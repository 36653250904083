import React, { useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../app_context/UserContext";
import { assignBucketUrl, formatPhoneNumber1, formatPhoneNumber3 } from "../../../Utils/HelperFunction";
import ProfilePicDemo from "../../../Assets/component/images/person.png";
import moment from "moment";
import Header from "../../../Components/Header/Header";
// import Navbar from "./Navbar";
import { ImgLoader2 } from "../../../Utils/Loader";

const ReviewOrder = ({ setRequestStep, createdGigData, vehicleInfoFormik, transportData, cityShow, dummy, setDummy,
  dummyTwo, setDummyTwo, calculateCostFormik, customerInfoFormik, load, setLoad }) => {


  const z = formatPhoneNumber1()

  const navigate = useNavigate();

  const app_context = useContext(UserContext);
  const { customerData, clearReview, setClearReview } = app_context;

  const inputRef = useRef()

  const handleCancel = () => {
    setClearReview(true);
    calculateCostFormik.resetForm();
    vehicleInfoFormik.resetForm();
    customerInfoFormik.resetForm();
    setTimeout(() => {
      // navigate('/cancel-policy')
      navigate("/new-quote");
    }, 100);
  };

  const getCodeFromVehiclePlate = () => {
    const plateNumber = vehicleInfoFormik?.values?.vehiclePlateNumber && vehicleInfoFormik?.values?.vehiclePlateNumber;
    const isValidNumber = plateNumber
      ?.slice(-4)
      ?.split("")
      ?.map((el) => !isNaN(el))
      .every((el) => el === true);
    if (isValidNumber) {
      return plateNumber?.slice(plateNumber?.length - 4);
    }
  };

  const isZeroMins = (time, type) => {
    const pickTime = moment(time).format("ddd. MMM. D (h:mm A)")
    const timeOnly = pickTime?.split("(")?.slice(-1)[0]
    const dateOnly = pickTime?.split("(")?.slice(0)[0]
    const mins = timeOnly?.split(":")?.slice(-1)[0]


    if (mins.includes("00")) {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`
      } else {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`
      }
    } else {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`
      } else {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`
      }
    }
  }

  const dropTime = moment(vehicleInfoFormik?.values?.dropOffTime).format("hh:mm A")
  // const dt1 = dropTime?.split(":")[0].replace(0, '') + ":" + dropTime?.split(":")[1].replace(":00", '')

  const isZeroMins1 = (time, type, timeToAdd) => {
    const s = Number(timeToAdd)
    const tTime = moment(time).add(s, "s").format()
    const pickTime = moment(tTime).format("ddd. MMM. D (h:mm A)")
    const timeOnly = pickTime?.split("(")?.slice(-1)[0]
    const dateOnly = pickTime?.split("(")?.slice(0)[0]
    const mins = timeOnly?.split(":")?.slice(-1)[0]

    if (mins.includes("00")) {
      if (type === "pick") {

        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`
      } else {
        return ` ${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]}`
      }

    } else {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`
      } else {
        return `${timeOnly.split(")")?.slice(0)[0]}`
      }
    }
  }


  useEffect(() => {
    vehicleInfoFormik.setFieldValue("mustArrivalTime", isZeroMins(vehicleInfoFormik?.values?.dropOffTime, "drop"));
    if (vehicleInfoFormik?.values?.vehiclePlateNumber !== "") {
      vehicleInfoFormik?.setFieldValue("securityCode", getCodeFromVehiclePlate());
    }
  }, []);

  useEffect(() => {
    if (window.document.referrer.includes("https://checkout.stripe.com")) {
      navigate("/new-quote")
    }
  }, [])

  // useEffect(() => {
  //   if(clearReview === true){
  //    calculateCostFormik.resetForm()
  //   }
  // }, [clearReview])
useEffect(()=>{
setInterval(() => {
  if(Boolean(load) && Boolean(vehicleInfoFormik?.values?.orderId)){
  window.location.reload()
  
  }
}, 1000);
},[])


  return (
    <>
      <div className=''>
        {/* <Header /> */}
        <Header />
        <main className="mb-4 main-content-container">

          <div className="container mb-3">

            <div style={{ marginTop: 80 }}>

              <div className="card  mb-3" style={{ border: "1px solid #E9E9E9" }}>

                <div className="card-header d-flex align-items-center justify-content-between bg-white border-0 mt-3">
                  <div className="d-flex align-items-center">
                    <div className="me-3 border-profile-pic" style={{ height: "50px", width: "50px" }}>
                      {
                        customerData?.data?.profilePicture ?
                          (
                            <img
                              ref={inputRef}
                              //  src={`${API_BASE_URL}/${customerData?.data?.profilePicture}`} 
                              src={assignBucketUrl(customerData?.data?.profilePicture)}
                              className=" rounded-circle "
                              style={{ height: "100%", width: "100%" }} alt=""
                              onError={event => {
                                event.target.src = ProfilePicDemo
                                event.onerror = null
                              }} />
                          )
                          :
                          (

                            <img src={ProfilePicDemo} className=" rounded-circle " style={{ height: "100%", width: "100%" }} alt="" />
                          )
                      }

                    </div>
                    <div>
                      {/* <p className="m-0 fw-semibold p-14">Customer name</p> */}
                      <p className="m-0 dark-clr fw-semibold">{
                        Boolean(customerData?.data?.firstName && customerData?.data?.lastName) ? (customerData?.data?.firstName + " " + customerData?.data?.lastName) : "-"
                      }</p>
                    </div>
                  </div>

                </div>

                <div className="border-top"></div>
                <div className="card-body ">
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Customer name</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{
                      // Boolean(customerData?.data?.firstName && customerData?.data?.lastName) ? (customerData?.data?.firstName + " " + customerData?.data?.lastName) : "-"
                      Boolean(customerInfoFormik?.values?.customerFullName) ? (customerInfoFormik?.values?.customerFullName) : "-"
                    }</div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Phone number</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">
                      {/* <a href="tel:+" className="text-decoration-none dark-clr">{customerData?.data?.phone || "-"}</a> */}
                      {formatPhoneNumber3(customerInfoFormik?.values)}

                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Customer e-mail</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{
                      // Boolean(customerData?.data?.firstName && customerData?.data?.lastName) ? (customerData?.data?.firstName + " " + customerData?.data?.lastName) : "-"
                      Boolean(customerInfoFormik?.values?.customerEmail) ? (customerInfoFormik?.values?.customerEmail) : "-"
                    }</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Customer other information</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{customerInfoFormik?.values?.customerOtherInfos || "-"}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">License plate</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{vehicleInfoFormik?.values?.vehiclePlateNumber || "-"}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Four last digits of the VIN</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{vehicleInfoFormik?.values?.vinNumber || "-"}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Make and model</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{vehicleInfoFormik?.values?.vehicleMake} {vehicleInfoFormik?.values?.vehicleModel || "-"}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Transmission</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{vehicleInfoFormik?.values?.transmission || "-"}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Vehicle other information</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{vehicleInfoFormik?.values?.vehicleOtherInformation || "-"}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Pickup Address</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{calculateCostFormik?.values?.pickupAddress || "-"}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Dropoff Address</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{calculateCostFormik?.values?.dropOffAddress || "-"}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Distance</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{calculateCostFormik?.values?.distance || transportData?.distance || "-"} miles</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Estimated duration</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold ">{calculateCostFormik?.values?.estimateEndTime || transportData?.estimateEndTime || "-"}</div>
                  </div>

                  {/* <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Estimated time arrival</div>
                    <div className="col-1 text-fade">:</div> 
                    <div className="col-5 p-13 fw-semibold px-0">{isZeroMins1(vehicleInfoFormik?.values?.pickUpDateAndTime, "pick", transportData?.estimatedTimeSeconds || calculateCostFormik?.values?.estimatedTimeSeconds)}</div>

                  </div> */}
                  <div className='row mb-2'>
                      <div className='col-5 text-fade p-11'>Earliest pickup time</div>
                      <div className='col-1 text-fade'>:</div>
                      <div className='col-5 p-13 fw-semibold px-0'>
                       {moment(vehicleInfoFormik?.values?.pickUpDateAndTime).format("ddd. MMM. D (h:mm A)")}
                      </div>
                    </div>
                  <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Must arrival latest</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold px-0">{isZeroMins(vehicleInfoFormik?.values?.dropOffTime, "drop")}</div>
                  </div>
                  {/* <div className="row mb-2">
                    <div className="col-5 text-fade p-11">Earliest pickup time</div>
                    <div className="col-1 text-fade">:</div>
                    <div className="col-5 px-0 p-14 fw-semibold px-0">
                      {isZeroMins(vehicleInfoFormik?.values?.earliestPickUpTime, "pick")}
                    </div>
                  </div> */}
                </div>
                <div className='d-flex align-items-center justify-content-between container my-2  border-top py-3'>
                  <Link
                    to='/new-quote'
                    // state={{
                    // 	cityShow,
                    // 	transportData,
                    // 	vehicleInfoFormikValue: vehicleInfoFormik?.values,
                    // 	dummyValues: { dummy, dummyTwo },
                    // }}
                    className='m-0 text-dark fw-semibold text-decoration-none '>
                    Edit order
                  </Link>

                  <button className='m-0 danger-text fw-semibold text-decoration-none p-0 bg-white border-0'
                    // onClick={() => handleCancel()}
                    data-bs-toggle="modal" data-bs-target="#CloseModal"
                  >
                    Cancel order
                  </button>
                </div>

              </div>
              {/* Modal Start */}
              <div
                className="modal fade"
                id="CloseModal"
                tabIndex={-1}
                aria-labelledby="CloseModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-bottom-0">

                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body text-center">
                      <h4 className="fw-bold">
                        Your order has been successfully cancelled
                      </h4>
                    </div>
                    <div className="modal-footer border-top-0">
                      <button type="button" className="button btn btn-primary w-100 py-2" data-bs-dismiss="modal"
                        onClick={() => handleCancel()}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Modal End*/}
              <div className='container mb-3'>
                {load?.loading === true ? (
                  <span className='button btn btn-primary w-100 py-3'>
                    <ImgLoader2 />
                  </span>
                ) : (
                  <button
                    className='button  btn-primary w-100 py-3'
                    data-bs-toggle='modal'
                    data-bs-target='#publish'
                    onClick={() => {
                      vehicleInfoFormik.handleSubmit();
                      // navigate('/confirm-order')
                    }}
                    disabled={!load}
                  >
                    Go to payment
                  </button>
                )}
              </div>
              <div className="scrollHeight"></div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ReviewOrder