import React from 'react'

const Header = () => {
  return (
    <>
    {/* <div
            className=" w-100 dark-color container-fluid row d-flex align-items-center  justify-content-between mb-5 gx-0 position-sticky"
         >
            <div className="text-white col-2 text-align-center ps-3 h4" 
            style={{cursor:"pointer"}}
             onClick={() => window.history.back()}
             >
               <i className="bi bi-chevron-left"></i>
            </div>
            <div 
            style={{width: "60px"}} className="col-6 text-align-center py-2">
               <img
                  src="../component/images/Mask Group 2259.png"
                  alt="#"
                  style={{width: "100%"}}
               />
            </div>
            {/* <div className="col-4 d-flex justify-content-evenly align-items-center ">
               <i className="bi bi-search text-white h4"></i>
               <i className="bi bi-bell-fill text-white h4"></i>
            </div> */}
         {/* </div> */} 
        <div className="dark-color d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {/* <div
            style={{ cursor: "pointer" }}
            onClick={() => {
               window.history.back();
            }}
          >
            <i className="bi bi-chevron-left h3 text-white ms-2"></i>
          </div> */}
          <div
            style={{ width: "60px" }}
            className=" text-align-center py-2 ms-3"
          >
            <img
              src="../component/images/Mask Group 2259.png"
              alt="#"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className=" d-flex justify-content-end align-items-center"></div>
      </div>
    </>
  )
}

export default Header