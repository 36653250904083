import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getLocationInputApi } from '../../../../api';
import AsyncSelect from 'react-select/async';
import { components } from "react-select";
import SelectStyle from '../../../../Utils/CustomSelectStyle';
import { debounce } from 'lodash';

// const apiKey = `AIzaSyBOZPRBIMUHB829qNCfYqAMTPGJpNtWAoo`;
const apiKey = "AIzaSyCJ4HYY3az2y8ek1oNy0eG4OTmr3RtPBh0";
// const apiKey = "AIzaSyCaCSJ0BZItSyXqBv8vpD1N4WBffJeKhLQ"
// const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';


// load google map api js


function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src
    })
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  })
}

const extractAddress = (place, formik) => {

  const address = {
    city: "",
    state: "",
    zip: "",
    // country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      // return city + zip + state + this.country;
    }
  }

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach(component => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      formik.values.city = value
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      // formik.values.state = `${value}, CA`
      formik.values.state = `CA`
      address.state = value;
    }

    if (types.includes("postal_code")) {
      formik.values.zipCode = value

      address.zip = value;
    }

    // if (types.includes("country")) {
    //     formik.values.country = value

    //   address.country = value;
    // }

  });

  return address;
}

const AutofillAddress = ({ businessFormik2 }) => {

  const searchInput = useRef(null);
  const [address, setAddress] = useState({});
  const [demoAdd, setDemoAdd] = useState("")
  // const [clearAdd, setClearAdd] = useState({ value: "", label: "" })

  const [cords, setCords] = useState({ latD: "", lngD: "" })


  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Search your address</span>
      </components.NoOptionsMessage>
    );
  };


  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }

  // do something on address change
  // const onChangeAddress = (autocomplete) => {
  //   const place = autocomplete.getPlace();
  //   setAddress(extractAddress(place, businessFormik2));
  // }

  // init autocomplete
  const initAutocomplete = () => {
    // if (!searchInput.current) return;

    // const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
    // console.log(autocomplete, "<><><><><")
    // autocomplete.setFields(["address_component", "geometry"]);
    // autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));

  }


  const reverseGeocode = ({ latD: lat, lngD: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    searchInput.current.value = "Getting your location...";
    fetch(url)
      .then(response => response.json())
      .then(location => {
        const place = location.results[0];
        const _address = extractAddress(place, businessFormik2);
        //   setDemoAdd(_address)
        setAddress(_address);
        searchInput.current.value = _address.plain();
      })
  }


  const findMyLocation = () => {
    // console.log(navigator.geolocation, ":L:L:L:L:L:L")
    if (navigator.geolocation) {
      reverseGeocode(cords)
      // console.log(navigator.geolocation, "{}{}{}{}{}__________")
      //   navigator.geolocation.getCurrentPosition(position => {
      //     console.log(position.coords , ":_:__:_:_:")
      //     reverseGeocode(position.coords)
      //   })
    }
  }


  // const handleLocationChange = async (search) => {

  //   try {
  //     const res = await getLocationInputApi(search);

  //     if (res.status === 200) {
  //       return res?.data?.length ? res.data.map((e) => ({ label: e.formatted_address, value: e.formatted_address, lat: e.geometry.location.lat, lng: e.geometry.location.lng })) : null;
  //     }
  //   } catch (error) {
  //     return null;
  //   }
  // };

  // const fetchLocationArray = async (a) => {
  //   // setAddress({...address,city:a.target.value})
  //   return await handleLocationChange(a);
  // };

  const fetchLocationArray = useCallback(
    debounce((inputText, callback) => {
      handleLocationChange(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const handleLocationChange = async (search) => {
    try {
      const res = await getLocationInputApi(search);
      // const res = await getLocationByPlaceId(placeId);

          if (res.status === 200 && res?.data?.length ) {
    
        const rd = res.data.map((e) => { 
         
          return  {
              label: e.name+" "+e.formatted_address, 
              value: e.name+" "+e.formatted_address, 
              lat : e.geometry.location.lat.toString(),
              lng : e.geometry.location.lng.toString(), 
              cityName : Boolean(e?.plus_code?.compound_code)
              ? e?.plus_code?.compound_code?.split(",")[0].split(" ").slice(1).join(' ') : e?.name,
              place_id : e.place_id,
            }
        }) 
        return rd
      }
      return null
    } catch (error) {
      return null;
    }
};

  //   const handleAddChange = (e) => {
  //     searchInput.current.value = e
  //   }

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, []);

  useEffect(() => {
    if (demoAdd) {
      findMyLocation()
    }
  }, [demoAdd])


  return (
    <>
      {/* <div>Autofill Addresssssssssssss</div> */}
      <div className="App">
        <div>
          <div className="search">
            {/* <span><Search /></span> */}

            {/* <input ref={searchInput} type="text" className='form-control' placeholder="Search location...." value={address.city} onChange={fetchLocationArray} /> */}
            {/* <ul>

            </ul> */}


          </div>

          <div className="address">
            <div className="mb-3s">
              <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Business address</label>
              <AsyncSelect
                // placeholder="Select your location"
                // defaultInputValue="Select your location"
                // isClearable
                styles={SelectStyle}
                components={{ NoOptionsMessage }}
                ref={searchInput}
                cacheOptions
                loadOptions={fetchLocationArray}
                onChange={(f) => {
                  businessFormik2.setFieldValue("address", f.value);
                  // setClearAdd({ value : f.value, label : f.label })
                  setDemoAdd(f)
                  setCords({ latD: f.lat, lngD: f.lng })
                  this.blur()
                }}
                defaultOptions
                name='address'
                className={Boolean(businessFormik2.touched?.address && businessFormik2.errors?.address) ? "form-control border-danger p-0" : "form-control p-0"}
                // value={clearAdd}
                value={{ value: businessFormik2.values?.address, label: businessFormik2.values?.address }}
                // onFocus={()=>{setClearAdd({ value : "", label : "" })}}
                onFocus={() => 
                  {businessFormik2.setFieldValue("address", "");
                  businessFormik2.setFieldValue("city", "")
                  businessFormik2.setFieldValue("state", "")
                  businessFormik2.setFieldValue("zipCode", "")}
              }
              />



              {Boolean(businessFormik2.touched.address && businessFormik2.errors.address) && <p className='text-danger mt-2'>{businessFormik2.errors.address}</p>}
            </div>
            <div className='d-flex justify-content-end align-items-end'>
              {/* <button className='btn1 py-1 h-100 rounded-2 mt-4 mb-4 position-relative' onClick={findMyLocation}>click me</button> */}
            </div>
            <div className='row g-3 mb-3 mt-1'>
              <div className="col-12 ">
                {/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">City</label> */}
                <input
                  type="text"
                  // className="form-control p-3 "
                  className={Boolean(businessFormik2.touched?.city && businessFormik2.errors?.city) ? "form-control border-danger p-3" :"form-control p-3 "}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="City"
                  name='city'
                  value={businessFormik2.values.city}

                onChange={businessFormik2.handleChange}
                />
                {Boolean(businessFormik2.touched.city && businessFormik2.errors.city) && <p className='text-danger mt-2'>{businessFormik2.errors.city}</p>}
              </div>

            </div>
            <div className='row g-3 mb-3'>
              {/* <div className="col-6">
               <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Country</label>
               <input
                    type="text"
                    className="form-control py-2"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    // placeholder="Enter zip code"
                    name='zipCode'
                    value={businessFormik2.values.country}
                    // onChange={businessFormik2.handleChange}
                />
            </div> */}
              <div className="col-6">
                {/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">State</label> */}
                <input
                  type="text"
                  // className="form-control p-3"
                  className={Boolean(businessFormik2.touched?.state && businessFormik2.errors?.state) ? "form-control border-danger p-3" :"form-control p-3 "}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="State"
                  name='state'
                  value={businessFormik2.values.state}

                onChange={businessFormik2.handleChange}
                />
                 {Boolean(businessFormik2.touched.state && businessFormik2.errors.state) && <p className='text-danger mt-2'>{businessFormik2.errors.state}</p>}
              </div>
              <div className="col-6">
                {/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Zip</label> */}
                <input
                  type="text"
                  className={Boolean(businessFormik2.touched?.zipCode && businessFormik2.errors?.zipCode) ? "form-control border-danger p-3" :"form-control p-3 "}
                  // className="form-control p-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Zip code"
                  name='zipCode'
                  value={businessFormik2.values.zipCode}

                onChange={businessFormik2.handleChange}
                />
                {Boolean(businessFormik2.touched.zipCode && businessFormik2.errors.zipCode) && <p className='text-danger mt-2'>{businessFormik2.errors.zipCode}</p>}
              </div>
            </div>
            {/* <p>City: <span>{address.city}</span></p>
            <p>State: <span>{address.state}</span></p>
            <p>Zip: <span>{address.zip}</span></p>
            <p>Country: <span>{address.country}</span></p> */}
          </div>

        </div>
      </div>
    </>
  )
}

export default AutofillAddress