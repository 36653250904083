import React, { useContext } from 'react'
import AsyncSelect from 'react-select/async'
import Navbar from './Navbar'
import { components } from "react-select";
import SelectStyle from '../../../Utils/CustomSelectStyle';
import {GigContext, UserContext} from '../../../app_context/UserContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DriverUrl } from '../../../Utils/BaseUrl';
import Header from '../../../Components/Header/Header';

const DeliveryRequest = ({ setRequestStep, vehicleInfoFormik, setQuotation, setCheckEditRequest, transportData, history }) => {

  const { calculateCostFormik } = useContext(GigContext)
 

  const navigate = useNavigate();

  const location = useLocation(); 

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No search results found</span>
      </components.NoOptionsMessage>
    );
  };

  const previousRoute = (j) => {
    if (j) {
      setCheckEditRequest(true)
      navigate("/quote", { state: { transportData: j } })
    }
  }

  return (
    <>
     {/* <Navbar /> */}
     <Header/>
      <main className="mb-4 main-content-container" >
        {/* <div className="dark-color d-flex align-items-center justify-content-between mb-3">
        <div style={{width: "60px" }}className=" text-align-center py-2 ms-3">
          <img src="./component/images/Mask Group 2259.png" alt="#" style={{width: "100%"}} />
        </div>
        <div className=" d-flex justify-content-end align-items-center">
          
          
            <i className="bi bi-list text-white h4 pe-3"></i>
          
        </div>
      </div> */}
    
       
        <div className="container mb-3 mt-3"  >
          <div style={{marginTop: 80}}>
            <h5 className="fw-bold">Create a delivery request.</h5>
            <h6 className="m-0 text-fade p-12 mt-3 mb-4 lh-base">Have your vehicle moved easily to the address you want. Fill in the details, make a payment, and let us transport the vehicle for you by driving.</h6>
          </div>
          <div className="mt-3 ">

            <div className="mb-4">
              <label for="exampleFormControlInput1" className="form-label fw-semibold p-14">Pick-up address city & zip</label>
              {/* <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Pick-up city & zip" name="pickupAddress" 
              value={transportData?.pickupAddress}
              /> */}
              <h6 className='p-12  text-fade lh-base'>
                {/* Washington, California  */}
                {/* rgegerger */}
                {calculateCostFormik?.values?.pickupAddress || transportData?.pickupAddress || "-" || location?.state?.gigDetails?.data?.pickupAddress || history?.history?.transportData?.pickupAddress}
                {/* <br></br>117867 */}
              </h6>
            </div>
            <div className="mb-5">
              <label for="exampleFormControlInput1" className="form-label fw-semibold p-14">Drop-off address city & zip</label>
              {/* <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Drop-off city & zip"
              name="dropOffAddress" 
            value={transportData?.dropOffAddress} */}
              {/* /> */}
              <h6 className='p-12  text-fade lh-base'>
                {/* Washington, Connecticut */}
                {calculateCostFormik?.values?.dropOffAddress || transportData?.dropOffAddress || "-" || location?.state?.gigDetails?.data?.dropOffAddress || history?.history?.transportData?.dropOffAddress}
                {/* <br></br> 87897 */}
              </h6>
            </div>

          </div>
          {/* <div className=" " style={{ height: "50px" }}></div> */}


        </div>
        <div className="container " style={{position: 'absolute', bottom: 0}}>
          <div className="form-check my-2">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
            <label className="form-check-label p-14" for="flexCheckChecked">
              Add the return delivery (trade in)
            </label>
          </div>
          
          <div className='row text-center '>
            <div className='col-6 '>
              <div className='w-100'>
                <Link className='button btn-primary  d-flex justify-content-center text-decoration-none w-100 py-3'

                  // onClick={()=> {
                  //   // setCheckEditRequest(true) ;
                  //   // navigate("/", {state : { transportData : transportData}});
                  //   previousRoute(transportData)}}
                  to="/new-quote"
                  state={{
                    transportData,
                    fromCity: history?.fromCity,
                    toCity: history?.toCity,
                    // location                                          
                  }}
                >
                  Edit
                </Link>
              </div>
            </div>
            <div className='col-6'>
              <button type="button" className="button btn-primary w-100 py-3"
                onClick={() => setRequestStep(3)}
              >
                Confirm
              </button>
            </div>
          </div>

          <div className="my-2">
            <a className="dark-clr p-12 fw-semibold text-decoration-none" target="_blank" href={DriverUrl}>Do you want to become a driver?</a>
          </div>
          <div className="scrollHeight"></div>
        </div>
        
      </main>
    </>
  )
}

export default DeliveryRequest