import { getApiMake, getApiModel, getApiWithoutToken, getApiWithToken, imgUploadApi, postApiWithoutToken, postApiWithToken, putApiWithoutToken, putApiWithToken } from "./interface";

//Driver register api...
export const registerApi = (payload) => {
    return postApiWithoutToken(`/api/dealer/auth/register`, payload);
};

export const loginApi = (payload) => {
    return postApiWithToken(`/api/dealer/auth/login`, payload);
};

export const fileUploadApi = (payload, id) => {
    return imgUploadApi(`/api/common/image/imageUpload`, payload);
};

 

export const multiFileUploadApi = (payload, orderId) => {
//   return imgUploadApi(`/api/common/image/imageUpload?orderId=${orderId}`, payload);
return imgUploadApi(`/api/common/image/imageUpload?uploadType=multi&type=beforePick&orderId=${orderId}`, payload);
//   http://192.168.1.157:3001/api/common/image/imageUpload?order=123
};

export const multiFileUploadApi2 = (payload, orderId) => {
    //   return imgUploadApi(`/api/common/image/imageUpload?orderId=${orderId}`, payload);
    return imgUploadApi(`/api/common/image/imageUpload?uploadType=multi&type=afterPick&orderId=${orderId}`, payload);
    //   http://192.168.1.157:3001/api/common/image/imageUpload?order=123
    };

export const myProfileApi = () => {
    return getApiWithToken(`/api/dealer/profile/myProfile`);
};

export const forgotPasswordApi = (payload) => {
    return postApiWithoutToken(`/api/dealer/auth/forgotPassword`, payload);
};

export const resetPasswordApi = (payload) => {
    return putApiWithoutToken(`/api/dealer/auth/resetPassword`, payload);
};

//get states...
export const getStatesApi = () => {
    return getApiWithoutToken(`/json/states/states.json`);
};

//get cities api...with post method...
export const getCitiesApi = (payload) => {
    // 
    return postApiWithoutToken(`/api/dealer/profile/getCities`, payload);
};

export const updateProfile = (payload) => {
    return putApiWithToken(`/api/dealer/profile/updateProfile`, payload);
};

export const getZipCodeApi = () => {
    return getApiWithoutToken(`/json/zipCode/zip.json`)
}

export const createGigApi = (payload) =>{
    return postApiWithToken(`/api/dealer/request/createRequest`, payload);
}


export const getLocationInputApi = (search) => {
    return getApiWithToken(`/api/common/location/getLocation?input=${search}`)
 }

  export const getCarApi = () =>{
    return getApiMake(`/cars/makes` );
}

  export const getCarYearApi = (id) =>{
    return getApiMake(`/cars/years`, id);
}

export const getApiModelApi = (payload) =>{
    return getApiModel(`/cars`, payload);
}


export const getDisAndPrice = (reqNum) =>{
    return getApiWithToken(`/api/dealer/request/getDistanceAndprice?requestNumber=${reqNum}`)
}
  
// export const getAmount = () =>{
//     return  getApiWithToken(`/api/dealer/request/getAmount`)
// }

// export const paypalApi = (payload) =>{
//     return getApiWithToken(`/api/dealer/request/payWithPaypal`, payload)
// }

 export const paypalApi = (payload) =>{
    // return postApiWithToken(`/create-payment`, payload)
    // return postApiWithToken(`/api/dealer/request/makePayment`, {"orderId": 411530695})
    return postApiWithToken(`/api/dealer/request/makePayment`, payload)


    }

 export const getAmount = (dist) => {
    
    return getApiWithoutToken(`/api/dealer/request/getAmount?distance=${dist}`)
 } 

export const getAllGig = (filter, source="") =>{
    return getApiWithToken(`/api/dealer/request/getAllGigById?filter=${filter.toLowerCase()}&source=${source}`)
}

export const getGigDetails = (orderId) => {
    if(orderId){
    return getApiWithToken(`/api/dealer/request/getGigById?orderId=${orderId}`)
    }
}

export const getLocationByPlaceId = (placeId) => {
    // 
    return getApiWithoutToken(`/api/common/location/getLocationByPlaceId?placeId=${placeId}`)
 } 

//  export const getGigById = (orderId) => {
//     return getApiWithToken(`/api/dealer/request/getGigById?orderId=${orderId}`)
//  }

export const postStarRating = (payload) => {
    return postApiWithToken(`/api/dealer/request/raiting`, payload)
}

export const putCancelGig = (payload) => {
    return putApiWithToken(`/api/dealer/request/cancelGigById` ,payload)
}

export const postForgotEmail = (payload) => {
    return postApiWithoutToken(`/api/dealer/auth/forgotEmail` , payload)
}

export const getAdminDetails = () =>{
    return getApiWithToken(`/api/common/commonAll/getAdminDetails`)
}
export const createPrice = (payload) => {
    return postApiWithoutToken(`/api/dealer/request/createPrice`, payload)
}

export const createPayment = (payload) => {
    return postApiWithToken(`/api/dealer/request/createPayment`, payload)
}

export const getPreviousChatMessage = (p) => {
    return getApiWithToken(`/api/common/commonAll/previousMessages?convoId=${p.convoId}&page=${p.page}&limit=${p.limit}`)
}

export const getLantLong = (orderId)=>{
    if(orderId){
    return getApiWithToken(`/api/dealer/request/findCoordinates/${orderId}`)
    }

}