import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../app_context/UserContext";
import { useFormik } from "formik";
import { io } from "socket.io-client";
import { API_BASE_URL } from "../../Utils/BaseUrl";

const ChatInput = ({ chat, setChat, chatLoading, scrollToBottom }) => {
  const app_context = useContext(UserContext);
  const { customerData, adminData, socket, setSocket } = app_context;

  const [attaches2, setAttaches2] = useState({ data: null, loading: true });

  const convoId = Boolean(customerData?.data?.uid && adminData?.data?.[0]?.id)
    ? customerData?.data?.uid + "_" + adminData?.data?.[0]?.id
    : "";

  const formik = useFormik({
    initialValues: {
      message: "",
      senderId: "",
      recieverId: "",
      convoId: "",
      //   isRead: false,
      attachment: {},
      senderName: "",
      chatType: "dealer",
      senderProfile: "",
    },
    validationSchema: "",
    onSubmit: async (values) => {
      values.message = values?.message?.trim();

      if (!values?.message) {
        if (attaches2?.data) {
          socket.emit("send message", { ...values });
          formik.setFieldValue("attachment", "");
        }
      } else if (values?.message) {
        socket.emit("send message", { ...values });
        formik.setFieldValue("message", "");
      }
      setAttaches2({ data: null });
    },
  });

  const handleFormSend = (e) => {
    if (formik.values.message !== "") {
      e.preventDefault();
      formik.handleSubmit();
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (socket) {
      socket?.on("message", (message) => {
         
        setChat((c) => {
          if(c?.find(l=>l?.id == message?.id)){
            return c
          }
          return [...c, message]
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    if (chatLoading === false) {
      scrollToBottom();
    }
  }, [chat, chatLoading]);

  useEffect(() => {
    if (convoId && customerData?.data?.uid && adminData?.data) {
      formik.setFieldValue("senderId", customerData?.data?.uid);
      formik.setFieldValue("recieverId", adminData?.data?.[0]?.id);
      formik.setFieldValue("convoId", convoId);
      formik.setFieldValue(
        "senderName",
        customerData?.data?.firstName + " " + customerData?.data?.lastName
      );
      formik.setFieldValue("senderProfile", customerData?.data?.profilePicture);

      setSocket(
        io(API_BASE_URL, {
          transports: ["websocket"],
          query: { convoId: convoId },
        })
      );
    }
  }, [customerData, adminData, convoId]);

  return (
    <div>
      <form onSubmit={(e) => handleFormSend(e)}>
        <input
          type="text"
          name="message"
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.message}
          style={{ paddingRight: 90 }}
          placeholder="Your message here... "
          className="w-100 form-control py-3  input-bg1 border-0 "
        />
        <div className="position-absolute chat-icon d-flex">
          <button
            type="submit"
          
            className="send-button me-2"
          >
            <img src="./component/images/send.svg" alt="" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatInput;
