import React, { useContext, useState } from 'react'
import { useFormik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getCitiesApi, getStatesApi, registerApi } from '../../../api';
import { registerValidation1, registerValidation2 } from '../../../Validation/Validation';
import RegisterStep1 from './Steps/Step1';
import RegisterStep2 from './Steps/Step2';
import { ImgLoader2 } from '../../../Utils/Loader';
import { UserContext } from '../../../app_context/UserContext';


const Register = () => {

   const app_context = useContext(UserContext)
   const { setLoggedIn, isBusiness } = app_context

   const [step, setStep] = useState(0);
   const [checkLength, setCheckLength] = useState(false)
   const [stateCode, setStateCode] = useState("")
   const [cityData, setCityData] = useState("")

   const [loader, setLoader] = useState(false)
   // const data = useLocation()

   // const UserApi = useContext(UserContext);
   // const { checkDestination, setCheckDestination } = UserApi;

   const history = useLocation()

   const checkDestination1 = history?.state?.checkDestination


   const navigate = useNavigate();

   const fetchStates = async (search) => {
      const res = await getStatesApi();
      if (res?.status === 200 && res?.data?.length) {
         const data = res?.data.map(l => ({ label: l.name, value: l.isoCode }))
         return data
      }
      return []
   };

   const fetchCities = async (stCode) => {
      try {
         const res = await getCitiesApi({ stateCode: stCode });
         if (res?.data?.cities?.length > 1) {
            setCityData(res?.data?.cities)
         }
      } catch (err) {
         return err
      }
   };

   const regFormik1 = useFormik({
      initialValues: {
         firstName: "",
         lastName: "",
         email: "",
         password: "",
         confirmPassword: "",
      },
      validationSchema: registerValidation1,

      onSubmit: (values) => {
         if (values.password.length < 8) {
            return setCheckLength(true)
         }
         setStep(1);
      },
   });

   const regFormik2 = useFormik({
      initialValues: {
         phone: "",
         company: "",
         address: "",
         city: "",
         state: "",
         zipCode: "",
         profilePicture: "",
         // signinPolicy: false,
      },
      validationSchema: registerValidation2,

      onSubmit: async (values) => {
         const data = { ...regFormik1.values, ...values };
         delete data.confirmPassword;
         // delete data.company;

         // const dummyAccount = {
         //    address
         // : 
         // "ORIGINAL SA-BY THAI 273 E San Fernando St, San Jose, CA 95112, United States",
         // city
         // : 
         // "San Jose",
         // email
         // : 
         // "garima98@yopmail.com",
         // firstName
         // : 
         // "garima",
         // isBusiness
         // : 
         // false,
         // lastName
         // : 
         // "sharma",
         // password
         // : 
         // "Garima@123",
         // phone
         // : 
         // "+12312312343",
         // profilePicture
         // : 
         // "upload/files/1683545904488Screenshot_from_2023-05-08_14-58-00.png",
         // state
         // : 
         // "CA",
         // zipCode
         // : 
         // "95112",
         // }

         try {
         setLoader(true)
            if (isBusiness === true) {
               const res = await registerApi({ ...data, isBusiness: true });
               // const res = await registerApi(dummyAccount);
               if (res?.status === 200 || res?.status === 201) {
                  localStorage.setItem("token", res.data.accessToken);
                  toast.success(res?.data?.message, { id: "001" });
                  setLoggedIn(true)

                  Boolean(checkDestination1 === true) ?

                     navigate("/new-quote", {
                        state: {
                           destination: 2,
                           transferHistory: history?.state?.data,
                           fromCity: history?.state?.fromCity,
                           toCity: history?.state?.toCity
                        }
                     })
                     :

                     navigate("/new-quote", {
                        state: {
                           destination: 1,
                           transferHistory: history?.state?.data,
                           fromCity: history?.state?.fromCity,
                           toCity: history?.state?.toCity
                        }
                     })
               }
            } else {
               if (isBusiness === false) {
                  delete data.company;
               }
               const res = await registerApi({ ...data, isBusiness: false });
               // const res = await registerApi(dummyAccount);
               if (res?.status === 200 || res?.status === 201) {
                  localStorage.setItem("token", res.data.accessToken);
                  toast.success(res?.data?.message, { id: "001" });
                  setLoggedIn(true)

                  Boolean(checkDestination1 === true) ?
                     navigate("/new-quote", {
                        state: {
                           destination: 2,
                           transferHistory: history?.state?.data,
                           fromCity: history?.state?.fromCity,
                           toCity: history?.state?.toCity
                        }
                     })
                     :
                     navigate("/new-quote", {
                        state: {
                           destination: 1,
                           transferHistory: history?.state?.data,
                           fromCity: history?.state?.fromCity,
                           toCity: history?.state?.toCity
                        }
                     })
               }
            }
         } catch (error) {
            setLoader(false) 
            toast.error(error?.response?.data?.message, { id: "etrr01" });
         }
      },
   });

   return (
      <div className="container px-3">
         <div className="mt-3" style={{ height: "100px", width: "100px" }}>
            <img
               src="./component/images/Group 3621.png"
               alt="#"
               style={{ width: "100%" }}
            />

         </div>
         {
            step === 0 ?

               <div className='row flex-column'>
                  <label htmlFor='profilePic mb-3'>
                     <span

                        style={{ cursor: "pointer" }}

                     >
                        <i className="bi bi-arrow-left-circle-fill dark-clr h3  " onClick={() => navigate('/new-quote')}></i>
                     </span>
                  </label>
                  <h1 className='fw-bold my-2 col-md-11'>Sign up</h1>
                  <p className="text-muted mb-6 fw-semibold text-center text-md-start">
                     {/* Create your account and start benefit from the convenience our drivers provide to you. */}
                     Sign up now and let our experienced drivers safely drive your vehicles between locations with ease.
                  </p>
               </div>

               :

               (isBusiness === true) ?
                  <div>
                     <div className='row flex-column'>
                        <label htmlFor='profilePic mb-3'>
                           <span
                              style={{ cursor: "pointer" }}
                           >
                              <i className="bi bi-arrow-left-circle-fill dark-clr h3  " onClick={() => setStep(0)}></i>
                           </span>
                        </label>
                        <h4 className="fw-bold my-2 col-md-11">Business Contact Information</h4>
                        {/* <p className="text-muted mb-6 fw-semibold">Create your account and get the sold cars driven smoothly to their buyers!</p> */}
                     </div>
                  </div>
                  :
                  (
                     <div>
                        <div className='row flex-column'>
                           <label htmlFor='profilePic mb-3'>
                              <span
                                 style={{ cursor: "pointer" }}
                              >
                                 <i className="bi bi-arrow-left-circle-fill dark-clr h3  " onClick={() => setStep(0)}></i>
                              </span>
                           </label>
                           <h1 className="fw-bold my-2 col-md-11">Sign up</h1>
                           <p className="text-muted mb-6 fw-semibold text-center text-md-start">Create your account and get the sold cars driven smoothly to their buyers!</p>
                        </div>
                     </div>
                  )

         }


         {step === 0 ? (
            <RegisterStep1 regFormik1={regFormik1} checkLength={checkLength} setCheckLength={setCheckLength} >
               <button type="submit" className="button text-white w-100  py-3"
               // onClick={()=>{handleSubmit(1)}}
               >
                  Next
               </button>
            </RegisterStep1>
         ) : (
            <RegisterStep2 regFormik1={regFormik1} regFormik2={regFormik2} fetchStates={fetchStates} fetchCities={fetchCities} stateCode={stateCode} setStateCode={setStateCode} cityData={cityData}>
              {loader
              ?
              <button className="button text-white w-100 py-3 fs-5">
              <div className="text-xs"><ImgLoader2 /></div>
           </button>
           :
            <button type="submit" className="button text-white w-100 py-3 fs-5">
                  Create an account
               </button>}
            </RegisterStep2>
         )}

         {Boolean(step === 0) && (
            <p className="text-muted text-center">Already have an account?
               <Link
                  to='/login'
                  state={{
                     transferHistory: history?.state?.data,
                     fromCity: history?.state?.fromCity,
                     toCity: history?.state?.toCity,
                     checkDestination1
                  }}
                  className="ms-2 fw-semibold text-decoration-none">Log in</Link>

               {/* here we have to pass the flag which is coming from the destination */}
            </p>
         )}

      </div>
   )
}

export default Register