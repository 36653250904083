import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { API_BASE_URL } from "../../Utils/BaseUrl";
import {UserContext} from "../../app_context/UserContext";
import { Loader } from "../../Utils/Loader"; 
import ProfilePicDemo from "../../Assets/component/images/person.png"
import ChatInput from "./ChatInput";
import { assignBucketUrl } from "../../Utils/HelperFunction";
import { getPreviousChatMessage } from "../../api";


const Chat = () => {

    const [chat, setChat] = useState([]);
    const [chatLoading, setChatLoading] = useState(true);
    
    const app_context = useContext(UserContext);
    const { customerData, adminData, socket, setSocket } = app_context; 

    const convoId = Boolean(customerData?.data?.uid && adminData?.data?.[0]?.id)
    ? customerData?.data?.uid + "_" + adminData?.data?.[0]?.id
    : "";
    
    const messagesEndRef = useRef(null);
    
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    
    const navigate = useNavigate();

    const inputRef = useRef()

    const fetchPreviousMessages = async (p) => {
        try {
            const res = await getPreviousChatMessage(p)
            setChat(res?.data) 
            setChatLoading(false)
        } catch (error) {
            return error
        }
    }

    useEffect(() => {

        setSocket(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: convoId } }))

    }, []);

    useEffect(() => {
        if(convoId){    
            fetchPreviousMessages({convoId : convoId, page : 1, limit : 50})
        }
    }, [convoId])

    useEffect(() => {
        if(chat?.length > 0){
            setChatLoading(false)
        }
    }, [chat])
  

    return (
        <>
            <div className="dark-color d-flex align-items-center justify-content-between fixed-top container w-100s">
                <div className="d-flex align-items-center">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            {navigate("/")}
                        }}
                    >
                        <i className="bi bi-chevron-left h3 text-white ms-2"></i>
                    </div>
                    <div
                        style={{ width: "60px" }}
                        className=" text-align-center py-2 ms-3"
                    >
                        <img
                            src="./component/images/Mask Group 2259.png"
                            alt="#"
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>

                <div className=" d-flex justify-content-end align-items-center"></div>
            </div>

            <main className="chat-container  " style={{ backgroundColor: "#fafafa" }}>
               

                
            {
                chatLoading === true ? 
                (<div className="text-center mt-8 pt-4 ">
                    <Loader />
                </div>)
                :
                (
                    <>
                    <div className=" chat-row chat-header py-2 border-bottom  mt-5 py-2 px-2">
                    <div className="d-flex justify-content-between align-items-center sky-blue-bg ">
                        <div className="d-flex">
                            <div className="me-4">
                                <div
                                    className="avatar position-absolute"
                                    style={{ width: 40, height: 40 }}
                                >
                                     {
                                        adminData?.data?.[0]?.profilePicture ? 
                                        (
                                            <img
                                            ref={inputRef} 
                                            src={assignBucketUrl(adminData?.data?.[0]?.profilePicture)}
                                             className=" rounded-circle " 
                                            style={{ height: "100%", width: "100%" , objectFit: "cover"}} alt="" 
                                            onError={event => {
                                            event.target.src = ProfilePicDemo
                                            event.onerror = null
                                        }}/>
                                        ) 
                                        :
                                        ( 
                                            <img src={ProfilePicDemo} className=" rounded-circle " style={{ height: "100%", width: "100%", objectFit: "cover" }} alt="..." />
                                        )
                                        }
                                </div>
                                {/* <div
                                    className="border rounded-circle position-relative"
                                    style={{
                                        width: 10,
                                        height: 10,
                                        right: "-32px",
                                        bottom: "-25px",
                                        backgroundColor: "#03ca4c",
                                    }}
                                /> */}
                            </div>
                            <div className="flex-fill ms-3 ">
                                {/* Title */}
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 href="#" className="d-block fw-bold mb-1 text-dark">
                                        {adminData?.data?.[0]?.firstName +
                                            " " +
                                            adminData?.data?.[0]?.lastName || "-"}
                                    </h6>
                                </div>
 
                                <div className="d-flex justify-content-between align-item-center">
                                    <p className="text-muted p-12 mb-0">101Drivers chat</p>
                                </div>
                            </div>
                        </div>
 
                    </div>
                </div>
                    <div className="mt-20 pt-10 chat-section px-2">
                    {chat?.length > 0
                        ? chat?.map((e, i) => {
                            return (
                                <React.Fragment>
                                    {e?.senderId === customerData?.data?.uid ? (
                                        <div
                                            className="d-flex justify-content-end my-4"
                                            ref={messagesEndRef}
                                        >
                                            <div className=" box-shadow  rounded-5 chat-bg">
                                                <p className="mb-0 rounded-5 message-container p-12 p-2 px-4 sender text-wrap">
                                                    {e?.message}
                                                </p>
                                            </div>
                                            <div
                                                className="avatar ms-2"
                                                style={{ width: 30, height: 30 }}
                                            >
                                                <img
                                                    alt="..."
                                                    src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg"
                                                    className="rounded-circle"
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    ) : (
                                        <div className="d-flex my-4 justify-content-start" ref={messagesEndRef}>
                                            <div
                                                className="me-2"
                                                style={{ width: 30, height: 30 }}
                                            >
                                                {
                                                    adminData?.data?.[0]?.profilePicture ? 
                                                    (
                                                        <img
                                                        ref={inputRef}
                                                        // src={`${API_BASE_URL}/${customerData?.data?.profilePicture}`} 
                                                        src={assignBucketUrl(customerData?.data?.profilePicture)}
                                                         className=" rounded-circle " 
                                                        style={{
                                                            height: 30,
                                                            width: 30,
                                                            objectFit: "cover",
                                                        }} alt="" 
                                                        onError={event => {
                                                        event.target.src = ProfilePicDemo
                                                        event.onerror = null
                                                    }}/>
                                                    ) 
                                                    :
                                                    ( 
                                                        <img src={ProfilePicDemo} className=" rounded-circle "
                                                        style={{
                                                            height: 30,
                                                            width: 30,
                                                            objectFit: "cover",
                                                        }} alt="..." />
                                                    )
                                                    }
                                            </div>
                                            <div className=" box-shadow  rounded-5">
                                                <p className="mb-0 message-container rounded-5 p-12 p-2 px-4 receiver text-wrap">
                                                    {e?.message}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })
                        : <div className="text-center">No data found</div>}

                 
                </div>
                <div
                            className="border-top w-100 p-2 pb-0 mb-0 chat-footer"
                        >
                            <div className="position-relative">
                                <ChatInput chat={chat} setChat={setChat} scrollToBottom={scrollToBottom} chatLoading={chatLoading} />
                            </div>
                        </div>
                </>
                )
            }       
                    
                   
                
            </main>

            {/* modal */}

            <div
                className="modal fade"
                id="publish"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-4">
                        <div className="modal-body row text-center justify-content-center p-4">
                            {/* <div className="" >
                    <img
                    src="../component/images/end-chat.png"
                    alt=" "
                    style={{ width: "70%" }}
                    className="img-fluid"
                    />
                </div> */}
                            <div className="text-center dark-clr">
                                {/* <h2 className="fw-bold  m-0 my-2">Thank you for </h2> */}
                                <h5 className="fw-bold  m-0 my-2">
                                    Do you really want to end this conversation?{" "}
                                </h5>
                                {/* <h1 className="m-0 p-12 my-2 fw-semibold">Do you really want to end this conversation? </h1> */}
                            </div>
                            <div
                                className="text-center my-3 d-flex"
                                style={{ justifyContent: "space-evenly" }}
                            >
                                <div className="" style={{ display: "contents" }}>
                                    <button
                                        type="submit"
                                        data-bs-dismiss="modal"
                                        className="button py-3 fw-semibold"
                                        style={{ width: "100px" }}
                                        onClick={() => navigate("/")}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="submit"
                                        data-bs-dismiss="modal"
                                        className="button py-3 fw-semibold"
                                        style={{ width: "100px" }}
                                    // onClick={() =>
                                    // navigate('/thank-you')
                                    //   {  navigate(`/confirm-order/${params?.orderId}`);
                                    //         toast.success("Gig created successfully")
                                    // }

                                    // navigate (`/pickup-details/${params?.orderId}`)

                                    // handleSubmit()
                                    // }
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chat;
