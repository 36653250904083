import React from 'react'
import Footer from '../../Components/Footer/Footer'
import { useNavigate } from 'react-router-dom';

const PaymentFailure = () => {

  const navigate = useNavigate();

  return (
    <div className="footer-app-container">
      <div className="dark-color d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {/* <div
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-chevron-left h3 text-white ms-2"></i>
          </div> */}
          <div
            style={{ width: "60px" }}
            className=" text-align-center py-2 ms-3"
          >
            <img
              src="../component/images/Mask Group 2259.png"
              alt="#"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className=" d-flex justify-content-end align-items-center"></div>
      </div>
      <main className='main-content-container d-flex align-items-center justify-content-center '>
                <div className=' payment-main'>
                    <img className='img-fluid' src='../../component/images/paymentFail.svg' />
                    <h1 className='payment-fail mt-5'>Ooops...</h1>
                    <h6 className='payment-detail'>Sorry! Something went wrong</h6>
                    <button className='payment-button-fail py-2 px-5 mt-4'
                      onClick={()=>{navigate(`/new-quote`)}}
                    >Done</button>
                </div>
            </main>
      <Footer />
    </div>
  )
}

export default PaymentFailure