import React, { useCallback, useContext, useEffect, useState } from "react";
import { isAuth, logoutOnJwtExpire } from "../../Utils/HelperFunction";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import SelectStyle from "../../Utils/CustomSelectStyle";
import { debounce } from "lodash";
import { getGigDetails, getLocationByPlaceId, getLocationInputApi } from "../../api";
import Map from "../Quotation/Map";
import { UserContext, GigContext } from "../../app_context/UserContext";
import Footer from "../../Components/Footer/Footer";

const NewCalculateCost = () => {
 
   const [directionsResponse, setDirectionsResponse] = useState(null)

   const app_context = useContext(UserContext);
   const { isBusiness, setIsBusiness } = app_context

   const { calculateCostFormik, setPlace, place, checkEditRequest, destinationShowCity, setDestinationShowCity,
      placeId, setPlaceId, } = useContext(GigContext) 

	const navigate = useNavigate();
	const location = useLocation();
	// const [gigDetails, setGigDetails] = useState({ data: "", loading: true });
	// const orderId = location?.state?.orderId;
	// const getGigsData = async () => {
	// 	setGigDetails({ loading: true });
	// 	try {
	// 		const res = await getGigDetails(orderId);
	// 		if (res.status === 200) {
	// 			setGigDetails({ data: res?.data, loading: false });
	// 		}
	// 	} catch (error) {
	// 		setGigDetails({ loading: false });
	// 	}
	// };

	// const isOrderId = () => {
	// 	if (gigDetails?.data) {
	// 		calculateCostFormik?.setFieldValue("pickupAddress", gigDetails?.data?.pickupAddress);
	// 		calculateCostFormik?.setFieldValue("dropOffAddress", gigDetails?.data?.dropOffAddress);
	// 		calculateCostFormik?.setFieldValue("dropOffAddress", gigDetails?.data?.dropOffAddress);

	// 		// vehicleInfoFormik.values.pickUpDateAndTime
	// 		setPlace({ ...place, to: gigDetails?.data?.dropOffAddress, from: gigDetails?.data?.pickupAddress });
	// 	}
	// };

	// useEffect(() => {
	// 	getGigsData();
	// }, [orderId]);

	// useEffect(() => {
	// 	isOrderId();
	// }, [gigDetails]);

   // const calculateCostFormik = useFormik({
   //    initialValues: {
   //       pickupAddress: "",
   //       dropOffAddress: "",
   //       // checkInQuote: false,

   //       fromLongitude: "",
   //       fromLatitude: "",
   //       toLongitude: "",
   //       toLatitude: "",

   //       distance: "",

   //       pickup_place_id: "",
   //       dropOff_place_id: "",

   //       pickupCity: "",
   //       dropOffCity: "",
   //       // priceOfRequest: "",

   //       estimateEndTime: '',
   //       estimatedTimeSeconds: '',
   //    },

   //    validationSchema: calculateCostValidation,

   //    onSubmit: async (values) => {
   //       const val = { ...values };
   //       delete values.pickup_place_id;
   //       delete values.dropOff_place_id;
   //       const { pickup_place_id, dropOff_place_id } = val;
   //       try {
   //          // setQuotation(2)

   //       } catch (error) {
   //          console.log(error);
   //          toast.error(error?.response?.data?.message, { id: "err01" });
   //       }
   //    },
   // });

   const handleLocationChange = async (search) => {
      try {
         const res = await getLocationInputApi(search);
         // const res = await getLocationByPlaceId(placeId);

         if (res.status === 200 && res?.data?.length) {

            const rd = res.data.map((e) => {

               return {
                  label: e.name + " " + e.formatted_address,
                  value: e.name + " " + e.formatted_address,
                  lat: e.geometry.location.lat.toString(),
                  lng: e.geometry.location.lng.toString(),
                  cityName: Boolean(e?.plus_code?.compound_code)
                     ? e?.plus_code?.compound_code?.split(",")[0].split(" ").slice(1).join(' ') : e?.name,
                  place_id: e.place_id,
               }
            })
            return rd
         }
         return null
      } catch (error) {
         return null;
      }
   };

   const fetchLocationArray = useCallback(
      debounce((inputText, callback) => { 
         handleLocationChange(inputText).then((options) => callback(options));
      }, 500),
      []
   );

   const handlePickUpChange = (f) => { 
      calculateCostFormik.setFieldValue("pickupAddress", f.value);
      calculateCostFormik.setFieldValue("fromLatitude", f.lat);
      calculateCostFormik.setFieldValue("fromLongitude", f.lng);
      // calculateCostFormik.setFieldValue("pickup_place_id", f.place_id)
      setPlaceId({ ...placeId, from_place_id: f.place_id });
      // setDestinationShowCity({ ...destinationShowCity, from: f.value.split(",")?.[0] })
      
      setPlace({ ...place, from: f.value });
   };
   const handleDropOffChange = (f) => {
      calculateCostFormik.setFieldValue("dropOffAddress", f.value);
      calculateCostFormik.setFieldValue("toLatitude", f.lat);
      calculateCostFormik.setFieldValue("toLongitude", f.lng);
      setPlaceId({ ...placeId, to_place_id: f.place_id });
      setPlace({ ...place, to: f.value });
   };

   const handleplaceId = async () => {
      try {
        if (placeId?.from_place_id !== "") {
          const res1 = await getLocationByPlaceId(placeId?.from_place_id);
    
          if (res1?.status === 200) {
            for (let j = 0; j < res1?.data?.address_components?.length; j++) {
    
              if (Boolean(res1.data?.address_components[j]?.types?.length)) {
    
                if (res1?.data?.address_components[j]?.types.includes("locality") === true) {

                  return (setDestinationShowCity((v) => ({
                    ...v,
                    from: res1?.data?.address_components[j]?.long_name,
                    // calculateCostFormik.setFieldValue("pickupCity", destinationShowCity.from)
                  })), calculateCostFormik.setFieldValue("pickupCity", res1?.data?.address_components[j]?.long_name ));
                }
              }
            }
          }
        } 
      } catch (err) {
         console.log(err)
         return err
      }
    }

    
    const handleplaceId2 = async () => {
    
      try {
        if (placeId?.to_place_id !== "") {
          const res1 = await getLocationByPlaceId(placeId?.to_place_id);
    
    
          if (res1?.status === 200) {
            for (let j = 0; j < res1?.data?.address_components?.length; j++) {
    
              if (Boolean(res1.data?.address_components[j]?.types?.length)) {
    
                if (res1?.data?.address_components[j]?.types.includes("locality") === true) {
                  return (setDestinationShowCity((v) => ({
                    ...v,
                    to: res1?.data?.address_components[j]?.long_name,
                  })), calculateCostFormik.setFieldValue("dropOffCity", res1?.data?.address_components[j]?.long_name ));
                }
              }
            }
          }
        } 
      } catch (err) {
         return err
      }
    }
    
    useEffect(() => {
      
      if (Boolean(placeId?.from_place_id) ) {
        handleplaceId()
      }
      if (Boolean(placeId?.to_place_id) ) {
        handleplaceId2()
      }
    }, [placeId, calculateCostFormik?.value?.pickupAddress, calculateCostFormik?.value?.dropOffAddress])
    

   useEffect(() => {
      if (checkEditRequest === true) {
      //   calculateCostFormik.setFieldValue(
      //     "pickupAddress",
      //     destinationShowCity.previousFrom
      //   );
      }
    }, [checkEditRequest]);

	useEffect(() => {
		if (Boolean(window.document.referrer.includes("https://checkout.stripe.com"))) {
			window.location.reload();
		}
	}, []);

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className='custom-css-class'>Search your address</span>
			</components.NoOptionsMessage>
		);
	};

	// useEffect(() => {
	// 	calculateCostFormik.setFieldValue("pickupAddress", "S & S Market 502 S 10th St, San Jose, CA 95112, United States");
	// 	calculateCostFormik.setFieldValue("fromLatitude", "37.3330216");
	// 	calculateCostFormik.setFieldValue("fromLongitude", "-121.8751542");
	// 	calculateCostFormik.setFieldValue("dropOffAddress", "S&S Tire and Auto Repair 597 S Murphy Ave, Sunnyvale, CA 94086, United States");
	// 	calculateCostFormik.setFieldValue("toLatitude", "37.3687255");
	// 	calculateCostFormik.setFieldValue("toLongitude", "-122.0332909");

	// 	// calculateCostFormik.setFieldValue("pickupAddress", "S & S Market 502 S 10th St, San Jose, CA 95112, United States");

	// 	// calculateCostFormik.setFieldValue("dropOffAddress", "S&S Tire and Auto Repair 597 S Murphy Ave, Sunnyvale, CA 94086, United States");

	// 	// // "S & S Market 502 S 10th St, San Jose, CA 95112, United States"
	// 	// // "S&S Tire and Auto Repair 597 S Murphy Ave, Sunnyvale, CA 94086, United States"

	// 	// fromLatitude: "37.3330216";
	// 	// fromLongitude: "-121.8751542";

	// 	// toLatitude: "37.3687255";
	// 	// toLongitude: "-122.0332909";
	// }, []);

   return (
      <>
         <div className="footer-app-container ">
            <header className="dark-color header d-flex align-items-center justify-content-between container">
               <div
                  style={{ width: "60px" }}
                  className=" text-align-center py-2 ms-3"
               >
                  <img
                     src="./component/images/Mask Group 2259.png"
                     alt="#"
                     style={{ width: "100%" }}
                  />
               </div>
               <div className=" d-flex justify-content-end align-items-center">
                  <div className="d-flex justify-content-end align-items-center">
                     {localStorage.getItem("token") ? (
                        <button
                           className="btn btn-light btn-sm  me-2"
                           style={{ cursorPointer: "pointer" }}
                           onClick={() => logoutOnJwtExpire()}
                        >
                           Log out
                        </button>
                     ) : (
                        <span>
                           <button
                              className="btn btn-light btn-sm  me-2"
                              style={{ cursorPointer: "pointer" }}
                              onClick={() => navigate("/login")}
                           >
                              Log in
                           </button>
                           <button
                              className="btn btn-light btn-sm  me-2"
                              style={{ cursorPointer: "pointer" }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                           >
                              Sign up
                           </button>
                        </span>
                     )}
                  </div>
                  <div
                     data-bs-toggle="offcanvas"
                     data-bs-target="#offcanvasWithBothOptions"
                     aria-controls="offcanvasWithBothOptions"
                  >
                     {/* <i className="bi bi-list text-white h4 pe-3"></i> */}
                     {/* <img src={Hamburger} className="pe-3" alt="" /> */}
                  </div>
               </div>
            </header>
            <main className=" mobile main-content-container ">

               <div className="d-md-block d-none" style={{ height: "80px" }}></div>
               <div className="row g-0 align-items-center mt-md-5 my-auto">
                  <div className="col-md-7 ">
                     <div className="text-center p-2 pb-0 px-4 mt-md-0 mt-4 quoteText d-md-block d-none">
                        <h6 className="fw-bolder fs-3  m-0">
                           101 Drivers is a platform which connects drivers with car
                           dealers and individuals that need drivers.
                        </h6>
                     </div>
                     <div className=" QuoteImage ">
                        <img
                           src="./component/images/home.png"
                           alt=""
                           style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                           }}
                        />
                     </div>
                  </div>

                  <div className="col-md-5  ps-md-3 frontForm">
                     <div className=" container ">
                        {/* <h6 className="fw-bolder m-0 my-3">Vehicle Delivery Quote</h6> */}
                        <h6 className="fw-bolder fs-4 d-md-block mb-4 d-none">
                           Vehicle Delivery Quote
                        </h6>
                        <h6 className="fw-bolder d-block d-md-none mt-4">
                           Vehicle Delivery Quote
                        </h6>

								<p className='text-start lh-sm fs-6  text-black fw-semibold d-md-block mb-4 d-none gtq-css'>
									Do you need a vehicle picked up or dropped off efficiently, quickly and inexpensively?{" "}
								</p>
								<p className='text-start lh-sm fs-6  text-black fw-semibold d-md-block mb-4 d-none gtq-css'>
									Our 101 Drivers deliver your vehicles anywhere in California.
								</p>
								<p className='text-start lh-sm p-13 text-black fw-semibold  d-block d-md-none gtq-css'>
									Do you need a vehicle picked up or dropped off efficiently, quickly and inexpensively?{" "}
								</p>
								<p className='text-start lh-sm p-13 text-black fw-semibold  d-block d-md-none gtq-css'>
									Our 101 Drivers deliver your vehicles anywhere in California.
								</p>
								<form>
									<div className='mt-3 '>
										<div className='mb-3'>
											{/* <label for="exampleFormControlInput1" className="form-label fw-semibold">Transport Origin</label> */}
											{/* <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="ZIP or City"/> */}
											<AsyncSelect
												components={{ NoOptionsMessage }}
												// defaultInputValue="Select your location"
												placeholder='From where?'
												styles={SelectStyle}
												cacheOptions
												loadOptions={fetchLocationArray}
												onChange={(f) => {
													handlePickUpChange(f);
												}}
												defaultOptions
												name='pickupAddress'
												className={
													Boolean(calculateCostFormik.touched?.pickupAddress && calculateCostFormik.errors?.pickupAddress)
														? "form-control border-danger p-0 "
														: "form-control p-0 mb-md-5"
												}
												value={
													Boolean(calculateCostFormik.values?.pickupAddress)
														? {
																label: calculateCostFormik.values.pickupAddress,
																value: calculateCostFormik.values.pickupAddress,
														  }
														: null
												}
												onFocus={() => {
													calculateCostFormik.setFieldValue("pickupAddress", "");
													// setDestinationShowCity({ from: "" })
												}}
											/>
										</div>
										{Boolean(calculateCostFormik.touched.pickupAddress && calculateCostFormik.errors.pickupAddress) && (
											<p className='text-danger mt-2'>{calculateCostFormik.errors.pickupAddress}</p>
										)}
										<div className='mb-3'>
											{/* <label for="exampleFormControlInput1" className="form-label fw-semibold">Transport Destination</label> */}
											{/* <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="ZIP or City"/> */}
											<AsyncSelect
												placeholder='To where?'
												styles={SelectStyle}
												components={{ NoOptionsMessage }}
												cacheOptions
												loadOptions={fetchLocationArray}
												onChange={(f) => {
                                     
													handleDropOffChange(f);
												}}
												defaultOptions
												name='dropOffAddress'
												className={
													Boolean(calculateCostFormik.touched?.dropOffAddress && calculateCostFormik.errors?.dropOffAddress)
														? "form-control border-danger p-0"
														: "form-control p-0"
												}
												value={
													Boolean(calculateCostFormik?.values?.dropOffAddress)
														? {
																label: calculateCostFormik?.values?.dropOffAddress || "",
																value: calculateCostFormik?.values?.dropOffAddress || "",
														  }
														: null
												}
												onFocus={() => {
													calculateCostFormik.setFieldValue("dropOffAddress", "");
													// setDestinationShowCity({...destinationShowCity, to: "" })
												}}
											/>
										</div>
										{Boolean(calculateCostFormik.touched.dropOffAddress && calculateCostFormik.errors.dropOffAddress) && (
											<p className='text-danger mt-2'>{calculateCostFormik.errors.dropOffAddress}</p>
										)}
									</div>
								</form>
							</div>

                     <div className="container">
                        {Boolean(
                           calculateCostFormik?.values?.pickupAddress === "" ||
                           calculateCostFormik?.values?.dropOffAddress === "" || (calculateCostFormik?.values?.pickupAddress === calculateCostFormik?.values?.dropOffAddress)
                        ) ? (
                           <>
                              <button
                                 type="button"
                                 className="btn btn-secondary w-100 py-3 fw-semibold disabled"
                                 style={{ cursor: "not-allowed", marginTop: "10px" }}
                                 disabled
                              >
                                 {/* Get a quote */}
                                 Next
                              </button>
                              {
                                 ((calculateCostFormik?.values?.pickupAddress === calculateCostFormik?.values?.dropOffAddress) && calculateCostFormik?.values?.pickupAddress !== '' && calculateCostFormik?.values?.dropOffAddress !== "")
                                    ?
                                    (<p className="text-danger">Please select different pickup and dropoff points</p>)
                                    :
                                    ""
                              }
                           </>
                        ) : (
                           <button
                              type="button"
                              className="button btn-primary w-100 py-3"
                              style={{ cursor: "pointer", marginTop: "10px" }}
                              onClick={() => {
                                 // handleGetQuote();
                                 // console.log("here to navigate...")
                                 navigate("/new-quote-details")
                              }}
                           >
                              {/* Get a quote */}
                              Next
                           </button>
                        )}
                        {Boolean(
                           calculateCostFormik.touched.checkInQuote &&
                           calculateCostFormik.errors.checkInQuote
                        ) && (
                              <p className="text-danger mt-2">
                                 {calculateCostFormik.errors.checkInQuote}
                              </p>
                           )}

                        <div className="quoteText p-2  d-md-none d-block">
                           <h6 className="fw-bolder p-13  mt-2 text-center ">
                              101 Drivers is a platform which connects drivers with car
                              dealers and individuals that need drivers.
                           </h6>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="scrollHeight"></div>
               <div className="d-none">
                  <Map
                     place={place}
                     directionsResponse={directionsResponse}
                     setDirectionsResponse={setDirectionsResponse}
                  >
                  </Map>
               </div>
            </main>
            {
        isAuth() ? 
        <Footer />
        :
        ""
      }
         </div>

         {/* <!-- Modal --> */}
         <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
         >
            <div className="modal-dialog modal-dialog-centered">
               <div className="modal-content mx-4">
                  <div className="modal-body ">
                     <h6 className="modal-title fw-bold text-center p-16">
                        Are you business or individual?
                     </h6>
                  </div>
                  <div className="d-flex justify-content-center">
                     <button
                        className="modal-button"
                        onClick={() => navigate("/register")}
                        data-bs-dismiss="modal"
                     >
                        <div className="mx-3 ">
                           <div
                              style={{ width: "90px" }}
                              className="modal-image-contain text-align-center mb-2"
                              onClick={() => { setIsBusiness(true) }}
                           >
                              <img
                                 src="./component/images/Business.svg"
                                 alt="#"
                                 style={{ width: "100%" }}
                              />
                           </div>
                           <p className="fw-semibold text-center">Business</p>
                        </div>
                     </button>
                     <button
                        className="modal-button"
                        onClick={() => navigate("/register")}
                        data-bs-dismiss="modal"
                     >
                        <div className="mx-3 ">
                           <div
                              style={{ width: "90px" }}
                              className="modal-image-contain text-align-center mb-2"
                              onClick={() => { setIsBusiness(false) }}
                           >
                              <img
                                 src="./component/images/Individual.svg"
                                 alt="#"
                                 style={{ width: "100%" }}
                              />
                           </div>
                           <p className="fw-semibold text-center">Individual</p>
                        </div>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default NewCalculateCost