import { toast } from "react-hot-toast";
import { fileUploadApi } from "../api";
import { API_BASE_URL } from "./BaseUrl";


const imageTypeRegex = /image\/(jpg|jpeg|png|jfif|webp|JPG|JPEG|PNG|JFIF|WEBP)/gm;



export const logoutOnJwtExpire = () => {
   localStorage.clear();
   window.location.href = "/new-quote"
};

export const getLastElementId = () => {
  const path = window.location.pathname.split("/new-quote");
  return path[path.length - 1];
};

export const getToken = () => localStorage.getItem("token");
export const setToken = (token) => localStorage.setItem("token", token);
export const removeToken = () => localStorage.removeItem("token");
export const isAuth = () => Boolean(getToken());

export const handleFileUpload = async (event, formik, setLoader) => {
  const logoImg = event.target?.files?.[0];
  if(!logoImg){
    return
  }
  const toastLoader = toast.loading("Uploading...")

   if (!logoImg) {
     return
   }
 
   const imgFile = new FormData();
   imgFile.append("file", logoImg);
 
 
   if (logoImg.size > 15000000) {
     toast.error("Please upload file less than 15 mb");
    //  formik.setFieldValue(event.target.name, "")
    //  setLoader(false)
    toast.dismiss(toastLoader)
     return
   }
 
   try {
    //  setLoader(true)
 
     if (logoImg.name.match(/\.(jpg|jpeg|png|jfif|webp|JPG|JPEG|PNG|JFIF|WEBP)$/)) {
       const res = await fileUploadApi(imgFile);
       if (res.status === 200) {
         formik.setFieldValue(event.target.name, res?.data?.filePath);
        //  setLoader(false)
        toast.dismiss(toastLoader)
         toast.success(res?.data?.message, { id: "001" });
       } 
       // else if (res.status === 413) {
       //   toast.error("Please upload file less than 15 mb");
       // }
     } else {
      //  setLoader(false)
    toast.dismiss(toastLoader)

       toast.error("Unsupported Image Format", { id: "etrr01" });
     }
   } catch (error) {
    //  setLoader(false)
    toast.dismiss(toastLoader) 
   }
 };

 export const globalHandleChange = (e, formik) => {
  formik.setFieldValue(e.target.name, e.target.value)
 }

 export const formatPhoneNumberWithName = (data) => {
  const phoneNumber = data?.phone?.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber?.length;

  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
  }

  return (
    <h6 className='fw-bold mb-4 '>
      Call the car owner {`${data?.name}`}{" "}
      <a className="text-decoration-none" href={`tel:${data?.phone}`}> {`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`}</a> and agree the
      delivery time.
    </h6>
  );
};


export const formatPhoneNumber1 = (data) => {
  const phoneNumber = data?.phone?.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber?.length;
  // console.log(phoneNumber,"number in side the function...")
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
  }

  return (
      <a className="text-decoration-none dark-clr" href={`tel:${data?.phone}`}> {`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`}</a> 

      // console.log(`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`,"7777777777777",phoneNumber)
  );
};

export const formatPhoneNumber2 = (data) => {
  const phoneNumber = data?.phone?.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber?.length;

  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
  }

  const x = `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`

  return "+1" +x
};
export const formatPhoneNumber4 = (data) => {
  const phoneNumber = data?.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber?.length;
  // console.log(phoneNumber,"number in side the function...")
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
  }

  return (
    <a className="text-decoration-none dark-clr" href={`tel:${data?.phone}`}> {`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`}</a>

    // console.log(`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`,"7777777777777",phoneNumber)
  );
};

export const formatPhoneNumber3 = (data) => {
  const phoneNumber = data?.customerPhone?.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber?.length;
  // console.log(phoneNumber,"number in side the function...")
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
  }

  return (
      <a className="text-decoration-none dark-clr" href={`tel:${data?.phone}`}> {`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`}</a> 

      // console.log(`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`,"7777777777777",phoneNumber)
  );
};

export const handleMapClick = (address) => {
  window.open(`https://www.google.com/maps?q=${encodeURIComponent(address)}, "_blank"`);
};



export const handleFileUploadForPickDrop = (e, imageFiles, setImageFiles, formik) => {
  const { files } = e.target;

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if(file.type.match(imageTypeRegex)) { imageFiles.push(file)} else {
      toast.error("Only valid images are allowed",{ id :"001" })
    }
    imageFiles.length <= 6 ? setImageFiles([...imageFiles]) : toast.error("Images shouldn't be more than 6", { id: "err01" });
  }
  imageFiles?.length < 6 && toast(`Please add ${6 - imageFiles.length} more images`, { icon: "📸", id: "09" });
  if (imageFiles.length >= 6) {
    document.getElementById("photos").disabled = true;
    document.getElementById("addBtn1").setAttribute("class", "changeFileBtn  btn button py-1 bg-secondary");
    document.getElementById("addBtn1").setAttribute("style", " cursor :inherit");
  }
  formik.setFieldValue("images", imageFiles);
};

export const handleFileRemoveForPickDrop = (imageFiles, setImageFiles, i, formik) => {
  const name = imageFiles[i];

  if (imageFiles.length < 7) {
    document.getElementById("photos").disabled = false;

    // document.getElementById("photos").setAttribute("disabled", `${false}`);
    document.getElementById("addBtn1").setAttribute("class", "changeFileBtn  btn button py-1 bg-red ");
    document.getElementById("addBtn1").setAttribute("style", " cursor :pointer");
  }

  const newFilter = imageFiles.filter((el, i) => {
    return el !== name;
  });

  // formik.setFieldValue("images", newFilter);
  // return setImageFiles([...newFilter]);

  if (newFilter.length > 0) {
    formik.setFieldValue("images", newFilter);
    return setImageFiles([...newFilter]);
  } else {
    formik.setFieldValue("images", "");
    return setImageFiles([...newFilter]);
  }
};

export const assignBucketUrl = (path) => {
  // console.log(path, "<><><><")
  return encodeURI(path?.startsWith("upload/") ? API_BASE_URL + "/" + path : path)
}