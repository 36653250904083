const SelectStyle = {
    // control: styles => ({ ...styles, backgroundColor: 'blue' }),
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {

        return {
            ...styles,
           
            width: "100%",

            padding: "0.35rem 0.5rem",
            fontSize: "1rem",
            fontWeight: "400",
            lineHeight: "1.3",
            color: "#16192c",
            backgroundColor: "#fff",
            backgroundClip: "padding-box",
            border: "1px solid #e7eaf0",
            appearance: "none",
            borderRadius: "0.375rem",
            boxShadow: " 0px 1px 2px rgba(50, 50, 71, 0.08)",
            

        };
    }

};

export default SelectStyle;
