import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

const RegisterStep1 = ({ children, regFormik1, checkLength, setCheckLength }) => {

   const [passwordType, setPasswordType] = useState("password");
   const [confirmPasswordType, setConfirmPasswordType] = useState("password");

   const handleChange = (e) => {
      e.preventDefault();
      regFormik1.setFieldValue(e.target.name, e.target.value);
   }

   const handleChange2 = (e) => {
      e.preventDefault();
      regFormik1.setFieldValue(e.target.name, e.target.value.replace(/[^\w\s]/gi, ""))
   }

   return (
      <>
         {/* <h1 className='fw-bold mb-1 col-md-11'>Create Account!</h1>
         <p className="text-muted mb-6 fw-semibold">
            Create your account and start benefit from the convenience our drivers provide to you.
         </p> */}

         <form onSubmit={regFormik1.handleSubmit}>
            {/* {checkLength && (
               <SweetAlert
                  title={"Use 8 or more characters with a mix of letters, numbers & symbols"}
                  style={{fontSize:"12px"}}
                  onConfirm={() => {
                     setCheckLength(false)
                     regFormik1.setFieldError("password", null);
                  }}
                  onCancel={() => {
                     regFormik1.setFieldError("password", null);
                  }}
               />
            )} */}
            <div className="mb-4">
               {/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">First name</label> */}
               <input
                  type="text"
                  // className="form-control"
                  className={Boolean(regFormik1.touched?.firstName && regFormik1.errors?.firstName) ? "form-control border-danger p-3" : "form-control p-3"}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="First name"
                  name='firstName'
                  value={regFormik1.values.firstName}
                  onChange={(e) => handleChange2(e)}
               />
               {Boolean(regFormik1.touched.firstName && regFormik1.errors.firstName) && <p className='text-danger mt-2'>{regFormik1.errors.firstName}</p>}
            </div>
            <div className="mb-4">
               {/* <label htmlFor="exampleInputEmail1" className="form-label  fw-semibold">Last name</label> */}
               <input
                  type="text"
                  // className="form-control"
                  className={Boolean(regFormik1.touched?.lastName && regFormik1.errors?.lastName) ? "form-control border-danger p-3" : "form-control p-3"}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Last name"
                  name='lastName'
                  value={regFormik1.values.lastName}
                  onChange={(e) => handleChange2(e)}
               />
               {Boolean(regFormik1.touched.lastName && regFormik1.errors.lastName) && <p className='text-danger mt-2'>{regFormik1.errors.lastName}</p>}
            </div>
            <div className="mb-4">
               {/* <label htmlFor="exampleInputEmail1" className="form-label  fw-semibold">Email </label> */}
               <div className="position-relative">
                  <input
                     type="text"
                     // className="form-control border-end-0"
                     className={Boolean(regFormik1.touched?.email && regFormik1.errors?.email) ? "form-control border-danger p-3 pe-5" : "form-control p-3 pe-5"}
                     id="exampleInputEmail1"
                     aria-describedby="emailHelp"
                     placeholder="Email "
                     name='email'
                     value={regFormik1.values.email}
                     onChange={(e) => handleChange(e)}
                  />
                  <i className="bi bi-envelope-fill position-absolute icon-p  text-fade"></i>
               </div>
               {Boolean(regFormik1.touched.email && regFormik1.errors.email) && <p className='text-danger mt-2'>{regFormik1.errors.email}</p>}
            </div>
            <div className="mb-2">
               {/* <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Password</label> */}
               <div className="position-relative">
                  <input
                     type={passwordType}
                     // className="form-control border-end-0"
                     className={Boolean(regFormik1.touched?.password && regFormik1.errors?.password) ? "form-control border-danger p-3" : "form-control p-3 pe-5"}
                     id="exampleInputPassword1"
                     placeholder="Password"
                     name='password'
                     value={regFormik1.values.password}
                     onChange={(e) => handleChange(e)}
                  />
                  <i className={`bi ${passwordType === "password" ? "bi-eye-slash" : "bi-eye"} position-absolute icon-p text-muted `} style={{ cursor: 'pointer' }} onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></i>
               </div>
            </div>
            {/* <div className="text-end">
                  <p className="p-13"> Password should be of maximum 8 characters</p>
               </div> */}
            {/* {Boolean(regFormik1.touched.password && regFormik1.errors.password) && <p className='text-danger mt-2'>{regFormik1.errors.password}</p>} */}

            {/* <p
               className={`mt-2 ${
                  regFormik1.touched.password && regFormik1.errors.password ? "text-danger fw-normal" : "fw-bold"
               } `}
               style={{ fontSize: "15px", color: "rgba(0,0,0,0.37)  " }}>
               Password: MUST contain at least 8 characters, at least one uppercase letter, at least one number and at
               least one special character ({"!”#$%&'()*+,-./:;<=>?@[]^_`{|}~"} )
               </p> */}
            {Boolean(regFormik1.touched.password && regFormik1.errors.password) && <p className='text-danger mt-2'>{regFormik1.errors.password}</p>}
            <p
               className={`lh-sm  ${regFormik1.touched.password && regFormik1.errors.password ? "text-danger fw-normal" : "fw-bold"
                  } `}
               style={{ fontSize: "14px", color: "rgba(0,0,0,0.37)  " }}>
               Password must have 8+ characters, 1 uppercase letter, 1 number and 1 special character.
            </p>
            <div className="mb-4">
               {/* <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Confirm password</label> */}
               <div className='position-relative'>

                  <input
                     type={confirmPasswordType}
                     // className="form-control"
                     className={Boolean(regFormik1.touched?.confirmPassword && regFormik1.errors?.confirmPassword) ? "form-control border-danger p-3" : "form-control p-3 pe-5"}
                     id="exampleInputPassword1"
                     placeholder="Confirm password"
                     name='confirmPassword'
                     value={regFormik1.values.confirmPassword}
                     onChange={(e) => handleChange(e)}
                  />
                  <i className={`bi ${confirmPasswordType === "password" ? "bi-eye-slash" : "bi-eye"} position-absolute icon-p text-muted`} style={{ cursor: "pointer" }} onClick={() => setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password")}></i>
               </div>
               {/* <p className='m-0 p-12 fw-semibold opacity-50'  >Use 8 or more characters with a mix of letters, numbers & symbols</p> */}
            </div>
            {/* {Boolean(regFormik1.touched.confirmPassword && regFormik1.errors.confirmPassword) && <p className='text-danger mt-2'>{regFormik1.errors.confirmPassword}</p>} */}
            
            <div className="text-center my-4">
               {/* <button type="submit" className="button text-white w-100  py-3">
                  Next
               </button> */}
               {children}
            </div>
         </form>
      </>
   )
}

export default RegisterStep1