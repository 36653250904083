import React, { useContext, useState } from 'react'
import { GigContext, RequestContext } from './UserContext'
import { useFormik } from 'formik';
import { customerInfoValidation, vehicleInfoValidation } from '../Validation/Validation';
import { createGigApi } from '../api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const RequestCreateState = (props) => {
    
    const [requestStep, setRequestStep] = useState(1);
  
  return (
    <RequestContext.Provider
    value={{
      requestStep, 
      setRequestStep,
    }}
    >
        {props.children}
    </RequestContext.Provider>
  )
}

export default RequestCreateState