import React from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer';

const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <>
  <Header/>
      <main>
        <div className="container">
         
          <div className="d-flex align-items-center" style={{ height: "85vh" }}>
            <div className="container ">
              <div className="m-auto text-center d-lg-block d-none">
                <img
                  src="./component/images/Image 159.png"
                  alt=" "
                  style={{ width: "40%" }}
                  className=""
                />
              </div>

              <div className="m-auto text-center d-lg-none d-block">
                <img
                  src="./component/images/Image 159.png"
                  alt=" "
                  style={{ width: "80%" }}
                  className=""
                />
              </div>
              <div className="text-center dark-clr">
                <h4 className="fw-bold  m-0 my-2">Thank you for </h4>
                <h4 className="fw-bold  m-0 my-2 ">using 101 Drivers</h4>
              </div>
              <div className="text-center my-3">
                <button
                  type="submit"
                  className="button  w-50 py-3 fw-semibold"
                  onClick={() => navigate("/")}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
};

export default ThankYou;
