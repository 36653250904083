import React from "react";
import { Link } from "react-router-dom";

function ResetThankyou() {

  return (
    <>
      <div className="container px-3">
      <div className='py-3' style={{ height: "100px", width: "100px" }}>
        <img src='./component/images/Group 3621.png' alt='#' style={{ width: "100%" }} />
      </div>
        <div className='my-5 align-items-center'>
          <div className='check-icon m-auto rounded-circle text-center d-flex align-items-center justify-content-center' style={{ height: "85px", width: "85px" }}>
            <img src='../../../../../component/images/Icon ionic-md-checkmark-circle-outline.png' alt='#' />
          </div>
        </div>
        <div className='text-center'>
          <h3 className='fw-bold'>Password Reset</h3>
          <p className='text-muted my-2 mb-4'  >
            Your password has been successfully reset.
            <br />
            Please, click below to log in.
          </p>
        </div>
        <div className='text-center mb-4  '>
          <div>
          <Link to='/login' className=' btn button fs-4 text-white w-100 py-3'>
            Log in
          </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetThankyou;
