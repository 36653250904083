import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import { useNavigate, useParams } from 'react-router-dom'
// import paymentDone from '../component/images/Mask Group 2259.png'
const PaymentSuccess = () => {

    const navigate = useNavigate();
    const params = useParams()

    return (
        <div className="footer-app-container">
            <Header hideBack={true}/>
            <main className='main-content-container d-flex align-items-center justify-content-center '>
                <div className=' payment-main'>
                    <img className='img-fluid' src='../../component/images/paymentSuccess.svg' />
                    <h1 className='payment-done mt-5'>Thank You!</h1>
                    <h6 className='payment-detail'>Payment done successfully</h6>
                    <button className='payment-button-done button w-50 ' 
                    data-bs-toggle="modal"
                        data-bs-target="#publish" >Done</button>
                </div>
            </main>
            {/* modal */}
            <div
                className="modal fade"
                id="publish"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-4">


                        <div className="modal-body row text-center justify-content-center p-0">
                            <div className="" >
                                <img
                                    src="../component/images/Image 159.png"
                                    alt=" "
                                    style={{ width: "70%" }}
                                    className="img-fluid"
                                />
                            </div>
                            <div className="text-center dark-clr">
                                <h2 className="fw-bold  m-0 my-2">Thank you for </h2>
                                <h2 className="fw-bold  m-0 my-2">your order!</h2>
                                <p className="m-0 p-12 my-2 fw-semibold">We have sent the link to your e-mail </p>
                            </div>
                            <div className="text-center my-3">
                                <button type="submit" data-bs-dismiss="modal" className="button  w-75 py-3 fw-semibold" 
                                onClick={() =>
                                // navigate('/thank-you')
                                // {
                                    navigate(`/confirm-order/${params?.orderId}`)
                                //     // toast.success("Gig created successfully")
                                // }

                                    // navigate (`/pickup-details/${params?.orderId}`)

                                    // handleSubmit()
                                  
                                }
                                >
                                    See your order
                                </button>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PaymentSuccess