import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { API_BASE_URL } from "../../../../Utils/BaseUrl";
import { ImgLoader, Loader2 } from "../../../../Utils/Loader";
import { assignBucketUrl, handleFileUpload } from "../../../../Utils/HelperFunction";
import PlacesAutocomplete from "react-places-autocomplete";

import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import AutofillAddress from "./AutofillAddress";
import ProfilePicDemo from "../../../../Assets/component/images/image_2.jpeg";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { UserContext } from "../../../../app_context/UserContext";

const RegisterStep2 = ({ children, regFormik2, fetchStates, fetchCities, stateCode, setStateCode, cityData }) => {
	const [viewCity, setViewCity] = useState("");
	const [viewState, setViewState] = useState("");
	const [value, setValue] = useState();

	const app_context = useContext(UserContext);
	const { isBusiness, setIsBusiness } = app_context;

	const inputRef = useRef();
	const imgRef = useRef();

	const handleRemove = () => {
		inputRef.current.src = "/component/images/image_2.jpeg";
	};

	const handleChangePhoneInput = (value) => {
		regFormik2.setFieldValue("phone", value);
		// if (e.target.value.length >= 12) {
		//    e.preventDefault()
		// } else {
		//    regFormik2.handleChange(e);
		//    regFormik2.setFieldValue(e.target.name, e.target.value);
		// }
	};

	useEffect(() => {
		fetchStates();
	}, []);

	useEffect(() => {
		fetchCities(stateCode);
	}, [stateCode]);

	const cityOptions =
		cityData?.length > 0 &&
		cityData?.map((item) => {
			const container = {};
			container["value"] = item.stateCode;
			container["label"] = `${item.name}`;
			return container;
		});

	const handleSelect = (e) => {
		setStateCode(e.value);
		setViewState(e);
		regFormik2.setFieldValue("state", e.label);
		regFormik2.setFieldValue("city", "");
		setViewCity("");
	};

	const cityHandleClick = () => {
		if (regFormik2?.values.state == "" || regFormik2?.state?.length === 0) {
			toast.error("Please select the state first", { id: "etrr01" });
		}
	};

	return (
		<>
			{/* <p className="text-muted mb-6">Create your account and get the sold cars driven smoothly to their buyers!</p> */}

			<form onSubmit={regFormik2.handleSubmit}>
				<div className='mb-3'>
					{/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Phone number</label> */}
					{/* <input
                  type="number"
                  className={Boolean(regFormik2.touched?.phone && regFormik2.errors?.phone) ? "form-control border-danger p-3" : "form-control p-3"}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="(XXX)-XXX-XXXX"
                  name='phone'
                  value={regFormik2.values.phone}
                  onChange={(e) => handleChange(e)}
               /> */}
					<Input
						className={
							Boolean(regFormik2.touched?.phone && regFormik2.errors?.phone)
								? "form-control border-danger cu-45 e-caret-hide"
								: "cu-45 form-control e-caret-hide"
						}
						defaultCountry='US'
						countries={["US"]}
						name='phone'
						placeholder={isBusiness === true ? "Business phone number" : "Phone number"}
						value={regFormik2.values.phone}
						onChange={handleChangePhoneInput}
						PhoneInputCountrySelectArrow={false}
						international={false}
					/>
					{Boolean(regFormik2.touched.phone && regFormik2.errors.phone) && <p className='text-danger mt-2'>{regFormik2.errors.phone}</p>}
				</div>

				{isBusiness === true ? (
					<div className='mb-3'>
						{/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Your company</label> */}
						<input
							type='text'
							className={Boolean(regFormik2.touched?.company && regFormik2.errors?.company) ? "form-control border-danger p-3" : "form-control p-3"}
							id='exampleInputEmail1'
							aria-describedby='emailHelp'
							placeholder='Company'
							name='company'
							value={regFormik2.values.company}
							onChange={regFormik2.handleChange}
						/>
						{Boolean(regFormik2.touched.company && regFormik2.errors.company) && <p className='text-danger mt-2'>{regFormik2.errors.company}</p>}
					</div>
				) : (
					""
				)}

				<AutofillAddress regFormik2={regFormik2} isBusiness={isBusiness} />

				{isBusiness === true ? (
					""
				) : (
					<div>
						<div>
							<h6 className=' fw-semibold mt-3'>Profile picture</h6>

							<div className='d-flex justify-content-between align-items-center mb-3 '>
								<div style={{ height: "50px", width: "50px", position: "relative" }} className=' '>
									<span className='image-section  position-absolute end-0'>
										<i className='bi bi-x-circle   ' style={{ cursor: "pointer" }} onClick={handleRemove}></i>
									</span>
									{/* {
                              regFormik2?.values?.profilePicture !== "" ? (
                                 <img 
                                 ref={inputRef} 
                                 src={encodeURI(`${API_BASE_URL}/${regFormik2?.values?.profilePicture}`)} alt='#' style={{ width: "100%", height:"100%", objectFit:"contain" }} className='border rounded-3 ' />
                                 ) : (
                                 (loader ?
                                 (<ImgLoader />)
                                 :
                                 (<img src='/component/images/image_2.jpeg' alt='#' style={{ width: "100%" }} className='border rounded-3 ' />)
                                 )
                              )
                           } */}

									<img
										ref={inputRef}
										// src={encodeURI(`${API_BASE_URL}/${regFormik2?.values?.profilePicture}`)}
										src={assignBucketUrl(regFormik2?.values?.profilePicture)}
										alt='#'
										style={{ width: "100%", height: "100%", objectFit: "fill" }}
										className='border rounded-3 '
										onError={(event) => {
											event.target.src = ProfilePicDemo;
											event.onerror = null;
										}}
									/>
								</div>

								<label htmlFor='profilePic'>
									<div
										onClick={() => {
											imgRef?.current?.click();
											// regFormik2.setFieldValue("profilePicture", "");
										}}
										style={{ cursor: "pointer" }}
										className='btn1 py-1 h-100 rounded-2'>
										Upload
									</div>
								</label>
								<input
									type='file'
									id='profilePic'
									name='profilePicture'
									onChange={(e) => {
										handleFileUpload(e, regFormik2);
										// regFormik2.setFieldValue("profilePicture", "");
									}}
									className='d-none'
									accept='image/*'
								/>
							</div>
							{Boolean(regFormik2.touched.profilePicture && regFormik2.errors.profilePicture) && (
								<p className='text-danger mt-2'>{regFormik2.errors.profilePicture}</p>
							)}
						</div>
						<p className={`mt-2 fw-bold`} style={{ fontSize: "15px", color: "rgba(0,0,0,0.37)  " }}>
							Profile image is not mandatory
						</p>
					</div>
				)}
				<div className='text-center my-4'>{children}</div>
				<div className='d-flex mb-3'>
					{/* <input type="checkbox"
                  name='signinPolicy'
                  value={regFormik2.values.signinPolicy}
                  onChange={(e) => regFormik2.setFieldValue("signinPolicy", e.target.checked ? true : false)} /> */}
					<span>
						<p className='my-auto ps-2 text-black fw-semibold p-12'> By signing up, I agree 101 driver's Privacy Policy.</p>
					</span>
				</div>
				{Boolean(regFormik2.touched.signinPolicy && regFormik2.errors.signinPolicy) && <p className='text-danger text-xs'>{regFormik2.errors.signinPolicy}</p>}
			</form>
			<br />
		</>
	);
};

export default RegisterStep2;
