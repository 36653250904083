import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createPayment, createPrice, paypalApi } from '../../api'
import { toast } from 'react-hot-toast';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer/Footer';
import { WEB_URL } from '../../Utils/BaseUrl';

const ApplePay = () => {

    const navigate = useNavigate()

    const params = useParams()

    const handleSubmit = async () => {
        
        const payload = {
            orderId: params?.orderId
        }
        
        // try {
            //     const res = await paypalApi(payload);
            //     if (res.status === 200) {
                //         // window.location.href = "https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-2C245158JK6828038"
                //     }
                // } catch (err) {
                    //     console.log(err);
                    // }
                    
            try {
            // console.log(params, "paramsparams")
                const res = await createPrice({"orderId" : params?.orderId});
                if (res.status === 200) {
            // console.log(res,"reaponse") 
            // console.log(res?.data?.price?.id,"reaponseee")         
                try {
                   const res1 = await createPayment({
                    "line_items": [{"price": res?.data?.price?.id ,"quantity": 1}], 
                    "success_url": `${WEB_URL}/confirm-order/${params?.orderId}`,
                    "cancel_url": `http://localhost:3000/apple-pay/${params?.orderId}`
                   }) 
                   if(res1?.status === 200){
                    window.location.href = res1?.data?.session?.url    
                   } 
                } catch (error) {
                    return error
                }
            }
        } catch (error) {
            return error
        }
    }

    return (
        <div className="footer-app-container">
        <Header />
            <main className="mb-4 main-content-container ">
                {/* <div className="dark-color d-flex align-items-center justify-content-between">
                    <div style={{width: "60px"}} className=" text-align-center py-2 ms-3">
                        <img src="/component/images/Mask Group 2259.png" alt="#" style={{width: "100%"}} />
                    </div>
                    <div className=" d-flex justify-content-end align-items-center">
                        <div>
                            {/* <!-- <i className="bi bi-list text-white h4 pe-3"></i> --> */}
                {/* <img src="/component/images/Group 3842.svg" className="pe-3" alt="" /> */}
                {/* </div>
                    </div>
                </div>  */}
                
                <div className="container mb-3">
                    <div className="my-3">
                        <h5 className="fw-bold ">Payment method</h5>

                    </div>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label p-14 fw-semibold" for="flexRadioDefault1">
                                <img src="/component/images/Icon payment-credit-card.svg" className="img-fluid  opacity-75 text-fade me-2" alt="" /><span>Credit Card</span>
                            </label>
                        </div>
                    </div>

                    <div className="mt-3 ">
                        <div className="mb-3">
                            <label for="exampleFormControlInput1" className="form-label fw-semibold p-14">Card number<span className="text-danger">*</span></label>
                            <div className="position-relative">
                                <input type="number" className="form-control ps-5 " id="exampleFormControlInput1" placeholder="Card number" />
                                <img src="/component/images/Iconawesome-cc-mastercard.svg" className="img-fluid icon-position-card opacity-25 text-fade" alt="" />
                            </div>
                        </div>
                        <div className="mb-3 row justify-content-between">
                            <div className="col-6">
                                <label for="exampleFormControlInput1" className="form-label fw-semibold p-14">Expiration date<span className="text-danger">*</span></label>
                                <input type="number" className="form-control " id="exampleFormControlInput1" placeholder="Expiration date" />
                            </div>
                            <div className="col-6">
                                <label for="exampleFormControlInput1" className="form-label fw-semibold p-14">Security code<span className="text-danger">*</span></label>
                                <input type="email" className="form-control " id="exampleFormControlInput1" placeholder="Security code" />
                            </div>
                        </div>
                        <div className="border-top"></div>
                        <div>
                            <div className=" pt-2">
                                <div className="form-check my-4">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label p-14 fw-semibold" for="flexRadioDefault1" >
                                        <img src="/component/images/Group 3922.svg" className="img-fluid  opacity-75 text-fade me-2" alt="" />
                                    </label>
                                </div>
                            </div>
                            <div className="form-check my-4">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label p-14 fw-semibold border rounded" for="flexRadioDefault1" >
                                    <img src="/component/images/Mask Group 2342.png" className="img-fluid  opacity-75 text-fade me-2" alt="" />
                                </label>
                            </div>
                            <div className="form-check my-4">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label p-14 fw-semibold" for="flexRadioDefault1" >
                                    <img src="/component/images/Group 3927.svg" className="img-fluid  opacity-75 text-fade me-2" alt="" />
                                </label>
                            </div>
                        </div>
                    </div>


                </div>
               

                <div className="container " style={{position: 'absolute', bottom: 50}}>
                    <button className="button btn-primary w-100 py-3 mb-3"
                        data-bs-toggle="modal"
                        data-bs-target="#publish"
                        // onClick={() =>
                        //     // navigate('/thank-you')
                        //     // navigate (`/pickup-details/${params?.orderId}`)

                        //     // handleSubmit()
                        // }
                    >
                        Place the order
                    </button>
                </div>
            </main>
            <div
                className="modal fade"
                id="publish"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-4">


                        <div className="modal-body row text-center justify-content-center p-0">
                            <div className="" >
                                <img
                                    src="../component/images/Image 159.png"
                                    alt=" "
                                    style={{ width: "70%" }}
                                    className="img-fluid"
                                />
                            </div>
                            <div className="text-center dark-clr">
                                <h2 className="fw-bold  m-0 my-2">Thank you for </h2>
                                <h2 className="fw-bold  m-0 my-2">your order!</h2>
                                <p className="m-0 p-12 my-2 fw-semibold">We have sent the link to your e-mail </p>
                            </div>
                            <div className="text-center my-3">
                                <button type="submit" data-bs-dismiss="modal" className="button  w-75 py-3 fw-semibold" 
                                onClick={() =>
                                // navigate('/thank-you')
                                // {
                                //     // navigate(`/confirm-order/${params?.orderId}`);
                                //     // toast.success("Gig created successfully")
                                // }

                                    // navigate (`/pickup-details/${params?.orderId}`)

                                    handleSubmit()
                                }>
                                    See your order
                                </button>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ApplePay