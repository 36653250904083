import { useFormik } from 'formik';
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { toast } from 'react-hot-toast';
import debounce from "lodash/debounce";
import { useLocation, useNavigate } from 'react-router-dom';

// import { calculateCostValidation, vehicleDetailsValidation } from '../../../Validation/Validation';
import CustomerInfromation from './Steps/CustomerInfromation';
import DatePickerFinal from './Steps/DatePickerFinal';
import DeliveryRequest from './Steps/DeliveryRequest';
import ServiceWork from './Steps/ServiceWork';
import VehicleInfromation from './Steps/VehicleInfromation';
import { GigContext, RequestContext, UserContext } from '../../app_context/UserContext';
import Customer from './Steps/Customer';
import ReviewOrder from './Steps/ReviewOrder';
import Footer from '../../Components/Footer/Footer';
import RequestCreateState from '../../app_context/RequestCreateState';
import Insurance from './Steps/Insurance';
import moment from 'moment';

const Request = (props) => {

  const history = useLocation() 

  const { calculateCostFormik, vehicleInfoFormik, customerInfoFormik, dummy, setDummy, dummyTwo, setDummyTwo, otherModel, setOtherModel, load, setLoad } = useContext(GigContext)
  const { requestStep, setRequestStep } = useContext(RequestContext)


  const payload_1 = history?.state?.data
  const cityShow = { from : history?.state?.fromCity, to : history?.state?.toCity }

  // const [requestStep, setRequestStep] = useState(1);
  // const [createdGigData, setCreatedGigData] = useState("");
  const [checkEditRequest, setCheckEditRequest] = useState(false)  

  const navigate = useNavigate();

  useEffect(()=>{
    // formikRequest.setFieldValue("pickupAddress",history.state?.from || "")
    // formikRequest.setFieldValue("dropOffAddress",history.state?.to || "")

  },[history.state])
  
useEffect(()=>{
  if(!Boolean(calculateCostFormik?.values?.pickupAddress && calculateCostFormik?.values?.dropOffAddress)){
    calculateCostFormik.resetForm()
    vehicleInfoFormik.resetForm()
    customerInfoFormik.resetForm()

   navigate("/new-quote")
  } 
},[calculateCostFormik])
  return ( 
      <div className="footer-app-container">

{requestStep === 1 && <ServiceWork
  // setQuotation={setQuotation}
  setRequestStep={setRequestStep}
/>}

{requestStep === 2 && <DeliveryRequest
  vehicleInfoFormik={vehicleInfoFormik}
  // formikRequest={formikRequest}
  setQuotation={history?.state?.setQuotation}
  setRequestStep={setRequestStep}
  setCheckEditRequest={setCheckEditRequest}
  transportData={payload_1}
  history={history?.state}
/>}

{requestStep === 3 && <DatePickerFinal
  // setQuotation={setQuotation} 
  setRequestStep={setRequestStep}
  vehicleInfoFormik={vehicleInfoFormik}
  transportData={payload_1}
/>}

      {requestStep === 4 && <VehicleInfromation
        // setQuotation={setQuotation}
        vehicleInfoFormik={vehicleInfoFormik}
        setRequestStep={setRequestStep}
        transportData={payload_1}
        history={history?.state}
        vehicleValue={history?.state?.vehicleValue}
        dummy={dummy}
        setDummy={setDummy}
        dummyTwo={dummyTwo}
        setDummyTwo={setDummyTwo}
        otherModel={otherModel}
        setOtherModel={setOtherModel}
        dummyHistory={history?.state?.history?.dummyValues}
      />}

{requestStep === 5 && <CustomerInfromation
  // setQuotation={setQuotation}
  vehicleInfoFormik={vehicleInfoFormik}
  customerInfoFormik={customerInfoFormik}
  setRequestStep={setRequestStep}
/>}

{/* {requestStep === 6 && <Customer
  vehicleInfoFormik={vehicleInfoFormik}
  setRequestStep={setRequestStep}
  transportData={payload_1}
  calculateCostFormik={calculateCostFormik}
/>} */}

{requestStep === 6 && <ReviewOrder
  setRequestStep={setRequestStep}
  vehicleInfoFormik={vehicleInfoFormik}
  transportData={payload_1}
  cityShow={cityShow}
  dummy={dummy}
  setDummy={setDummy}
  dummyTwo={dummyTwo} 
  setDummyTwo={setDummyTwo}
  calculateCostFormik={calculateCostFormik}
  customerInfoFormik={customerInfoFormik}
  load={load}
  setLoad={setLoad}
/>}
{
  requestStep === 99 && <Insurance 
    setRequestStep={setRequestStep}
  />
}

<Footer/>
</div> 
  )
}

export default Request