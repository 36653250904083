import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getGigDetails } from '../../api'
import NewMap from '../../Components/NewMap/NewMap'
import { formatPhoneNumber1, formatPhoneNumber3 } from '../../Utils/HelperFunction'
import { Loader } from '../../Utils/Loader'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

const ConfirmOrder = () => {

  const [gigData, setGigData] = useState({ data: null, loading: true })
  const [directionsResponse, setDirectionsResponse] = useState(null)


  const navigate = useNavigate()

  const params = useParams()

  const getGigsData = async () => {
    setGigData({ loading: true });
    try {
      const res = await getGigDetails(params?.orderId);
      if (res.status === 200) {
        setGigData({ data: res?.data, loading: false });
      }
    } catch (error) {
      setGigData({ loading: false });
    }
  }

  const isZeroMins = (time, type) => {
    const pickTime = moment(time).format("ddd. MMM. D (h:mm A)")
    const timeOnly = pickTime?.split("(")?.slice(-1)[0]
    const dateOnly = pickTime?.split("(")?.slice(0)[0]
    const mins = timeOnly?.split(":")?.slice(-1)[0]

    if (mins.includes("00")) {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")").slice(0)[0]})`
      } else {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")").slice(0)[0]})`
      }
    } else {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.split(")").slice(0)[0]})`
      } else {
        return ` ${dateOnly}, (${timeOnly.split(")").slice(0)[0]})`
      }
    }
  }

  const dropTime = moment(gigData?.data?.dropOffTime).format("hh:mm A")
  // const dt1 = dropTime?.split(":")[0].replace(0, '') + ":" + dropTime?.split(":")[1].replace(":00",'')

  const isZeroMins1 = (time, type, timeToAdd) => {
    const s = Number(timeToAdd)
    const tTime = moment(time).add(s, "s").format()
    const pickTime = moment(tTime).format("ddd. MMM. D (h:mm A)")
    const timeOnly = pickTime?.split("(").slice(-1)[0]
    const dateOnly = pickTime?.split("(").slice(0)[0]
    const mins = timeOnly?.split(":").slice(-1)[0]

    if (mins.includes("00")) {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")").slice(0)[0]})`
      } else {
        return ` ${timeOnly.replace(":00", " ").split(")").slice(0)[0]}`
      }
    } else {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.split(")").slice(0)[0]})`
      } else {
        return `${timeOnly.split(")").slice(0)[0]}`
      }
    }
  }

  // function clearHistory() {
  //   window.history.go(-(window.history.length - 1));
  // }
  // useEffect(() => {
  //   clearHistory();
  // },[])

  useEffect(() => {
    getGigsData()
  }, [params?.orderId])


  return (
    <div className="footer-app-container">
      <Header />
      <main className="mb-4 main-content-container mt-5">
        {/* <div className="dark-color d-flex align-items-center justify-content-between">
          <div style={{ width: "60px" }} className=" text-align-center py-2 ms-3">
            <img src="../component/images/Mask Group 2259.png" alt="#" style={{ width: "100%" }} />
          </div>
          <div className=" d-flex justify-content-end align-items-center">
            <div>
              {/* <!-- <i className="bi bi-list text-white h4 pe-3"></i> --> */}
        {/* <img src="../component/images/Group 3842.svg" className="pe-3" alt="" /> */}
        {/* </div>
          </div>
        </div>  */}


        {gigData && gigData?.loading
          ? (
            <div className='text-center mt-8 pt-4'><Loader /></div>
          ) : (
            <>
              <div className="container my-4 ">

                <div className="mt-3 ">

                  <div className="card  my-3" style={{ border: "1px solid #E9E9E9" }}>
                    {/* <div className="light-color p-12 text-white rounded-pill text-center py-1 px-4 customerActive mb-3 d-flex align-items-center justify-content-center ">
                    <div> */}
                    {/* <!-- <i className="bi bi-dot"></i> --> */}
                    {/* <div style={{ height: "5px", width: "5px" }} className="bg-white rounded-circle me-1"></div>
                    </div><div>Active 07:58:32 pm</div>
                  </div> */}

                    <div className="border-top"></div>
                    <div className="card-body mt-3 ">
                      {/* <div className="row mb-2">
                      <div className="col-5 text-fade p-11">Started</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">Thurs. FEB. 20 (9 AM)</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5 text-fade p-11">Estimated duration</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">{gigData?.data?.estimateEndTime || "-"}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5 text-fade p-11">Estimated time arrival</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">Thurs. FEB. 20 (11 AM)</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5 text-fade p-11">Must arrive latest</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">Thurs. FEB. 20 (11 AM)</div>
                    </div>
                    <div className="border-bottom my-2"></div> */}
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Customer name</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.customerFullName || "-"}</div>
                      </div>
                      {/* <div className="row mb-2">
                      <div className="col-5 text-fade p-11">Buyer</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">Jake Buffett</div>
                    </div> */}
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Phone number</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-14 fw-semibold ">
                          {/* <a href="tel:+" className="text-decoration-none dark-clr">{gigData?.data?.phone || "-"}</a> */}
                          {formatPhoneNumber3(gigData?.data)}
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Customer e-mail</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-14 fw-semibold ">{
                          // Boolean(customerData?.data?.firstName && customerData?.data?.lastName) ? (customerData?.data?.firstName + " " + customerData?.data?.lastName) : "-"
                          gigData?.data?.customerEmail || "-"
                        }</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">License plate</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.vehiclePlateNumber || "-"}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Four last digits of the VIN</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.vinNumber || "-"}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Make and model</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.vehicleMake} {gigData?.data?.vehicleModel || "-"}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Transmission</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.transmission || "-"}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Pickup address</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.pickupAddress || "-"}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Dropoff address</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.dropOffAddress || "-"}</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Distance</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.distance || "-"} miles</div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Estimated duration</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold ">{gigData?.data?.estimateEndTime || "-"}</div>
                      </div>
                      {/* <div className="row mb-2">
                      <div className="col-5 text-fade p-11">Estimated time arrival</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">1 h 20 min</div>
                    </div> */}
                      {/* <div className="row mb-2">
                      <div className="col-5 text-fade p-11">Started</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold">{pickTime}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5 text-fade p-11">Must arrival latest</div>
                      <div className="col-1 text-fade">:</div>
                      <div className="col-5 p-13 fw-semibold ">{dropTime}</div>
                    </div> */}
                      {/* <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Estimated time arrival</div>
                        <div className="col-1 text-fade">:</div> 
                        <div className="col-5 p-13 fw-semibold">{isZeroMins1(gigData?.data?.pickUpDateAndTime, "pick", gigData?.data?.estimatedTimeSeconds)}</div>

                      </div> */}
                      <div className='row mb-2'>
                      <div className='col-5 text-fade p-11'>Earliest pickup time</div>
                      <div className='col-1 text-fade'>:</div>
                      <div className='col-5 p-13 fw-semibold'>
                       {moment(gigData?.data?.pickUpDateAndTime).format("ddd. MMM. D (h:mm A)")}
                      </div>
                    </div>
                      <div className="row mb-2">
                        <div className="col-5 text-fade p-11">Must arrival latest</div>
                        <div className="col-1 text-fade">:</div>
                        <div className="col-5 p-13 fw-semibold">{isZeroMins(gigData?.data?.dropOffTime, "drop")}</div>
                      </div>
                      {/* <div className="row mb-2">
                  <div className="col-5 text-fade p-11">Earliest pickup time</div>
                  <div className="col-1 text-fade">:</div>
                  <div className="col-5 p-14 fw-semibold">
                  {isZeroMins(gigData?.data?.earliestPickUpTime , "pick")}
                  </div>
                </div> */}

                    </div>

                  </div>

                  <div className="text-center " style={{ height: "250px" }}>
                    {/* <img src="../component/images/customerActive.png" className="img-fluid light-color" alt="" style={{ height: "100%", width: "100%", objectFit: "fill" }} /> */}
                    <NewMap
                      from={gigData?.data?.pickupAddress} to={gigData?.data?.dropOffAddress} directionsResponse={directionsResponse}
                      setDirectionsResponse={setDirectionsResponse} />
                  </div>


                </div>
              </div>
              <div className="container  mt-3 mb-5">
                <button className="button  btn-primary w-100 py-3 mb-3"
                  onClick={() =>
                    //  navigate(`/apple-pay/${params?.orderId}`)
                    navigate('/thank-you')
                    // navigate(`/confirm-order/${res?.data?.createRequest?.orderId}`)

                  }>Proceed</button>
                {/* <button className="button  btn-primary w-100 py-3" onClick={() => navigate('/vehicle-delivery-info')} >
              CONFIRM AND PAY
            </button> */}
            <div className="scrollHeight"></div>
              </div>
             
            </>
          )
        }
      </main>
      <Footer />
    </div>
  )
}

export default ConfirmOrder