import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import Login from '../Pages/Account/Login'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import Register from '../Pages/Account/Register'
import MyProfile from '../Pages/Account/MyProfile'
import ResetPassword from '../Pages/Account/ResetPassword'
import ForgotPassword from '../Pages/Account/ForgotPassword'
import ResetThankyou from '../Pages/Account/ResetPassword/Reset_Thankyou'
import Request from '../Pages/Request'
import ApplePay from '../Pages/ApplePay'
import CancellationPolicy from '../Pages/CancellationPolicy'
import ConfirmOrder from '../Pages/ConfirmOrder'
import YourOpinion from '../Pages/YourOpinion'
import RateCourier from '../Pages/RateCourier'
import ThankYou from '../Pages/ThankYou'
import Edit from '../Pages/Edit'
import RegisterBusiness from '../Pages/Account/RegisterBusiness'
import AllGigs from '../Pages/AllGigs'
import TrackOrderMap from '../Pages/TrackOrderMap/TrackOrderMap'
import Sidebar from '../Sidebar'
import ForgotEmail from '../Pages/Account/ForgotEmail'
import Chat from '../Pages/Chat/Chat'
import PreviewOrder from '../Pages/PreviewOrder'
import NewCalculateCost from '../Pages/NewFlow/NewCalculateCost'
import NewQuoteDetails from '../Pages/NewFlow/NewQuoteDetails'
import GigContextState from '../app_context/GigContextState'
import RequestCreateState from '../app_context/RequestCreateState'
import PaymentSuccess from '../Pages/PaymentStatus/PaymentSuccess'
import PaymentFailure from '../Pages/PaymentStatus/PaymentFailure'
import ViewGig from '../Pages/AllGigs/ViewGig'


const MainRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
        <Route path="/register-business" element={ <PublicRoute><RegisterBusiness/></PublicRoute> } />
       
        <Route path='/forgot-password' element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path='/forgot-email' element={<PublicRoute><ForgotEmail /></PublicRoute>} />
        <Route path='/reset-password' element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route path='/my-thankyou' element={<PublicRoute><ResetThankyou /></PublicRoute>} />

        {/* private routes */}
        <Route path='/request/create' element={
          <GigContextState>
            <RequestCreateState>
              <PrivateRoute>
                <Request />
              </PrivateRoute>
            </RequestCreateState>
          </GigContextState>} 
        />
        <Route path='/confirm-order/:orderId' element={<PrivateRoute><ConfirmOrder /></PrivateRoute>} /> 
        <Route path='/apple-pay/:orderId' element={<PrivateRoute><ApplePay /></PrivateRoute>} /> 
        <Route path='/cancel-policy/:gigId' element={<PrivateRoute><CancellationPolicy /></PrivateRoute>} /> 

        <Route path='/preview-details' element={<PreviewOrder />} />

        <Route path='/opinion/:orderId' element={<PrivateRoute><YourOpinion /></PrivateRoute>} />
        <Route path='/rate-courier/:orderId' element={<PrivateRoute><RateCourier /></PrivateRoute>} />
        <Route path='/thank-you' element={<PrivateRoute><ThankYou /></PrivateRoute>} />
        <Route exact path='/' element={<PrivateRoute><AllGigs/></PrivateRoute>} />
        <Route path='/edit/:gigId' element={<PrivateRoute><Edit /></PrivateRoute>} />
        <Route path='/viewgig' element={<PrivateRoute><ViewGig /></PrivateRoute>} />

        <Route path='/track-your-order/:gigId' element={<PrivateRoute><TrackOrderMap /></PrivateRoute>} /> 

        <Route path='/my-profile' element={<PrivateRoute><MyProfile /></PrivateRoute>} /> 

        <Route path='/sidebar' element={<Sidebar />} />

        <Route path='/chat' element={<Chat/>} />
        <Route path='/paymentSuccess/:orderId' element={<PaymentSuccess/>} />
        <Route path='/paymentFailure/:orderId' element={<PaymentFailure/>} />

        {/* new routes... */}
        <Route path="/new-quote" element={<GigContextState><NewCalculateCost/></GigContextState>} />
        <Route path="/new-quote-details" element={<GigContextState><NewQuoteDetails/></GigContextState>} />

      </Routes>
      <Toaster position='top-center' reverseOrder={false}/>
    </>
  )
}

export default MainRouter