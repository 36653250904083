
import React, { useState } from 'react'
import { useLocation, useNavigate} from 'react-router-dom'
import { Loader } from '../../Utils/Loader';
import Header from '../../Components/Header';
const PreviewOrder = () => {

 

  
//   const [beforeData, setBeforeData] = useState({data: null , loading: false})
//   const [afterData, setAfterData] = useState({data: null , loading: false})

 
  

  // const params = useParams()

    const location = useLocation(); 


  return (
    <>
        {/* <div className="dark-color d-flex align-items-center justify-content-between">
      <div style={{width: "60px"}} className=" text-align-center py-2 ms-3">
        <img src="../component/images/Mask Group 2259.png" alt="#" style={{width: "100%"}}/>
      </div>
      <div className=" d-flex justify-content-end align-items-center">
        <div>
          {/* <!-- <i className="bi bi-list text-white h4 pe-3"></i> --> */}
          {/* <img src="../component/images/Group 3842.svg" className="pe-3" alt=""/>
        </div>
      </div>
    </div>  */}

    <Header/>

   {location?.state?.beforeData?.loading || location?.state?.afterData?.loading ? (
   (<div className='text-center mt-4 mb-4'><Loader/></div>)
   ) : (
    <main className="container my-3 px-3">
   

    <div>
     <div className='d-flex justify-content-between align-items-center mb-3'>
     <p className="text-14 m-0 fw-semibold">Photos before pickup</p>
     
     </div>
    
      
      <div className="row mb-3" style={{height: "200px",  overflow:"auto" }}  >
      
        {location?.state?.beforeData?.length >= 0 &&
                location?.state?.beforeData?.map((el, i) => {
                  return (
                    <div className='col-4 mb-3 position-relative' style={{ height: "80px" }}>
                      <span
                        style={{ cursor: "pointer", right: "0.5rem" }}
                        className='position-absolute'
                        // onClick={() => handleFileRemoveForPickDrop(imageFiles, setImageFiles, i, pickUpFormik)}
                        >
                        {/* <i className='bi bi-x-circle me-2 crossCircle'></i> */}
                      </span>
                      <img
                        src={el}
                        alt=''
                        className='img-fluid rounded'
                        style={{ width: "100%", height: "100%", objectFit: "fill", border: "1px solid grey" }}
                      />
                    </div>
                    // console.log(location?.state?.beforeData,"ddddddddd")
                  );
                })}
      
      </div>



      <div>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <p className="text-14 m-0 fw-semibold">Photos after delivery</p>
       
      </div>
       <div className='row mb-3' style={{height: "200px", overflow: "auto" }}>

        {location?.state?.afterData?.length >= 0 &&
                location?.state?.afterData?.map((el, i) => {
                  return (
                    <div className='col-4 mb-3 position-relative' style={{ height: "80px" }}>
                      <span
                        style={{ cursor: "pointer", right: "0.5rem" }}
                        className='position-absolute'
                        // onClick={() => handleFileRemoveForPickDrop(imageFiles, setImageFiles, i, dropOffFormik)}
                        >
                        {/* <i className='bi bi-x-circle me-2 crossCircle'></i> */}
                      </span>
                      <img
                        src={el}
                        alt=''
                        className='img-fluid rounded'
                        style={{ width: "100%", height: "100%", objectFit: "fill", border: "1px solid grey" }}
                      />
                    </div>
                    // console.log(location?.state?.afterData,"dddddddddddddddddddddddddddd")
                  );
                })}

       </div>
      
      </div>
     
    
    </div>
    <div style={{height: "75px"}}></div>
    <button type="submit" className="button text-white w-100 py-3 fw-semibold" 
    onClick={() => {window.history.back()}}
    >
    Go to previous page
    
    </button>

    
  </main>
   )}
    </>
  )
}

export default PreviewOrder