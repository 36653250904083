import React, { useContext, useEffect, useState } from 'react'
import Input from 'react-phone-number-input/input';
import { Link, useNavigate } from 'react-router-dom'
import { myProfileApi } from '../../../api';
import Navbar from './Navbar';
import { formatPhoneNumber } from 'react-phone-number-input'
import { UserContext } from '../../../app_context/UserContext';
import Header from '../../../Components/Header/Header';


const CustomerInfromation = ({ customerInfoFormik, setRequestStep }) => {

  const app_context = useContext(UserContext)
  const { customerData } = app_context

  // console.log(customerData?.data,"dataaaaa")
  // console.log(customerInfoFormik.values,"dataaaaaa9789")
  // console.log(customerInfoFormik.values.companyName,"dataaaaaa534")


  const navigate = useNavigate()

  const handleChangePhoneInput = (value) => {
    customerInfoFormik.setFieldValue('customerPhone', value)
  }

  // function formatPhoneNumber(phoneNumberString) {
  //   var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  //   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     var intlCode = (match[1] ? '+1 ' : '');
  //     return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  //   }
  //   return null;
  // }

  // const formatPhoneNumber = (input) => {
  //   if(!input || isNaN(input)) return `input must be a number was sent ${input}`
  //   if(typeof(input) !== 'string') input = input.toString()
  //   if(input.length >= 1){
  //     // return input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  //     return input.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');

  //   }else{
  //     return 'something went wrong'
  //   }
  // }


  // formatPhoneNumber(customerInfoFormik.values.phone, "::::::::")  // => "+1 (234) 567-8900"

  const handleChange2 = (e) => {
    e.preventDefault();
    customerInfoFormik.setFieldValue(e.target.name, e.target.value);
  }

  // useEffect(() => {
  //   if (customerData) {
  //     customerInfoFormik.setValues({
  //       fullName: `${customerData?.data?.firstName} ${customerData?.data?.lastName}`,
  //       email: customerData?.data?.email,
  //       phone: customerData?.data?.phone,
  //       company: customerData?.data?.company,
  //       corporationName: customerData?.data?.corporationName,
  //       otherInfos: customerData?.data?.otherInfos,
  //     })
  //   }
  // }, [customerData])

  const onHandleSubmit = () => {
    if (
      // customerInfoFormik.errors === "" 
      // || 
      (customerInfoFormik.values.customerFullName !== "" && customerInfoFormik?.values?.customerEmail !== "" && customerInfoFormik?.values?.customerPhone !== "")
    ) {
      setRequestStep(6)
    }
  }

  //   console.log((customerInfoFormik.errors === "" ) ,"ggggggggggggggggttttttt")
  //   console.log((customerInfoFormik?.values?.customerFullName !== "" && customerInfoFormik?.values?.customerEmail !== "" && customerInfoFormik?.values?.customerPhone !== ""),"gggggggggggggggghhhhhhhhhhhhh")

  // console.log(customerInfoFormik?.values,"customer-----valuessssss")
  // console.log(customerInfoFormik?.errors,"customer-----valuesssssserrors")


  return (
    <div>


      {/* <div className="dark-color d-flex align-items-center justify-content-between mb-3">
      <div style={{width:" 60px"}} className=" text-align-center py-2 ms-3">
        <img src="./component/images/Mask Group 2259.png" alt="#" style={{width:" 100%" }}/>
      </div>
      <div className=" d-flex justify-content-end align-items-center">
        
        
          <i className="bi bi-list text-white h4 pe-3"></i>
       
      </div>
    </div> */}

      {/* <Navbar /> */}
      <Header />

      <div className='main-content-container'>
        <form onSubmit={customerInfoFormik.handleSubmit}>

          <div className="container mb-3 mt-3">
            <div className='mb-2' style={{ marginTop: 80 }}>
              <h4 className="fw-bold ">Customer information</h4>
            </div>
            <div className="mt-3 ">
              {/* <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                <label className="form-check-label p-15" for="flexCheckChecked">
                  I’m a business user Do you want to register
                  your account? <Link to="#">Register</Link>
                </label>
              </div> */}
              <div className="mb-3 mt-2">
                <input
                  type="text"
                  className="form-control"
                  // className={Boolean(customerInfoFormik.values.customerFullName) ? "form-control border-danger p-3" : "form-control p-3"}
                  id="exampleFormControlInput1"
                  placeholder="First and last name"
                  name='customerFullName'
                  value={customerInfoFormik.values.customerFullName}
                  //  disabled
                  onChange={(e) => handleChange2(e)}
                />
                {Boolean(customerInfoFormik.touched.customerFullName && customerInfoFormik.errors.customerFullName) && <p className='text-danger mt-2'>{customerInfoFormik.errors.customerFullName}</p>}
              </div>
              <div className="mb-3 position-relative">
                <i className='bi bi-envelope-fill icon-mail1 text-muted border-start-0'></i>
                <input
                  style={{ paddingRight: "32px", paddingLeft: "40px" }}
                  type="email"
                  className="form-control"
                  // className={Boolean(customerInfoFormik.values.customerEmail) ? "form-control border-danger p-3" : "form-control p-3"}
                  id="exampleFormControlInput1"
                  placeholder="Email (for receipt)"
                  name='customerEmail'
                  value={customerInfoFormik.values.customerEmail}
                  onChange={(e) => handleChange2(e)}
                //  disabled
                />
                {Boolean(customerInfoFormik.touched.customerEmail && customerInfoFormik.errors.customerEmail) && <p className='text-danger mt-2'>{customerInfoFormik.errors.customerEmail}</p>}
              </div>
              <div className="mb-3">

                {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Phone (213)-123-4567"
                  name='phone'
                  value={formatPhoneNumber(customerData?.data?.phone)}
                /> */}
                {/* <PhoneInput
                    className="form-control p-3 cu-45 e-caret-hide"
                    defaultCountry="US"  
                    countries={["US"]}
                    name="phone"
                    type="text"
                    placeholder="Enter phone number"
                    //  disabled
                    value={dummy}
                    //  value="2626929526525"
                    onChange={handleChangePhoneInput}
                    //  PhoneInputCountrySelectArrow={false}
                    //  international = {false}
                    
                  /> */}

                <Input
                  //  className={Boolean(customerInfoFormik.touched?.customerPhone && customerInfoFormik.errors?.customerPhone) ? "form-control border-danger p-3 cu-45 e-caret-hide" : "cu-45 form-control p-3 e-caret-hide"}
                  style={{}}
                  className="form-control ps-2"
                  defaultCountry="US"
                  countries={["US"]}
                  name="customerPhone"
                  placeholder="Phone number"
                  value={customerInfoFormik.values.customerPhone}
                  onChange={handleChangePhoneInput}
                  PhoneInputCountrySelectArrow={false}
                  international={false}
                />
                {Boolean(customerInfoFormik.touched.customerPhone && customerInfoFormik.errors.customerPhone) && <p className='text-danger mt-2'>{customerInfoFormik.errors.customerPhone}</p>}
              </div>
              {/* {
                console.log(Boolean(
                  // customerInfoFormik.values.companyName !== "" ||
                customerData?.data?.company !== "-" ), "ttttt...000")
                }
              {
                console.log(Boolean(
                  // customerInfoFormik.values.companyName !== "" ||
                customerData?.data?.company !== "" ), "ttttt...111")
                }
               { console.log(Boolean(
                  // customerInfoFormik.values.companyName !== "" ||
               
                customerData?.data?.company !== null ), "ttttt...222")
                }
               { console.log(Boolean(
                  // customerInfoFormik.values.companyName !== "" || 
                customerData?.data?.isBusiness === true  ), "ttttt...33333")
              } */}
              {/* {
                           // console.log( profileData?.data?.company,"dataaaa", profileData?.data?.isBusiness)
                           Boolean(
                            // customerInfoFormik.values.companyName !== "" ||
                            // customerData?.data?.company !== "" ||  
                            // customerData?.data?.company !== null || 
                            customerData?.data?.isBusiness === true  )
                           ?
                           (
                            <div className="mb-3">
                               {/* <label htmlFor="exampleInputPassword1" className="form-label fw-semibold"
                               >Company Name</label> */}
              {/* <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Company (if commercial)"
                                    name='company'
                                  //  value={customerData?.data?.company}
                                  value={customerInfoFormik?.values?.company}
                                    onChange={(e) => handleChange2(e)}
                                />
                              </div> */}
              {/* {Boolean(profileFormik.touched.company && profileFormik.errors.company) && <p className='text-danger mt-2'>{profileFormik.errors.company}</p>} */}
              {/* </div>
                         ) 
                           :
                          ""
                        } */}
              {/* <div className="mb-3">
                              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Company (if commercial)"
                                name='companyName'
                                value={customerInfoFormik.values.'companyName'}
                              />
                            </div> */}
              {/* <div className="mb-3">
                    
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Receiving corporation name"
                    name='corporationName'
                    value={customerInfoFormik.values.corporationName}
                    />
                  </div> */}
              <div className="mb-3">

                <textarea
                  className="form-control"
                  placeholder="Other information"
                  style={{ height: "130px" }}
                  name='customerOtherInfos'
                  value={customerInfoFormik.values.customerOtherInfos}
                  onChange={(e) => handleChange2(e)}
                ></textarea>
                {/* {Boolean(customerInfoFormik.touched.customerOtherInfos && customerInfoFormik.errors.customerOtherInfos) && <p className='text-danger mt-2'>{customerInfoFormik.errors.customerOtherInfos}</p>} */}
              </div>
            </div>
            {/* {console.log(customerInfoFormik.errors,"gggggggggg")} */}
            <div className="container">
              <button type='submit' className="button btn-primary w-100 py-3"
                onClick={() =>
                  // navigate('/customer')
                  //   {
                  //     // if(customerInfoFormik.errors === ""){
                  //     if(customerInfoFormik.errors !== ""){
                  //     setRequestStep(6)
                  //   }
                  // }
                  onHandleSubmit()
                }
              >
                Continue
              </button>
              {/* <Link 
                className="button btn-primary w-100 py-3 text-decoration-none" 
                // to={`/customer/${createdGigData?.data?.createRequest?.orderId}`}
              >
                CONTINUE
              </Link> */}
              {/* <div className="my-2">

                <Link to="#" className="p-12">Do you want to register an account?</Link>
              </div> */}
            </div>

          </div>

        </form>
       
      </div>

      <div className="scrollHeight"></div>

    </div>
  )
}

export default CustomerInfromation
