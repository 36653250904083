import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getGigDetails } from '../../api';
import { getLastElementId } from '../../Utils/HelperFunction';
import ReactStars from "react-rating-stars-component";

const RateCourier = () => {

  const navigate = useNavigate();

  const [gigData, setGigData] = useState({data: null , loading: false})

  const location =  useLocation()

  // const orderId = getLastElementId()
  const orderId = useParams()

  const getGigsData = async () => {
    setGigData({loading : true});
    try {
      const res = await getGigDetails(orderId?.orderId);
      if(res.status === 200){
        setGigData({data : res?.data, loading : false});
      } 
    } catch (error) { 
      setGigData({loading:false});
    }
  } 

  useEffect(() => {
    getGigsData()
  }, [orderId])
 


  return (
    <>
          <div className="w-100 dark-color py-3 row gx-0 position-sticky top-0">
      <div className="text-white text-center col-10 mb-0 ps-3 h5">
        Rate Courier
      </div>

      {/* <div className="col-2 text-center">
        <Link to='/' className="text-white"> Skip</Link>
      </div> */}
    </div>
    <main className="container">
      <div className=" d-flex flex-column justify-content-center">
        <div className="mx-auto my-4 position-relative" style={{width: "60px"}}>
          <img
            src={gigData?.data?.profilePicture || "../component/images/image_2.jpeg"}
            alt=" "
            style={{width: "100%", objectFit: "contain"}}
            className="img-fluid rounded-circle"
          />
          {Boolean(Number(gigData?.data?.rating))&&<div
              className="bg-white text-center rounded-pill position-absolute"
              style={{bottom: "-5px",
                left: "5px",
                width: "50px",
                height: "20px",
                boxShadow: "2px 2px 2px lightgrey"}}
            >
              <span className='fw-bold'>{gigData?.data?.rating}</span> <i className="bi bi-star-fill star-icon"></i>
            </div>}
        </div>
        <div className=" border-bottom">
          <div className="border-bottom">
            <h5 className="fw-bold text-center mt-4">Your opinion matter!</h5>
            <h6 className=" mb-4 text-center lh-base">
              101 Drivers, Inc. request you to share your experience of your
              vehicle delivery order <br />
              <b>{gigData?.data?.orderId}</b>.
            </h6>
            <h6 className="m-0 mb-2 dark-clr text-center fw-bold">
              Amount, paid: ${gigData?.data?.priceOfRequest || "-"}
            </h6>
          </div>
         
          <div className="px-2 text-center h1 mb-3 mt-3 d-flex justify-content-center" style={{pointerEvents: "none",
  cursor: "not-allowed"}}>
            {/* <i className="bi bi-star-fill px-1 star-icon"></i>
            <i className="bi bi-star-fill px-1 star-icon"></i>
            <i className="bi bi-star-fill px-1 star-icon"></i>
            <i className="bi bi-star-fill px-1 star-icon"></i>
            <i className="bi bi-star-fill px-1 star-icon"></i> */}
            <ReactStars
              count={5} 
              value={location?.state ? Number(location?.state) : ""}
              size={55}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
              />
          </div>
          <h6 className="p-14 fw-bold text-center mb-4">
            Rate your experience with 101drivers.com
          </h6>
          {/* <h6 className="p-15 text-center px-1 ">
            If you don't want to receive requests for <br/> reviews from 101 Drivers,Inc., <a href="#" className="fw-semibold">Unsubscribe here</a>
          </h6> */}
        </div>
        <div style={{height: "250px"}}>
            <h5 className="fw-bold text-center  px-2  my-5">Please add tip for your driver.</h5>
           <div className="mx-auto my-4" style={{width: "190px"}}>
            <img
              src="../component/images/Mask Group 2311.png"
              alt=" "
              style={{width: "100%", objectFit: "contain"}}
              className="img-fluid "
            />
          </div>
        </div>
        <div className="text-center my-3 bottom-0">
          <button type="submit" className="button text-white w-100 py-3 fw-semibold" onClick={() => navigate('/thank-you')}>
            Continue
          </button>
        </div>
      </div>
    </main>
    </>
  )
}

export default RateCourier