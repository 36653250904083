import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getGigDetails } from "../../api";
import {UserContext} from "../../app_context/UserContext";
import NewMap from "../../Components/NewMap/NewMap";
import { formatPhoneNumber1 } from "../../Utils/HelperFunction";
import { Loader } from "../../Utils/Loader";
import Footer from "../../Components/Footer/Footer";
import NewMapTwo from "../../Components/NewMap/NewMapTwo";

const TrackOrderMap = () => {
  const [gigDetails, setGigDetails] = useState({ data: "", loading: false });
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const params = useParams();

  const app_context = useContext(UserContext);
  const { clearReview, setClearReview } = app_context;

  const fetchGigDetailsById = async (id) => {
    setGigDetails({ loading: true });
    try {
      const res = await getGigDetails(id);
      if (res?.status === 200) {
        setGigDetails({ data: res?.data, loading: false });
      }
    } catch (error) {
      setGigDetails({ loading: false });
    }
  };

  const handleCancel = () => {
    setClearReview(true);
    setTimeout(() => {
      navigate("/cancel-policy");
    }, 10);
  };


  useEffect(() => {
    fetchGigDetailsById(params?.gigId);
  }, [params?.gigId]);


  const navigate = useNavigate();

  return (
    <>

      <div className=" footer-app-container ">
        {/* <div className="dark-color d-flex align-items-center justify-content-between">
          <div
            style={{ width: "60px" }}
            className=" text-align-center py-2 ms-3"
          >
            <img
              src="../component/images/Mask Group 2259.png"
              alt="#"
              style={{ width: "100%" }}
            />
          </div>
          <div className=" d-flex justify-content-end align-items-center">
            <div>
              {/* <!-- <i className="bi bi-list text-white h4 pe-3"></i> --> */}
              {/* <img
                src="../component/images/Group 3842.svg"
                className="pe-3"
                alt=""
              /> */}
            {/* </div>
          </div>
        </div>  */}
        <div className="dark-color container header d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/edit/${params?.gigId}`);
            }}
          >
            <i className="bi bi-chevron-left h3 text-white ms-2"></i>
          </div>
          <div
            style={{ width: "60px" }}
            className=" text-align-center py-2 ms-3"
          >
            <img
              src="../component/images/Mask Group 2259.png"
              alt="#"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className=" d-flex justify-content-end align-items-center"></div>
      </div>
        <main className="mb-4 main-content-container ">


          {gigDetails && gigDetails?.loading ? (
            <div className="text-center mt-8 pt-4 ">
              <Loader />
            </div>
          ) : (
            <>
              <div className="container mb-3">
                <div className=" pt-2 ">
                  <div
                    className="card  mb-3"
                    style={{ border: "1px solid #E9E9E9", marginTop: 80 }}
                  >
                    {/* <div
                    className="light-color p-11 text-white rounded-pill text-center py-1 px-3 customerActive "
                    style={{ left: "40%" }}
                  >
                    <img
                      src="../component/images/Icon feather-edit-3.svg "
                      alt=""
                    />
                    Edit
                  </div> */}
                    <div className="text-center py-1 px-3 border-top pt-3">Track Your Order</div>
                    {/* <div className="border-top"></div> */}
                    {/* <div className="card-body px-0 pb-0"> */}
                    <div className="text-center track-order-map" >
                      {/* <img src="../component/images/customerActive.png" className="img-fluid light-color" alt="" style={{ height: "100%", width: "100%", objectFit: "fill" }} /> */}
                      {/* <NewMap
                        from={gigDetails?.data?.pickupAddress}
                        to={gigDetails?.data?.dropOffAddress}
                        directionsResponse={directionsResponse}
                        setDirectionsResponse={setDirectionsResponse}
                      /> */}
                      <NewMapTwo
                        from={gigDetails?.data?.pickupAddress}
                        to={gigDetails?.data?.dropOffAddress}
                        directionsResponse={directionsResponse}
                        setDirectionsResponse={setDirectionsResponse}
                        orderId={params?.gigId}
                      />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>

              {/* <!-- <div style="height: 40px;"></div> --> */}
              {/* <div className="container  mt-3">
              <button className="button  btn-primary w-100 py-3">
                TRACK YOUR ORDER
              </button>
            </div> */}
            </>
          )}
        </main>
        <Footer />
      </div>

      {/* <div className="mb-5 pb-5"></div> */}
      {/* <!-- Footer --> */}


      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-3 position-relative">
            <div className="logout-icon h1">
              <i className="bi bi-box-arrow-left fs-3 fw-bold "></i>
            </div>

            <div className="text-center mt-4">
              <h1 className="modal-title fs-5 fw-bold" id="exampleModalLabel">
                Sign Out?
              </h1>
            </div>
            <div className="modal-body py-3 ps-0 text-center fw-semibold">
              Are you sure you want to Sign out?
            </div>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="rounded-2 text-white  py-3 px-5 border-0 light-color"
                data-bs-dismiss="modal"
              >
                SIGN OUT
              </button>
              <button
                type="button"
                className="rounded-2  text-fade py-3 px-5 border-0 ms-1"
                data-bs-dismiss="modal"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackOrderMap;
