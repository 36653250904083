export const API_BASE_URL = `https://stage-api.101drivers.com`;
// export const API_BASE_URL = `http://192.168.1.157:3001`

// export const WEB_URL =  `http://192.168.1.189:3000`
export const WEB_URL =  `https://stage-dealer.101drivers.com`
// export const WEB_URL =  `http://localhost:3000`
// export const WEB_URL =  `https://dealer.101drivers.com/`

// export const API_BASE_URL = `https://api.101drivers.com`;

// export const API_BASE_URL = `http://localhost:3001`;

// export const API_BASE_URL = ` http://192.168.1.163:3001`;

export const CarUrl = "https://car-data.p.rapidapi.com";
export const DriverUrl = `https://stage-driver.101drivers.com`;

// given by vinay sir
export const GoogleMapApiKey = "AIzaSyA_L3FkfovIhLufnkPMQrHZkAVjKDKuhCU";

// given by kanak sir
// export const GoogleMapApiKey = "AIzaSyCJ4HYY3az2y8ek1oNy0eG4OTmr3RtPBh0"
