import {
  useJsApiLoader,
  GoogleMap,
  DirectionsRenderer,
  MarkerF,
  Marker,
} from '@react-google-maps/api'
import { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMapApiKey } from '../../Utils/BaseUrl'
import CarImg from "../../Assets/component/images/hatchback.png"
import { getLantLong } from '../../api'
// import { getMapCords } from '../../api'

const center = { lat: 36.7783, lng: 119.4179 }


//Working for the navigation part...
let map, infoWindow;

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation
      ? "Error: The Geolocation service failed."
      : "Error: Your browser doesn't support geolocation."
  );
  infoWindow.open(map);
}


function animateMarkerTo(marker, newPosition) { 
  var options = {
    duration: 1000,
    easing: function (x, t, b, c, d) {
      // jquery animation: swing (easeOutQuad)
      return -c * (t /= d) * (t - 2) + b;
    }
  };

  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;
  window.cancelAnimationFrame =
    window.cancelAnimationFrame || window.mozCancelAnimationFrame;

  // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
  marker.AT_startPosition_lat = marker.getPosition().lat();
  marker.AT_startPosition_lng = marker.getPosition().lng();
  var newPosition_lat = newPosition.lat();
  var newPosition_lng = newPosition.lng();

  // crossing the 180° meridian and going the long way around the earth?
  if (Math.abs(newPosition_lng - marker.AT_startPosition_lng) > 180) {
    if (newPosition_lng > marker.AT_startPosition_lng) {
      newPosition_lng -= 360;
    } else {
      newPosition_lng += 360;
    }
  }

  var animateStep = function (marker, startTime) {
    var ellapsedTime = new Date().getTime() - startTime;
    var durationRatio = ellapsedTime / options.duration; // 0 - 1
    var easingDurationRatio = options.easing(
      durationRatio,
      ellapsedTime,
      0,
      1,
      options.duration
    );

    if (durationRatio < 1) {
      marker.setPosition({
        lat:
          marker.AT_startPosition_lat +
          (newPosition_lat - marker.AT_startPosition_lat) * easingDurationRatio,
        lng:
          marker.AT_startPosition_lng +
          (newPosition_lng - marker.AT_startPosition_lng) * easingDurationRatio
      });

      // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
      if (window.requestAnimationFrame) {
        marker.AT_animationHandler = window.requestAnimationFrame(function () {
          animateStep(marker, startTime);
        });
      } else {
        marker.AT_animationHandler = setTimeout(function () {
          animateStep(marker, startTime);
        }, 17);
      }
    } else {
      marker.setPosition(newPosition);
    }
  };

  // stop possibly running animation
  if (window.cancelAnimationFrame) {
    window.cancelAnimationFrame(marker.AT_animationHandler);
  } else {
    clearTimeout(marker.AT_animationHandler);
  }

  animateStep(marker, new Date().getTime());
}

// window.initMap = initMap;




function NewMapTwo({ from, to, directionsResponse, setDirectionsResponse, orderId }) {
  // console.log(directionsResponse,"from, to, directionsResponse, setDirectionsResponse")

  const [ driverCords, setDriverCords ] = useState({ dLat : "", dLng : "" })
 
  const [navLoc,setNavloc] = useState({ lat: 37.315836192733485, lng: -121.83538689960598 })
  
  // console.log(place,"direction response in mapppppppppppppppppppppp")

  const { isLoaded } = useJsApiLoader({
    //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    // googleMapsApiKey: "AIzaSyCJ4HYY3az2y8ek1oNy0eG4OTmr3RtPBh0",
    googleMapsApiKey: GoogleMapApiKey,
    libraries: ['places'],
    id: "google-map-script"
  })

  // Working under the previous function...

  // console.log(place, "fromtooooooooooo")

  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  // const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [result, setResult] = useState('')

  const [duration, setDuration] = useState('')

  const mapRef = useRef()
  const markerRef = useRef(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    mapRef.current = map;
  }, []);

  const onClick = useCallback((event) => { 
    console.log(event,'yyy')
    animateMarkerTo(markerRef.current.marker, event.latLng);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    mapRef.current = null;
  }, []);



  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef()

  // console.log(directionsResponse, "<><><><><>")

  const calculateRoute = async () => {
    // console.log('calculate funtion')

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: from,
      destination: to,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    // console.log(results,"result of map in new mapp");

    setDirectionsResponse(results)
  }

// const getMapCordtinates = async (id) => {
//   try {
//     const res = await getMapCords(id)
//     setDriverCords((v) => ({ ...v, dLat : res?.data?.fromLatitude, dLng : res?.data?.fromLongitude }))
//     console.log(res,"response+Map")
//   } catch (error) {
//     console.log(error)
//   }
// }

  // useEffect(() => {
  //   getMapCordtinates(orderId);
  // }, [orderId])
  

  // useEffect(() => {
  //   calculateRoute()
  // }, [])

  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [isLoaded,from, to]);


  const latlong = async()=>{
    const {data} = await getLantLong(orderId)
    // console.log(data,"asdfasdf")
    // setNavloc({lat:parseFloat( 37.313620),lng : parseFloat(-121.866680)})
    // setNavloc({lat:parseFloat( data.currentLatitude),lng : parseFloat(data.currentLongitude)})
    animateMarkerTo(markerRef.current.marker, {lat:()=>{return data.currentLatitude}, lng:()=>{return data.currentLongitude}});

    
  }
  useEffect(()=>{
    const timer = setInterval(()=>{
    
    latlong()},30000)
    return () => clearInterval(timer);

      },[])


  if (!isLoaded) {
    return <div>Please wait ...</div>
  }
  


  function clearRoute() {
    // setDirectionsResponse(null)
    setDistance('')
    setDuration('')
    originRef.current.value = ''
    destiantionRef.current.value = ''
  }

  

  // console.log(directionsResponse, "JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ&&&&&&&&&&&&&&&&&&")

  return (

    <div style={{ height: "100%", zIndex: -1 }}>
      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          gestureHandling: 'none',
        }}
        // onLoad={map => setMap(map)}
        onLoad={onLoad}
        // onClick={(e) => onClick(e)}
        onUnmount={onUnmount}
      >
        {/* <MarkerF position={center} /> */}
        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}

        <Marker
          icon={{
            url: "https://driver101.s3.us-west-1.amazonaws.com/upload/files/ts-map-pin.png",
            scaledSize: new window.google.maps.Size(30, 30), 
          }}
          position={navLoc}
          ref={markerRef}
        />


      </GoogleMap>
    </div>
  )
}

export default NewMapTwo;