import { useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { isAuth } from "../Utils/HelperFunction"

export default function PrivateRoute({ children }) {


   const auth = isAuth()
   const navigate = useNavigate()

   

   // const isTokenPresent = () => {
   //    if(!auth){
   //       navigate("/login")
   //    }
   // }

   // useEffect(() => {
   //    isTokenPresent()
   // }, [auth])
   

   return auth ? children : <Navigate to='/new-quote' />

}