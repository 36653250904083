import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

const BusinessStep1 = ({ children, businessFormik1, checkLength, setCheckLength}) => {

   const [passwordType, setPasswordType] = useState("password");
   const [confirmPasswordType, setConfirmPasswordType] = useState("password");

   const handleChange = (e) => {
      e.preventDefault();
      businessFormik1.setFieldValue(e.target.name, e.target.value);
   }

   const handleChange2 = (e) => {
      e.preventDefault();
      businessFormik1.setFieldValue(e.target.name, e.target.value.replace(/[^\w\s]/gi, ""))
   }

   return (
      <>
       <h1 className='fw-bold mb-1 col-md-11'>Create account!</h1>
         <p className="text-muted mb-6 fw-semibold">
            Create your account and start benefit from the convenience our drivers provide to you.
         </p>

         <form onSubmit={businessFormik1.handleSubmit}>
            {checkLength && (
               <SweetAlert
                  title={"Use 8 or more characters with a mix of letters, numbers & symbols"}
                  style={{fontSize:"12px"}}
                  onConfirm={() => {
                     setCheckLength(false)
                     businessFormik1.setFieldError("password", null);
                  }}
                  onCancel={() => {
                     businessFormik1.setFieldError("password", null);
                  }}
               />
            )}
            <div className="mb-4">
               {/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Business name</label> */}
               <input
                  type="text"
                  // className="form-control"
                  className={Boolean(businessFormik1.touched?.businessName && businessFormik1.errors?.businessName) ? "form-control border-danger p-3" : "form-control p-3"}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Business name"
                  name='businessName'
                  value={businessFormik1.values.businessName}
                  onChange={(e) => handleChange2(e)}
               />
               {Boolean(businessFormik1.touched.businessName && businessFormik1.errors.businessName) && <p className='text-danger mt-2'>{businessFormik1.errors.businessName}</p>}
            </div>
            
            <div className="mb-4">
               {/* <label htmlFor="exampleInputEmail1" className="form-label  fw-semibold">Business email </label> */}
               <div className="position-relative">
                  <input
                     type="text"
                     // className="form-control border-end-0"
                     className={Boolean(businessFormik1.touched?.email && businessFormik1.errors?.email) ? "form-control border-danger p-3 pe-5" : "form-control p-3 pe-5"}
                     id="exampleInputEmail1"
                     aria-describedby="emailHelp"
                     placeholder="Business email "
                     name='email'
                     value={businessFormik1.values.email}
                     onChange={(e) => handleChange(e)}
                  />
                  <i className="bi bi-envelope-fill position-absolute icon-p  text-fade"></i>
               </div>
               {Boolean(businessFormik1.touched.email && businessFormik1.errors.email) && <p className='text-danger mt-2'>{businessFormik1.errors.email}</p>}
            </div>
            <div className="mb-4">
               {/* <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Password</label> */}
               <div className="position-relative">
                  <input
                     type={passwordType}
                     // className="form-control border-end-0"
                     className={Boolean(businessFormik1.touched?.password && businessFormik1.errors?.password) ? "form-control border-danger p-3" : "form-control p-3 pe-5"}
                     id="exampleInputPassword1"
                     placeholder="Password"
                     name='password'
                     value={businessFormik1.values.password}
                     onChange={(e) => handleChange(e)}
                  />
                  <i className={`bi ${passwordType === "password" ? "bi-eye-slash" : "bi-eye"} position-absolute icon-p text-muted `} style={{cursor:'pointer'}} onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></i>
               </div>
            </div>
            {/* <div className="text-end">
                  <p className="p-13"> Password should be of maximum 8 characters</p>
               </div> */}
            {Boolean(businessFormik1.touched.password && businessFormik1.errors.password) && <p className='text-danger mt-2'>{businessFormik1.errors.password}</p>}


            <div className="mb-4">
               {/* <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Confirm password</label> */}
              <div className='position-relative'>

              <input
                  type={confirmPasswordType}
                  // className="form-control"
                  className={Boolean(businessFormik1.touched?.confirmPassword && businessFormik1.errors?.confirmPassword) ? "form-control border-danger p-3" : "form-control p-3 pe-5"}
                  id="exampleInputPassword1"
                  placeholder="Password again "
                  name='confirmPassword'
                  value={businessFormik1.values.confirmPassword}
                  onChange={(e) => handleChange(e)}
               />
               <i className={`bi ${confirmPasswordType === "password" ? "bi-eye-slash" : "bi-eye"} position-absolute icon-p text-muted`} style={{ cursor: "pointer" }} onClick={() => setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password")}></i>
              </div>
              {/* <p className='m-0 p-12 fw-semibold opacity-50'  >Use 8 or more characters with a mix of letters, numbers & symbols</p> */}
            </div>
            {Boolean(businessFormik1.touched.confirmPassword && businessFormik1.errors.confirmPassword) && <p className='text-danger mt-2'>{businessFormik1.errors.confirmPassword}</p>}
            <div className="text-center my-4">
               {/* <button type="submit" className="button text-white w-100  py-3">
                  Next
               </button> */}
               {children}
            </div>
         </form>
      </>
   )
}

export default BusinessStep1