import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { getLocationInputApi, myProfileApi, updateProfile } from "../../../api";
import { Loader } from "../../../Utils/Loader";
import { profileValidation } from "../../../Validation/Validation";
import { assignBucketUrl, formatPhoneNumber2, handleFileUpload } from "../../../Utils/HelperFunction";
import Footer from "../../../Components/Footer/Footer";
import { components } from "react-select";
import { debounce } from "lodash";
import Input from "react-phone-number-input/input";
import ProfilePicDemo from "../../../Assets/component/images/image_2.jpeg";
import AsyncSelect from "react-select/async";

const MyProfile = () => {
	const inputRef = useRef();
	const imgRef = useRef();
	const [profileData, setProfileData] = useState();
	const profileFormik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			company: "",
			email: "",
			phone: "",
			address: "",
			city:"",
			profilePicture:""
		},
		validationSchema: profileValidation,
		onSubmit: async (values) => {
			// let payload = {
			// 	...values,
			// };
			// delete payload.email;
			try {
				if(!profileData?.data?.isBusiness) {
					delete values.company
				}
				const response = await updateProfile(values);
				if (response.status === 200) {
					toast.success(response.data.message, { id: "001" });
				}
			} catch (err) {
				toast.error(err?.response?.data?.message, { id: "etrr01" });
			}
		},
	});
	const handleLocationChange = async (search) => {
		try {
			const res = await getLocationInputApi(search);
			// const res = await getLocationByPlaceId(placeId);

			if (res.status === 200 && res?.data?.length) {
				const rd = res.data.map((e) => {
					return {
						label: e.name + " " + e.formatted_address,
						value: e.name + " " + e.formatted_address,
						lat: e.geometry.location.lat.toString(),
						lng: e.geometry.location.lng.toString(),
						cityName: Boolean(e?.plus_code?.compound_code) ? e?.plus_code?.compound_code?.split(",")[0].split(" ").slice(1).join(" ") : e?.name,
						place_id: e.place_id,
					};
				});
				return rd;
			}
			return null;
		} catch (error) {
			return null;
		}
	};


	const handleRemove = () => { 
		profileFormik.setFieldValue("profilePicture","")
	};

	const fetchLocationArray = useCallback(
		debounce((inputText, callback) => {
			handleLocationChange(inputText).then((options) => callback(options));
		}, 500),
		[]
	);
	const handleChangePhoneInput = (value) => {
		profileFormik.setFieldValue("phone", value);
	};
	const handlePickUpChange = (f) => { 
		profileFormik.setFieldError("city",f.cityName);
		profileFormik.setFieldValue("address", f.value); 
	};

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className='custom-css-class'>Search your address</span>
			</components.NoOptionsMessage>
		);
	};

	const handleChange = (e) => {
		profileFormik.handleChange(e);

		e.preventDefault(); 
		if (e.target.value.length >= 12) {
			e.preventDefault();
		} else {
			profileFormik.handleChange(e);
			profileFormik.setFieldValue(e.target.name, e.target.value);
		}
	};

   const handleChange2 = (e) => {
      e.preventDefault()
      profileFormik.setFieldValue(e.target.name, e.target.value.replace(/[^\w\s]/gi, ""))
   }

   const getProfile = async () => {
      setProfileData({ loading: true })
      try {
         const res = await myProfileApi();
         if (res.status === 200) {
            setProfileData({ data: res?.data?.data });
         }
      } catch (err) {
         setProfileData({ loading: false })
        
      }
   };

	useEffect(() => {
		getProfile();
	}, []);

	useEffect(() => {
		if (profileData) {
			profileFormik.setValues({
				firstName: profileData?.data?.firstName || "",
				lastName: profileData?.data?.lastName || "",
				company: profileData?.data?.company || "",
				email: profileData?.data?.email || "",
				phone: profileData?.data?.phone || "",
				address: profileData?.data?.address || "",
				city:profileData?.data?.city || "",
				profilePicture:profileData?.data?.profilePicture || ""
			});
		}
	}, [profileData]);

   return (
      <div className="footer-app-container">
         {/* <Header /> */}
         <div className="dark-color header container d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex align-items-center">
               <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                     // navigate("/");
                     window.history.back();
                  }}
               >
                  <i className="bi bi-chevron-left h3 text-white ms-2"></i>
               </div>
               <div
                  style={{ width: "60px" }}
                  className=" text-align-center py-2 ms-3"
               >
                  <img
                     src="./component/images/Mask Group 2259.png"
                     alt="#"
                     style={{ width: "100%" }}
                  />
               </div>
            </div>
            <div className=" d-flex justify-content-end align-items-center"></div>
         </div>
				 
         <div className="container px-3">
            <h3 className="fw-semibold mb-3" style={{marginTop: 80}}>My Profile</h3>
            {
               !profileData?.loading
                  ? (<>
                     <form onSubmit={profileFormik.handleSubmit}>
                        <div className="mb-4"> 
                           <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="First name"
                              name='firstName'
                              value={profileFormik.values.firstName}
                              onChange={(e) => handleChange2(e)}
                           />
                           {Boolean(profileFormik.touched.firstName && profileFormik.errors.firstName) && <p className='text-danger mt-2'>{profileFormik.errors.firstName}</p>}
                        </div>
                        <div className="mb-4"> 
                           <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Last name"
                              name='lastName'
                              value={profileFormik.values.lastName}
                              onChange={(e) => handleChange2(e)}
                           />
                           {Boolean(profileFormik.touched.lastName && profileFormik.errors.lastName) && <p className='text-danger mt-2'>{profileFormik.errors.lastName}</p>}
                        </div>
                        
                        {
                            
                          Boolean(profileData?.data?.isBusiness)
                           ?
                           (
                              <div className="mb-4">
                               
                                 <div className="input-group">
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="exampleInputPassword1"
                                       placeholder="Company Name"
                                       name='company'
                                       value={profileFormik.values.company}
                                       onChange={(e) => handleChange(e)}
                                    />
                                 </div>
                                    {Boolean(profileFormik.touched.company && profileFormik.errors.company) && <p className='text-danger mt-2'>{profileFormik.errors.company}</p>}
                              </div>
                           ) 
                           :
                          ""
                        }
                        
                        <div className="mb-4">
                           
								<div className='position-relative'>
								<i className='bi bi-envelope-fill icon-mail1 text-muted border-start-0'></i>
									<input
										style={{ paddingRight: "32px", paddingLeft: "40px" }}
										type='text'
										className='form-control border-end-0'
										id='exampleInputEmail1'
										aria-describedby='emailHelp'
										placeholder='Enter your email'
										name='email'
										value={profileFormik.values.email}
										onChange={(e) => handleChange(e)}
									/>
								
								</div>
							</div>

							<div className='mb-4'>
							 
                         

								<div>
									<Input
										className={
											Boolean(profileFormik.touched?.phone && profileFormik.errors?.phone)
												? "form-control border-danger cu-45 e-caret-hide"
												: "cu-45 form-control e-caret-hide"
										}
										defaultCountry='US'
										countries={["US"]}
										name='phone'
										placeholder={"Phone number"}
										value={profileFormik.values.phone}
										onChange={handleChangePhoneInput}
										PhoneInputCountrySelectArrow={false}
										international={false}
									/>
									{Boolean(profileFormik.touched.phone && profileFormik.errors.phone) && <p className='text-danger mt-2'>{profileFormik.errors.phone}</p>}
								</div>
							</div>
							<AsyncSelect
								components={{ NoOptionsMessage }} 
								placeholder='From where?' 
								cacheOptions
								loadOptions={fetchLocationArray}
								onChange={(f) => {
									handlePickUpChange(f);
								}}
								defaultOptions
								name='address'
								className={"form-control p-0"}
								value={{ label: profileFormik.values.address, value: profileFormik.values.address }}
								onFocus={() => { 
								}}
							/>

<div>
						{!profileData?.data?.isBusiness&&<div>
							<h6 className=' fw-semibold mt-3'>Profile picture</h6>

							<div className='d-flex justify-content-between align-items-center mb-3 '>
								<div style={{ height: "50px", width: "50px", position: "relative" }} className=' '>
								{Boolean(profileFormik?.values?.profilePicture)&&<span className='image-section  position-absolute end-0'>
										<i className='bi bi-x-circle   ' style={{ cursor: "pointer" }} onClick={handleRemove}></i>
									</span>}
								
									<img
										ref={inputRef}
										
										src={assignBucketUrl(profileFormik?.values?.profilePicture)}
										alt='#'
										style={{ width: "100%", height: "100%", objectFit: "fill" }}
										className='border rounded-3 '
										onError={(event) => {
											event.target.src = "https://driver101.s3.us-west-1.amazonaws.com/upload/files/Portrait_Placeholder.png";
											event.onerror = null;
										}}
									/>
								</div>

								<label htmlFor='profilePic'>
									<div
										onClick={() => {
											imgRef?.current?.click();
											
										}}
										style={{ cursor: "pointer" }}
										className='btn1 py-1 h-100 rounded-2'>
										Upload
									</div>
								</label>
								<input
									type='file'
									id='profilePic'
									name='profilePicture'
									onChange={(e) => {
										handleFileUpload(e, profileFormik);
										 
									}}
									className='d-none'
									accept='image/*'
								/>
							</div>
							{Boolean(profileFormik.touched.profilePicture && profileFormik.errors.profilePicture) && (
								<p className='text-danger mt-2'>{profileFormik.errors.profilePicture}</p>
							)}
						</div> }
					</div>
							<div className='text-center my-4'>
								<button type='submit' className='button text-white w-100 py-3'>
									Save
								</button>
							</div>
						</form>
					</>
				) : (
					""
				)}
				{profileData?.loading ? (
					<div className='text-center mt-8'>
						<Loader />
					</div>
				) : (
					""
				)}
			</div>
			<Footer />
		</div>
	);
};

export default MyProfile