import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getAllGig, getGigDetails } from "../../api";
import { Loader } from "../../Utils/Loader";
import GigFilter from "../../Utils/AllGigFilter.json";
import {UserContext} from "../../app_context/UserContext";
import Sidebar from "../../Sidebar";
import { logoutOnJwtExpire } from "../../Utils/HelperFunction";
import Footer from "../../Components/Footer/Footer";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import SelectStyle from "../../Utils/CustomSelectStyle";
import { debounce } from "lodash";

const AllGigs = () => {
  const app_context = useContext(UserContext);
  const { clearReview, setClearReview } = app_context;
  const [sreachedGig, setSearchGig] = useState()
  const [gigList, setGigList] = useState({ data: "", loading: true });
  const [filterValue, setFilterValue] = useState("Active");
  const [gigSearch, setGigSearch] = useState({ label:"search gig by id.."})

  // const [isOpen, setIsopen] = useState(false);

  // const ToggleSidebar = () => {
  //     isOpen === true ? setIsopen(false) : setIsopen(true);
  // }

  const handleCreateNewGig = () => {
    setClearReview(true);
    setTimeout(() => {
      navigate("/new-quote");
    }, 10);
  };

  // const handleToggle = () => {
  //   if(isOpen === true){
  //     setIsopen(false);
  //     <Sidebar />
  //   }else{
  //     setIsopen(true);
  //   }
  // }
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Data Not Found</span>
      </components.NoOptionsMessage>
    );
  };
  const handleSearchGig =  async (search) => {
    setGigSearch({ label: search })
    if(search) {
      try {
        const res = await getGigDetails(search);
        if (res.status === 200) {
            return res?.data?.orderId ? [{
            label: res.data?.orderId,
            value: res.data,
          }] : null
        }
        return null
      } catch (error) {
        return null;
      }
    }
  };
  const gigDetailbySearching = useCallback(
    debounce((inputText, callback) => {
      handleSearchGig(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const navigate = useNavigate();
  const fetchAllGig = async () => {
    setGigList({ loading: true });
    try {
      const res = await getAllGig(filterValue);
      if (res.status === 200) {
        setGigList({ data: res?.data, loading: false });
      }
    } catch (err) {
      setGigList({ loading: false }); 
    }
  };

  useEffect(() => {
    fetchAllGig();
  }, [filterValue]);

  return (
    <>
      {/* {
      isOpen ? 
      (
        <Sidebar />
      )
      :
      ""
    } */}
    <div className="footer-app-container">
      <div className="dark-color container header d-flex align-items-center justify-content-between">
        <div style={{ width: "60px" }} className="text-align-center py-2 ms-3">
          <img
            src="./component/images/Mask Group 2259.png"
            alt="#"
            style={{ width: "100%" }}
          />
        </div>
        {/* <div className="d-flex justify-content-end align-items-center" style={{ cursor:"pointer"}} onClick= */}
        {/* // {() => 
        // setIsopen(true)
        // handleToggle()
        // }
        // {ToggleSidebar}
        // {() => navigate(`/sidebar`)}
        // >
        //   <div>
        //     {/* <!-- <i className="bi bi-list text-white h4 pe-3"></i> --> */}
        {/* //     <img src="./component/images/Group 3842.svg" className="pe-3" alt="" />
        //   </div>
        // </div> */}
        <div className=" d-flex justify-content-end align-items-center">
          <div

            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
            style={{cursor: "pointer"}}
          >
            {/* <!-- <i className="bi bi-list text-white h4 pe-3"></i> --> */}
            <img
              src="./component/images/Group 3842.svg"
              className="pe-3"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <!-- Sidebar Start --> */}
      <div
        className="offcanvas offcanvas-start bg-navy-blue "
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header">
          <div
            className="offcanvas-title m-auto my-4"
            id="offcanvasWithBothOptionsLabel"
            style={{ height: "80px" }}
          >
            <img
              src="./component/images/Mask Group 2259.png"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
        <div className="offcanvas-body">
          <div className="w-100  mb-3">
            <button
              className="logout border button w-100  bg-transparent text-white fw-semibold text-start"
              data-bs-dismiss="offcanvas"
              onClick={() => navigate("/sidebar")}
            >
              <div className="d-flex justify-content-between">
              <div>
              <i className="bi bi-car-front me-2"></i>
              <span>Dealer's gig details</span>
              </div>
              <i className="bi bi-chevron-right"></i>
              </div>
            </button>
          </div>
          <div className="w-100  mb-3">
            <button
              className="logout border button w-100  bg-transparent text-white fw-semibold text-start"
              data-bs-dismiss="offcanvas"
              onClick={() => navigate("/chat")}
            >
              <div className="d-flex justify-content-between">
              <div>
              <i className="bi bi-chat me-2"></i>
              <span>Help</span>
              </div>
              <i className="bi bi-chevron-right"></i>
              </div>
            </button>
          </div>
        </div>
        <div className="offcanvas-header">
          <div className="w-100  mb-3">
            <button
              className="logout border button w-100  bg-transparent text-white fw-semibold"
              data-bs-toggle="offcanvas"
              data-bs-target="#logoutModal"
              style={{ cursorPointer: "pointer" }}
              onClick={() => logoutOnJwtExpire()}
            >
              <i className="bi bi-box-arrow-left me-2 h5"></i>
              Log out
            </button>
          </div>
        </div>
      </div>
      <main className="container main-content-container" >
      <div style={{marginTop: 80}}></div>
        <div className="row mt-3" >
          <div className="col-6">
            <div className="dropdown mb-3">
              <button
                className="form-control px-1 d-flex justify-content-between align-items-center dropdown-toggle py-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div>
                  {filterValue === "Active" && (
                    <img
                      src="./component/images/Group 3625.png"
                      alt=""
                      className="me-2"
                      style={{ width: 20 }}
                    />
                  )}
                  {filterValue === "Booked" && (
                    <img
                      src="./component/images/Group 3646.png"
                      alt=""
                      className="me-2"
                      style={{ width: 15 }}
                    />
                  )}
                  {filterValue === "Listed" && (
                    <img
                      src="./component/images/Group 3647.png"
                      alt=""
                      className="me-2"
                      style={{ width: 15 }}
                    />
                  )}
                  {filterValue === "Completed" && (
                    <img
                      src="./component/images/Group 3645.png"
                      alt=""
                      className="me-2"
                      style={{ width: 15 }}
                    />
                  )}
                  {filterValue === "Cancelled" && (
                    <img
                      src="./component/images/Group 3648.png"
                      alt=""
                      className="me-2"
                      style={{ width: 15 }}
                    />
                  )}
                   {filterValue === "Expired" && (
                    <img
                      src="./component/images/Group 3648.png"
                      alt=""
                      className="me-2"
                      style={{ width: 15 }}
                    />
                  )}
                  <span className="fw-bold p-11">
                    {" "}
                    {filterValue === "Completed" ? "Delivered" : filterValue}
                  </span>
                </div>
              </button>
              <ul className="dropdown-menu w-100">
                <li
                  onClick={() => {
                    setFilterValue("Booked");
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <div>
                      <img
                        src="./component/images/Group 3646.png"
                        alt=""
                        className="me-2"
                        style={{ width: 15 }}
                      />
                      <span className="fw-bold p-11"> Booked</span>
                    </div>
                  </a>
                </li>
                <li
                  onClick={() => {
                    setFilterValue("Listed");
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <div>
                      <img
                        src="./component/images/Group 3647.png"
                        alt=""
                        className="me-2"
                        style={{ width: 15 }}
                      />
                      <span className="fw-bold p-11"> Listed</span>
                    </div>
                  </a>
                </li>
                <li
                  onClick={() => {
                    setFilterValue("Completed");
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <div>
                      <img
                        src="./component/images/Group 3645.png"
                        alt=""
                        className="me-2"
                        style={{ width: 15 }}
                      />
                      <span className="fw-bold p-11"> Delivered</span>
                    </div>
                  </a>
                </li>
                <li
                  onClick={() => {
                    setFilterValue("Cancelled");
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <div>
                      <img
                        src="./component/images/Group 3648.png"
                        alt=""
                        className="me-2"
                        style={{ width: 15 }}
                      />
                      <span className="fw-bold p-11"> Cancelled Orders</span>
                    </div>
                  </a>
                </li>

                <li
                  onClick={() => {
                    setFilterValue("Expired");
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <div>
                      <img
                        src="./component/images/Group 3648.png"
                        alt=""
                        className="me-2"
                        style={{ width: 15 }}
                      />
                      <span className="fw-bold p-11"> Expired Orders</span>
                    </div>
                  </a>
                </li>

                <li
                  onClick={() => {
                    setFilterValue("Active");
                  }}
                >
                  <a className="dropdown-item opacity-25" href="#">
                    <div>
                      <img
                        src="./component/images/Group 3625.png"
                        alt=""
                        className="me-2"
                        style={{ width: 20 }}
                      />
                      <span className="fw-bold p-11"> Active is Default</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            {/* <Select 
              options={GigFilter}
            /> */}
          </div>
          <div className="col-6">
            {/* <!-- <div className="form-control py-2">
           <p className="m-0 p-12"><i className="bi bi-plus-circle"></i> Create new Post</p>
          </div> --> */}
            <button className="form-control px-1 py-2" type="button">
              <div
                className="d-flex align-items-center justify-content-center"
                onClick={() => handleCreateNewGig()}
              >
                <span>
                  <i className="bi bi-plus-circle p-13"></i>
                </span>
                <span className="fw-semibold p-10 ms-2">
                  Create&nbsp;new&nbsp;order
                </span>
              </div>
            </button>
          </div>
            <div>
            {/* <AsyncSelect
              components={{ NoOptionsMessage }}
              // defaultInputValue="Select your location"
              placeholder='Sreach gig by id.. '
              // styles={SelectStyle}
              cacheOptions
                loadOptions={gigDetailbySearching}
              onChange={(f) => {
              }}
              defaultOptions
              name='pickupAddress'
              className="form-control p-0"
              // value={
                
              // }
              // onFocus={() => {
              //   calculateCostFormik.setFieldValue("pickupAddress", "");
              //   // setDestinationShowCity({ from: "" })
              // }}
            /> */}

              <AsyncSelect
                components={{ NoOptionsMessage }}
                // defaultInputValue="Select your location"
                placeholder='search gig by id..'
                // styles={SelectStyle}
                cacheOptions
                loadOptions={gigDetailbySearching}
                onChange={(f) => { 
                  return f?.value?.orderId ? navigate(`/edit/${f?.value?.orderId}`) : null
                }}
                value={gigSearch?.label||""}
                defaultOptions
                name='pickupAddress'
                onFocus={()=>{
                  setGigSearch({ label: "search gig by id.." })
                }}
              />
          </div>
        </div>

        <div className="">
          <p className="m-0 p-18 fw-bold mt-3 mb-1">Your orders:</p>
          {gigList?.loading ? (
            <div className="text-center mt-4 mb-4">
              <Loader />
            </div>
          ) : (
            gigList?.data &&
            gigList?.data?.map((el, i) => {
              return (
                <React.Fragment key={`gig-list${i}`}>
                  <div
                    className="row gx-0 d-flex justify-content-around align-items-center mb-3"
                    style={{
                      boxShadow: "0px 0px 2px lightgrey",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/edit/${el?.orderId}`)}
                  >
                    <div className="col-12 rounded px-3 py-2">
                      <div className="text-end">
                        <p className="fw-bold mb-1 p-12">{`${el?.orderId}`}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="text-dark mb-1 p-12">
                          {el?.status === "Active" ? (
                            <span className="text_success fw-semibold">
                              Active -
                            </span>
                          ) : (
                            ""
                          )}
                          {/* Thurs. FEB. 20 */}
                          {el?.pickUpDateAndTime
                            ? moment(el?.pickUpDateAndTime).format(
                                "ddd. MMM. D"
                              )
                            : "-"}
                        </p>
                        <p className="text-dark mb-1 p-12">
                          {el?.pickUpDateAndTime
                            ? moment(el?.pickUpDateAndTime).format("hh:mm A")
                            : "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="text-fade mb-1 p-12">
                          {el?.pickupCity || "-"}
                        </p>
                        <p className="text-fade mb-1 p-12">
                          {el?.dropOffCity || "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="fw-semibold text-muted mb-1 p-11 text-start">
                          {el?.pickupAddress || "-"}
                        </p>
                        <p className="text-muted fw-semibold mb-1 p-11 text-end">
                          {el?.dropOffAddress || "-"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="text-dark mb-0">
                          {/* <span className="light-yellow p-13 rounded-5 px-2 p-12 text-muted me-1">
                            Pro 1
                          </span> */}
                          <span className="light-yellow p-13 rounded-5 px-2 p-12 text-muted">
                            {el?.distance || "-"} mi
                          </span>
                        </p>
                        <p className="text-dark mb-0">
                          {/* <span className="text-muted fw-semibold h6 me-2 p-12 text-decoration-line-through">
                            {" "}
                            {"$" +
                              (
                                parseInt(el?.priceOfRequest)?.toFixed(2) / 0.8
                              ).toFixed(2)}
                          </span> */}
                          <span className="danger-text fw-semibold h6 p-12">
                            {" "}
                            {"$" + el?.priceOfRequest || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
        
                </React.Fragment>
              );
            })
          )}

          {gigList?.data?.length === 0 && !gigList?.loading ? (
            <div className="text-center">No data found</div>
          ) : (
            ""
          )}
        </div>

        {/* <div className="head-image container px-0 mb-4">
          <img
            src="./component/images/active-order-map.png"
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
              
            }}
          />
        </div> */}
        
      </main>
      
      <Footer />

      </div>
    </>
  );
};

export default AllGigs;
