import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { postCancelGig, putCancelGig } from '../../api';
import {UserContext} from '../../app_context/UserContext';
import { Loader } from '../../Utils/Loader';

const CancellationPolicy = () => {

  const [cancel, setCancel] = useState({data: null , loading: false})

  const navigate = useNavigate()

  const params = useParams()

  const handleCancel = async () => {
    setCancel({loading : true});
    try {
      const res = await putCancelGig({ orderId : params?.gigId});
      if(res.status === 200){
        setCancel({data : res?.data, loading : false});
      } 
    } catch (error) { 
      setCancel({loading:false});
    }
  }

  const handleBtn = () => {
    handleCancel();
    navigate("/new-quote")
  }

  return (
    <>
         <main className="mb-4">
    <div className="dark-color d-flex align-items-center justify-content-between">
      <div style={{width: "60px"}} className=" text-align-center py-2 ms-3">
        <img src="../component/images/Mask Group 2259.png" alt="#" style={{width: "100%"}} />
      </div>
      <div className=" d-flex justify-content-end align-items-center">
        <div>
          {/* <!-- <i className="bi bi-list text-white h4 pe-3"></i> --> */}
          <img src="./component/images/Group 3842.svg" className="pe-3" alt=""/>
        </div>
      </div>
    </div>
   {
    cancel && cancel?.loading
    ? 
    ( <div className='text-center mt-8 pt-4'><Loader/></div>)
    :
     <div>
    <div className="container mb-3">
      <div className="my-3">
        <h5 className="fw-bolder p-16">Cancellation & Refund Policy</h5>

      </div>
      <div className="mt-3 ">
        <h6 className="m-0 p-14 mb-3 ">Per our 24-hour booking policy, if your delivery is changed prior to 24 hours of the original scheduled pickup time, you may change or cancel without any charge. </h6>
        <h6 className="m-0 p-14 mb-3 ">Your booked vehicle delivery order is not eligible for a refund within 24 hours of scheduled pickup time. </h6>
        <h6 className="m-0 p-14 mb-3 ">If you must cancel or change your order within 24 hours and it has already been booked by a driver, a full charge may apply.</h6>
        <h6 className="m-0 p-14 mb-3 "> Any refund will be credited back to your original form of payment.</h6>
       
         
      </div>
      {/* <div className="text-center" style={{height: "400px"}}>
        <img src="./component/images/customerActive.png" height="100%" width="100%" alt=""/>
      </div> */}
    </div>
     <div className=" " style={{height: "400px"}} ></div>
    <div className="container ">
      <button className="button btn-primary w-100 py-3" onClick={() => handleBtn()}>
       Cancel
      </button>
    </div>
    </div>
   }
  </main>
    </>
  )
}

export default CancellationPolicy