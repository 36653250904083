import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import TimePicker from 'react-times';
import "react-times/css/material/default.css";
import "react-times/css/classic/default.css";
import moment from "moment";
import Navbar from "./Navbar";
import Header from "../../../Components/Header/Header";

const DatePickerFinal = ({ setQuotation, setRequestStep, vehicleInfoFormik }) => {
	const handleDateChange = (sD) => {
		const selectedDate = new Date(sD);

		vehicleInfoFormik?.setFieldValue("pickUpDateAndTime", selectedDate);
	};

	const handleDateChange2 = (sD) => {
		const selectedDate = new Date(sD);

		vehicleInfoFormik?.setFieldValue("dropOffTime", selectedDate);
	};

	const checkIng = (x, y) => {
		const cmp = moment(y).isAfter(x);
		return cmp;
	};

	const startDate = vehicleInfoFormik.values?.pickUpDateAndTime || "";
	const dropOffTime = vehicleInfoFormik.values?.dropOffTime || "";
	return (
		<div>
			{/* <Navbar /> */}
			<Header/>
			<div className='mb-4 main-content-container'>
				<div className='container mb-3 mt-3' >
					<div style={{marginTop: 80}}>
						<h5 className='fw-bold p-16'>Vehicle delivery date</h5>
					</div>

					<div className=' text-center'>
						<img
							src='../component/images/dateImage.png'
							alt=''
							className='img-fluid '
							style={{
								objectFit: "fill",
							}}
						/>
					</div>
					<div className='my-3'>
						<div>
							{/* <p className="fw-semibold">
                {moment(startDate).format("MMM DD. YYYY")}
              </p> */}
							<p className='p-16 fw-bold'>Vehicle delivery time slot</p>
						</div>
					</div>
					<div>
						<div className='d-sm-flex d-block align-items-center justify-content-between mb-2'>
							<p className='m-0'>Ready for Pickup</p>
							{console.log(vehicleInfoFormik,'d')}
							<div className='d-flex flex-column'>
								<DatePicker
									showTimeSelect
									timeFormat='hh:mm a'
									timeIntervals={15}
									onChange={handleDateChange}
									timeCaption='Time'
									// use12Hours
									selected={vehicleInfoFormik.values.pickUpDateAndTime}
									// selected={vehicleInfoFormik.values.pickUpDateAndTime === "" ? new Date(moment("MMMM d, yyyy h:mm aa").format()) : vehicleInfoFormik.values.pickUpDateAndTime}
									dateFormat='MMMM d, yyyy h:mm aa'
									minDate={new Date()}
									useWeekdaysShort={true}
									wrapperClassName='datePicker'
									locale='en-US'
								/>
							{vehicleInfoFormik?.touched?.pickUpDateAndTime&&<p className="text-danger">{vehicleInfoFormik?.errors?.pickUpDateAndTime}</p>}
							</div>

						</div>


						<div className='d-sm-flex d-block align-items-center justify-content-between'>
							<p className='m-0'>Latest drop-off</p>
							<div className='d-flex flex-column'>
								<DatePicker
									showTimeSelect
									timeFormat='hh:mm a'
									timeIntervals={15}
									timeCaption='time'
									dateFormat='MMMM d, yyyy h:mm aa'
									// minDate={new Date()}
									selected={vehicleInfoFormik.values.dropOffTime}
									onChange={handleDateChange2}
									minDate={moment().toDate()}
								/>
							{vehicleInfoFormik?.touched?.dropOffTime&&<p className="text-danger">{vehicleInfoFormik?.errors?.dropOffTime}</p>}

							</div>
						</div>
					</div>
				</div>
				{startDate && dropOffTime && (checkIng(startDate, dropOffTime) === false) ? (
					<>
						<p className='text-danger ps-2'>Please select valid drop-off time.</p>
					</>
				) : (
					""
				)}

				<div className='container pb-3'>
					{checkIng(startDate, dropOffTime) === false ? (
						<>
							<button className='button btn-grey w-100 py-3' style={{opacity:0.5}} disabled={true}>Done</button>
						</>
					) : (
						<>
							<button
								className='button btn-primary w-100 py-3'
								onClick={() => {
									setRequestStep(4);
								}}>
								Done
							</button>
						</>
					)}
					<div className="scrollHeight"></div>
				</div>
			</div>

			{/* <!-- Modal Interval--> */}
			<div className='modal fade' id='Interval' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
				<div className='modal-dialog modal-dialog-centered'>
					<div className='modal-content mx-4'>
						<div className='modal-header justify-content-center'>
							<h2 className='text-center'>Time Interval</h2>
						</div>
						<div className='modal-body row text-center'>
							<div className='col-12 time'></div>
						</div>
						<div className='modal-footer d-block'>
							<div className='row'>
								<div className='col-6'>
									<button type='button' className='btn border py-2 text-fade fw-semibold w-100' data-bs-dismiss='modal'>
										Cancel
									</button>
								</div>
								<div className='col-6'>
									<button
										// onClick={() => {handleInterval()}}
										type='button'
										className=' button btn-primary w-100  btn py-2 w-100  light-color text-white px-3'
										data-bs-dismiss='modal'>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DatePickerFinal;
