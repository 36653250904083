import Footer from "../Components/Footer/Footer";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllGig } from "../api";
import { Loader } from "../Utils/Loader";
import moment from "moment";
import ProfilePicDemo from "../Assets/component/images/person.png"
import { API_BASE_URL } from "../Utils/BaseUrl";
import { assignBucketUrl, formatPhoneNumber1 } from "../Utils/HelperFunction";
import { debounce } from "lodash";

const Sidebar = () => {
  const navigate = useNavigate();
  const [gigList, setGigList] = useState({ data: "", loading: false });
  const [driverModalDetails, setDriverModalDetails] = useState({
    profilePicture: "",
    phone: "",
    name: "",
    rating: "",
    email: ""
  })
  // const [ filterValue, setFilterValue ] = useState("listed")

  const inputRef = useRef()

  const fetchAllGig = async (searchQuery) => {
    setGigList({ loading: true });
    try {
      const res = await getAllGig("listed", searchQuery);
      if (res.status === 200) {
        setGigList({ data: res?.data, loading: false });
      }
    } catch (err) {
      setGigList({ loading: false });
       
    }
  };

  const capitalize = (e) => {
    let ex = e.split(" ")
    for (let i = 0; i < ex.length; i++) {
      ex[i] = ex[i].charAt(0).toUpperCase() + ex[i].slice(1)
    }
    return ex.join(" ")
  }

  const handleSearchGig = async (e) => {
      fetchAllGig(e.target.value)
  };

  const debounceHanlder = debounce(handleSearchGig, 500)

  useEffect(() => {
    fetchAllGig();
  }, []);


  return (
    <div className="footer-app-container">
      <div className="dark-color d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <i className="bi bi-chevron-left h3 text-white ms-2"></i>
          </div>
          <div
            style={{ width: "60px" }}
            className=" text-align-center py-2 ms-3"
          >
            <img
              src="./component/images/Mask Group 2259.png"
              alt="#"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className=" d-flex justify-content-end align-items-center"></div>
      </div>

      <main className="main-content-container">
        <div className='container px-3 mb-4 pt-3 mt-2'>
            <input type="search" className="form-control" placeholder="search by source" onChange={debounceHanlder} />
        </div>
        <div className="container py-2">
          <h5 className="fw-bold">Your orders:</h5>
        </div>
        {gigList?.loading ? (
          <div className="text-center mt-4 mb-4">
            <Loader />
          </div>
        ) : (
          gigList?.data?.length > 0 ?
          gigList?.data.map((el, i) => {
            return (
              <React.Fragment key={`gig-list${i}`}>
                <div className="container my-2">
                  <div>
                    <div
                      className="card mb-4 border-0"
                      style={{
                        boxShadow: "0px 0px 2px lightgrey",
                        cursor: "pointer",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#card1"
                      onClick={() => {
                        setDriverModalDetails({
                          profilePicture: el?.driverInfo?.profilePicture,
                          phone: el?.driverInfo?.phone,
                          name: el?.driverInfo?.name,
                          rating: el?.rating,
                          email: el?.driverInfo?.email,
                        })
                      }}
                    >
                      <div className="p-2 py-3 d-flex justify-content-between align-items-center border-bottom position-relative">
                        <div className="d-flex align-items-center">
                          <div
                            className="rounded-circle"
                            style={{ height: 40, width: 40 }}
                          >
                            {/* <img
                              src="./component/images/image_2.jpeg"
                              // src="./component/images/icon-person.svg"
                              alt=""
                              className="rounded-circle"
                              style={{ width: "100%", height: "100%" }}
                            /> */}
                            {
                              driverModalDetails?.profilePicture
                                ?
                                  (
                                    <img
                                      ref={inputRef}
                                      // src={`${API_BASE_URL}/${driverModalDetails?.profilePicture}`} 
                                      src={assignBucketUrl(driverModalDetails?.profilePicture)}
                                      className=" rounded-circle border-profile-pic " style={{ height: "100%", width: "100%" }} alt=""
                                      onError={event => {
                                        event.target.src = ProfilePicDemo
                                        event.onerror = null
                                      }} />
                                  )
                                  :
                                  (
                                    <img src="../component/images/person.png" className=" rounded-circle " style={{ height: "100%", width: "100%" }} alt="" />
                                  )
                              }
                          </div>
                          <div className="ms-2">
                            <p className="mb-0 fw-semibold text-dark p-12">

                              {/* {el?.driverInfo?.name.charAt(0).toUpperCase() + el?.driverInfo?.name.slice(1)} */}
                              {
                                capitalize(el?.driverInfo?.name || "User name")
                              }
                            </p>
                            <p className="mb-0 text-dark fw-semibold p-9">
                              <i className="bi bi-star-fill star p-9 star-icon" />{" "}
                              <span className="text-fade">
                                {el?.rating || "-"}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex ">
                          <div className="text-center ">
                            <p className="mb-0 text-fade p-10">Final&nbsp;cost</p>
                            <p className="mb-0 text-dark fw-semibold p-10">
                              {Boolean(el?.priceOfRequest)
                                ? "$" + '\u00A0' + el?.priceOfRequest
                                : "-"}
                            </p>
                          </div>

                          {/* <div className="text-center">
                            <p className="mb-0 text-fade p-10">Time</p>
                            <p className="mb-0 text-dark fw-semibold p-10">
                              12:00 AM
                            </p>
                          </div> */}
                        </div>

                        <div className="your-order-completed p-9 rounded-pill">
                          {el?.status.charAt(0).toUpperCase() + el?.status.slice(1)}
                        </div>
                      </div>
                      <div className="row p-2 py-3  justify-content-around" style={{ height: 215 }}>
                        <div
                          className="col-1 d-flex flex-column align-items-center justify-content-between "
                          style={{ height: 120 }}
                        >
                          <i className="bi  bi-record-circle-fill dark-clr" />
                          <div
                            className="  "
                            style={{
                              width: 1,
                              height: 85,
                              border: "1px dashed #E6E6E6",
                            }}
                          />
                          <i className="bi bi-record-circle-fill  text-fade opacity-50" />
                        </div>

                        <div className="col-7 d-flex flex-column  justify-content-between">
                          <div>
                            <p className="mb-0 text-fade p-10">Pickup Point</p>
                            <p className="text-black fw-semibold p-12 mb-2" style={{ height: 75, overflow: "hidden", overflowY: "auto", fontSize: " 12px" }}>
                              {el?.pickupAddress || "-"}
                            </p>
                          </div>
                          <div className="">
                            <p className="mb-0 text-fade p-10">Drop off Point</p>
                            <p className="text-black fw-semibold p-12 m-0" style={{ height: 75, overflow: "hidden", overflowY: "auto", fontSize: " 12px" }}>
                              {el?.dropOffAddress || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-2 d-flex flex-column justify-content-between ps-0" style={{ height: 150 }}>
                          <p className="text-fade p-10 text-start">
                            {" "}
                            {el?.pickUpDateAndTime
                              ? moment(el?.pickUpDateAndTime).format("hh:mm A")
                              : "-"}
                          </p>
                          <p
                            className="text-fade  p-10 text-start"

                          >
                            {el?.pickUpDateAndTime
                              ? moment(el?.dropOffTime).format("hh:mm A")
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div style={{ height: "100px" }}></div> */}



                  {/* MODAL */}

                  {/* <!-- Model --> */}
                  <div
                    className="modal fade"
                    id="logoutModal"
                    tabindex="-1"
                    aria-labelledby="logoutModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content p-3 position-relative">
                        <div className="logout-icon h1">
                          <i className="bi bi-box-arrow-left fs-3 fw-bold "></i>
                        </div>

                        <div className="text-center mt-4">
                          <h1
                            className="modal-title fs-5 fw-bold"
                            id="logoutModalLabel"
                          >
                            Sign Out?
                          </h1>
                        </div>
                        <div className="modal-body py-3 ps-0 text-center fw-semibold">
                          Are you sure you want to Sign out?
                        </div>
                        <div className="d-flex justify-content-between">
                          <button
                            type="button"
                            className="rounded-2 text-white  py-3 px-5 border-0 light-color"
                            data-bs-dismiss="modal"
                          >
                            SIGN OUT
                          </button>
                          <button
                            type="button"
                            className="rounded-2  text-fade py-3 px-5 border-0 ms-1"
                            data-bs-dismiss="modal"
                          >
                            CANCEL
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })
          : <h3 style={{textAlign:"center"}}>Data Not Found</h3>
        )}

      </main>


      {/* MODAL */}

      <div
        className="modal fade"
        id="card1"
        tabIndex={-1}
        aria-labelledby="card1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="pt-2 d-flex justify-content-between">
              <div></div>
              <h6 className=" m-0 fw-bold " >
                Your driver details
              </h6>
              <button
                type="button"
                className="pe-3 text-fade border-0 "
                style={{backgroundColor: 'transparent'}}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="rounded-circle m-auto mb-3" style={{ height: 100, width: 100 }}>
                  {/* <img
                      src="./component/images/image_2.jpeg"
                      alt=""
                      className="rounded-circle"
                      style={{ width: "100%", height: "100%" }}
                    /> */}
                              {
                                driverModalDetails?.profilePicture 
                                ?
                                  (
                                    <img
                                      ref={inputRef}
                                      // src={`${API_BASE_URL}/${driverModalDetails?.profilePicture}`}
                                      src={assignBucketUrl(driverModalDetails?.profilePicture)}
                                       className=" rounded-circle border-profile-pic " style={{ height: "100%", width: "100%" }} alt=""
                                      onError={event => {
                                        event.target.src = ProfilePicDemo
                                        event.onerror = null
                                      }} />
                                  )
                                  :
                                  (
                                    <img src="../component/images/person.png" className=" rounded-circle " style={{ height: "100%", width: "100%" }} alt="" />
                                  )
                              }
                            </div>
                            <p className="m-0 fw-bold p-16">{capitalize(driverModalDetails?.name || "-")}</p>
                            <div
                              className="bg-white m-auto rounded-pill "
                              style={{ width: 50, height: 20, boxShadow: "2px 2px 2px lightgrey" }}
                            >
                              <p className="mb-0 text-dark fw-semibold p-12">
                                <i className="bi bi-star-fill star p-12 star-icon" />{" "}
                                <span className="">{driverModalDetails?.rating || "-"}</span>
                              </p>
                            </div>

              </div>

              <div
                className="container call-email-modal d-flex justify-content-between mt-3 py-3 px-3 rounded-4"
                style={{ boxShadow: "0px 0px 2px lightgrey" }}
              >
                <div className="d-flex ">
                  <div className="me-2">
                    <i className="bi bi-telephone-fill"></i>
                  </div>
                  <div className="lh-sm">
                    <p className="call-email-modal-label mb-0 text-black fw-semibold p-12">
                      Call
                    </p>
                    <a href={`tel:${driverModalDetails?.phone}`} className="text-fade  p-12 m-0 text-decoration-none dark-clr">
                      +1{formatPhoneNumber1(driverModalDetails)}
                    </a>
                  </div>
                </div>
                <div className="border-end "></div>
                <div className="d-flex">
                  <div className="me-2">
                    <i className="bi bi-envelope-fill "></i>
                  </div>
                  <div className="lh-sm">
                    <p className="call-email-modal-label mb-0 text-black fw-semibold p-12">
                      Email
                    </p>
                    <Link onClick={() => window.location = `mailto:${driverModalDetails?.email}`} className="text-fade  p-12 m-0 text-decoration-none">
                      {driverModalDetails?.email || "-"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Sidebar;
