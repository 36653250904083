import React from 'react'

const Insurance = ({ setRequestStep }) => {
  return (
    <>
      <main className="mb-4">
        <div className="dark-color d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRequestStep(4)
              }}
            >
              <i className="bi bi-chevron-left h3 text-white ms-2"></i>
            </div>
            <div
              style={{ width: "60px" }}
              className=" text-align-center py-2 ms-3"
            >
              <img
                src="../component/images/Mask Group 2259.png"
                alt="#"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div className=" d-flex justify-content-end align-items-center"></div>
        </div>
        {/* <div className="dark-color d-flex align-items-center justify-content-between fixed-top">
                <div className="d-flex align-items-center">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            // navigate("/");
                        }}
                    >
                        <i className="bi bi-chevron-left h3 text-white ms-2"></i>
                    </div>
                    <div
                        style={{ width: "60px" }}
                        className=" text-align-center py-2 ms-3"
                    >
                        <img
                            src="./component/images/Mask Group 2259.png"
                            alt="#"
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>

                <div className=" d-flex justify-content-end align-items-center"></div>
            </div> */}

        <div className="container mb-3">
          <div className="my-3">
            <h5 className="fw-bolder p-16">Inspection & Insurance</h5>

          </div>
          <div className="mt-3 ">
            <div className='row mb-2'>
              <div className='col-1 pe-0 me-2'>
                <i className='bi bi-caret-right-fill'></i>
              </div>
              <div className='col-10 ps-0'> <h6 className="m-0 p-14 mb-3 ">A staff member and the driver walk around your car and look inside, checking for damage. </h6></div>
            </div>
            <div className='row mb-2'>
              <div className='col-1 pe-0 me-2'>
                <i className='bi bi-caret-right-fill'></i>
              </div>
              <div className='col-10 ps-0'>  <h6 className="m-0 p-14 mb-3 ">If any damage, they enter it on their paperwork before keys are handed over. </h6></div>
            </div>
            <div className='row mb-2'>
              <div className='col-1 pe-0 me-2'>
                <i className='bi bi-caret-right-fill'></i>
              </div>
              <div className='col-10 ps-0'>  <h6 className="m-0 p-14 mb-3 ">Our driver takes 6 pictures of your car to record its condition. At the delivery location the driver takes another six pictures of the car. </h6></div>
            </div>
            <div className='row mb-2'>
              <div className='col-1 pe-0 me-2'>
                <i className='bi bi-caret-right-fill'></i>
              </div>
              <div className='col-10 ps-0'>  <h6 className="m-0 p-14 mb-3 ">The tank must be at least half full for any delivery. A vehicle must be insured.</h6></div>
            </div>

           
           
           


          </div>
          {/* <div className="text-center" style={{height: "400px"}}>
        <img src="./component/images/customerActive.png" height="100%" width="100%" alt=""/>
      </div> */}
        </div>

      </main>
    </>
  )
}

export default Insurance