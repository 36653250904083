import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getGigDetails, postStarRating } from '../../api'
import { assignBucketUrl, getLastElementId } from '../../Utils/HelperFunction'
import ReactStars from "react-rating-stars-component";
import { API_BASE_URL } from '../../Utils/BaseUrl';
import ProfilePicDemo from "../../Assets/component/images/person.png"
// /Assets/component/images/person.png"

const YourOpinion = () => {
  
  const [gigData, setGigData] = useState({data: null , loading: false})

  // const orderId = getLastElementId()
  const orderId = useParams()

  const navigate = useNavigate()

  const inputRef = useRef()

  const ratingChanged = async (newRating, orderId) => {
    try {
      if(newRating !== ""){
        const res = await postStarRating({
          "rating" : newRating.toString(),
          "orderId": orderId?.orderId });
        if(res.status === 200){
          navigate(`/rate-courier/${orderId?.orderId}`, { state : newRating})
        }
      }
    } catch (error) {
      return error
    }
     };

  const getGigsData = async () => {
    setGigData({loading : true});
    try {
      const res = await getGigDetails(orderId?.orderId);
      if(res.status === 200){
        setGigData({data : res?.data, loading : false});
      } 
    } catch (error) { 
      setGigData({loading:false});
    }
  } 

  useEffect(() => {
    getGigsData()
  }, [orderId])


  return (
    <>
        <div className="w-100 dark-color py-3 row gx-0 position-sticky top-0">
      {/* <div className="text-white text-center col-10 ps-5 mb-0 ps-3 h5">
        Rate Courier
      </div> */}

      <div className="col-10 text-end">
        <a href="#" onClick={() => {window.history.back()}} className="text-white text-decoration-none"> Skip</a>
      </div>
    </div>
    <div
      className="rating-dialog d-flex flex-column justify-content-center align-items-center"
    >
      <div className="card border-0" style={{width: "22rem"}}>
        <div className="card-body position-relative">
          <div
            className="mx-auto position-absolute"
            style={{width: "60px", left: "40%", top: "-30px"}}
          >
            {/* <img
              src="../component/images/image_2.jpeg"
              alt=" "
              style={{width: "100%", objectFit: "contain"}}
              className="img-fluid rounded-circle"
            /> */}

                    {
                      gigData?.data?.profilePicture ? 
                      (
                        <img
                        ref={inputRef}
                        //  src={`${API_BASE_URL}/${gigData?.data?.profilePicture}`} 
                        src={assignBucketUrl(gigData?.data?.profilePicture)}
                          className=" rounded-circle " 
                          style={{ height: "100%", width: "100%" }} alt="" 
                         onError={event => {
                          event.target.src = ProfilePicDemo
                          event.onerror = null
                       }}/>
                      ) 
                      :
                       (
                        // <img src="../component/images/image_4.jpeg" className=" rounded-circle " style={{ height: "100%", width: "100%" }} alt="" />
                        <img src={ProfilePicDemo} className=" rounded-circle " style={{ height: "100%", width: "100%" }} alt="" />
                       )
                    }

            <div
              className="bg-white text-center rounded-pill position-absolute"
              style={{ bottom: "-5px",
                left: "5px",
                width: "50px",
                height: "20px",
                boxShadow: "2px 2px 2px lightgrey"}}
            >
              <span className='fw-bold'>5</span> <i className="bi bi-star-fill star-icon"></i>
            </div>
          </div> 
          <div className="">
            <div>
              <h5 className="fw-bold text-center mt-4">Your opinion matter!</h5>
              <h6 className="text-14 text-center lh-base">
                101 Drivers, Inc. request you to share your experience of your
                vehicle delivery order <br />
                {/* #D-123456. */}
                <span style={{fontWeight:"bolder"}}>{gigData?.data?.orderId}.</span>
              </h6>
              <p className="m-0 mb-2 dark-clr text-center fw-bold p-14">
                Amount, paid: ${gigData?.data?.priceOfRequest || "-"}
              </p>
            </div>
            <div className="border-top pt-2">
              <div className="px-2 text-center h1 d-flex justify-content-center">
                {/* <i className="bi bi-star px-1 star-icon"></i>
                <i className="bi bi-star px-1 star-icon"></i>
                <i className="bi bi-star px-1 star-icon"></i>
                <i className="bi bi-star px-1 star-icon"></i>
                <i className="bi bi-star px-1 star-icon"></i> */}
                <ReactStars
                  count={5}
                  onChange={(e)=> {
                    ratingChanged(e, orderId)
                  }}
                  size={55}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ffd700"
              />
              </div>
              
              <h6 className="p-12 m-0 text-center ">
                Rate your experience with 101drivers.com
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default YourOpinion