import { useFormik } from 'formik';
import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { resetPasswordApi } from '../../../../api';
import { forgotPasswordValidation } from '../../../../Validation/Validation';

const ResetForm = () => {

   const location = useLocation();

   const [passwordType, setPasswordType] = useState("password");
   const [confirmPasswordType, setConfirmPasswordType] = useState("password");
 
   const navigate = useNavigate();
 
   const params = useParams();
 
   const resetPasswordFormik = useFormik({
     initialValues: {
       password: "",
       confirmPassword: "",
     },
     validationSchema: forgotPasswordValidation,
     onSubmit: async (values) => {
       try {
         // const res = await resetPasswordApi({ ...values, code: location?.state.token || "" })
         const res = await resetPasswordApi({ password: values.password, code: location?.search.split("=")[1] })
         if (res.status === 200 || Response.status === 201) {
           toast.success(res?.data.message, { id: "001" });
           navigate("/my-thankyou");
         }
       } catch (err) { 
         toast.error(err?.response?.data?.message, { id: "etrr01" });
       }
     },
   });


   return ( 
      <>
         <div className="my-4  d-flex justify-content-center">
            <img src="./component/images/Forgot password-rafiki.png" alt="#" />
         </div>
         <div className="text-center">
            <h3 className="fw-bold  h1">Reset password?</h3>
            <p className="text-muted mb-4" style={{ fontSize: "14px" }} >
               Reset your password and create new password.
            </p>
         </div>

         <form onSubmit={resetPasswordFormik.handleSubmit}>
            <div className="mb-4">
               {/* <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Password</label> */}
               <div className="  position-relative">
                  <input
                     className=" form-control  w-100 pass-input-padding"
                     type={passwordType}
                     placeholder="Password "
                     name='password'
                     value={resetPasswordFormik.values.password}
                     onChange={resetPasswordFormik.handleChange}
                  />
      
                  <i className={`bi ${passwordType === "password" ? "bi-eye-slash" : "bi-eye"} position-absolute h4 icon-p text-fade`} style={{ cursor: "pointer" }} onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></i>
               </div>
               {Boolean(resetPasswordFormik.touched.password && resetPasswordFormik.errors.password) && <p className='text-danger mt-2'>{resetPasswordFormik.errors.password}</p>}
               {/* <div className="text-end ">
                  <p className="pswd"> Password should be of maximum 8 characters</p>
               </div> */}
            </div>

            <div className="mb-3">
               {/* <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Confirm password</label> */}
               <div className="  position-relative ">
                  <input
                     className=" form-control  w-100 pass-input-padding"
                     type={confirmPasswordType}
                     placeholder="Password again"
                     name='confirmPassword'
                     value={resetPasswordFormik.values.confirmPassword}
                     onChange={resetPasswordFormik.handleChange}
                  />

               <i className={`bi ${confirmPasswordType === "password" ? "bi-eye-slash" : "bi-eye"} position-absolute h4 icon-p text-fade`} style={{ cursor: "pointer" }} onClick={() => setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password")}></i>
               </div>
               {/* <p className='m-0 p-12 fw-semibold opacity-50'  >Use 8 or more characters with a mix of letters, numbers & symbols</p> */}
               {Boolean(resetPasswordFormik.touched.confirmPassword && resetPasswordFormik.errors.confirmPassword) && (
            <p className='text-danger mt-2'>{resetPasswordFormik.errors.confirmPassword}</p>
          )}
            </div>

            <div className="text-center">
               <button type="submit" className="button  w-100 text-white mb-3 p-16">
                  Reset password
               </button>
            </div>
         </form>
         <div className='text-center'>
            <h6 className='text-muted'>
               <Link to='/login' className='text-muted text-decoration-none'>
                  <i className='bi bi-arrow-left'></i> Back to log in
               </Link>
            </h6>
         </div>
      </>
   )
}

export default ResetForm