import React from 'react'
import HomeIcon from '../../Assets/component/images/icon-home.svg'
import CarIcon from '../../Assets/component/images/icon-car.svg'
import ClipboardIcon from '../../Assets/component/images/icon-clipboard.svg'
import PersonIcon from '../../Assets/component/images/icon-person.svg'
import { useNavigate } from 'react-router'

const Footer = () => {

  const navigate = useNavigate()
    return (
        <>
            <footer className='footer container px-0'>
                {/* Footer */}
                <div className="d-flex w-100 justify-content-around align-items-center border-top bg-white py-3  bottom-0">

                    <div style={{cursor: 'pointer'}} onClick={() => navigate('/')}>
                        <span>
                            {/* <i class="bi bi-car-front-fill h3"></i> */}
                            <img src={HomeIcon} alt="" />
                        </span>
                    </div>
                    <div className='opacity-25' style={{cursor: 'pointer'}} onClick='/'>
                        <span>
                            {/* <i class="bi bi-car-front-fill h3"></i> */}
                            <img src={CarIcon} alt="" />
                        </span>
                    </div>
                    <div className='opacity-25' style={{cursor: 'pointer'}} onClick='/'>
                        <span>
                            {/* <i class="bi bi-car-front-fill h3"></i> */}
                            <img src={ClipboardIcon} alt="" />
                        </span>
                    </div>
                    {/* <div>
 <span>
   <img src="./component/images/Path 1598.svg" alt="">
 </span>
     </div> */}
                    {/* <div className=" ">
      <div className="position-relative">
        <span
          className="rounded-5  icons px-2 py-1 position-absolute"
          style={{
            top: "-10px",
            boxShadow: "0px 1px 10px hsla(0, 0%, 0%, 0.05)"
          }}
        >
          <img src="./component/images/Path 1598.svg" alt="" />
        </span>
        <span className="ps-1">
          <p className="text-10 dark-clr mb-0 fw-bold d-flex flex-column align-items-center">
            Order
            <img src="./component/images/Line 74.svg" alt="" className="p-8" />
          </p>
        </span>
      </div>
    </div> */}
                    <div style={{cursor: 'pointer'}} onClick={() => navigate('/my-profile')}>
                        <span>
                            {/* <i class="bi bi-car-front-fill h3"></i> */}
                            <img src={PersonIcon} alt="" />
                        </span>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer