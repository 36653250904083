import { useFormik } from 'formik';
import React from 'react'
import { toast } from 'react-hot-toast';
import Input from 'react-phone-number-input/input';
import { Link } from 'react-router-dom'
import { postForgotEmail } from '../../../api';
import { forgetPhoneValidation } from '../../../Validation/Validation';

const ForgotEmail = () => {

    const handleChangePhoneInput = (value) => { 
        forgotEmailFormik.setFieldValue('phoneNumber', value)
        // if (e.target.value.length >= 12) {
        //    e.preventDefault()
        // } else {
        //    regFormik2.handleChange(e);
        //    regFormik2.setFieldValue(e.target.name, e.target.value);
        // }
     }

   const forgotEmailFormik = useFormik({
      initialValues: {
         phoneNumber: "",
      },
      validationSchema: forgetPhoneValidation,
      onSubmit: async (values) => {
        try {
          const response = await postForgotEmail(values);
          if (response.status === 200) {
            toast.success("Email sent to your phone number", { id: "001" });
          }
        } catch (err) { 
          toast.error(err.response.data.message, { id: "etrr01" });
        }
      },
    });

   return (
      <div className="container px-3">
         <div style={{ height: "100px", width: "100px" }} className="mt-2 ">
            <img
               src="./component/images/Group 3621.png"
               alt="#"
               style={{ width: "100%" }}
            />
         </div>

         <div className="my-5 text-center">
            <img src="./component/images/Forgot password-rafiki.png" alt="#" />
         </div>
         <div className="text-center">
            <h1 className="fw-bold ">Forgot email?</h1>
            <p className="text-muted mb-4 fs-">
               Enter the phone number associated with your account.
            </p>
         </div>

         <form onSubmit={forgotEmailFormik.handleSubmit}>
            
            <div className="mb-4">
               {/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Phone number</label> */}
               {/* <input
                  type="number"
                  className={Boolean(regFormik2.touched?.phone && regFormik2.errors?.phone) ? "form-control border-danger p-3" : "form-control p-3"}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="(XXX)-XXX-XXXX"
                  name='phone'
                  value={regFormik2.values.phone}
                  onChange={(e) => handleChange(e)}
               /> */}  
            <Input
               className={Boolean(forgotEmailFormik.touched?.phoneNumber && forgotEmailFormik.errors?.phoneNumber) ? "form-control border-danger cu-45 e-caret-hide" : "cu-45 form-control e-caret-hide"}
               defaultCountry="US"  
               countries={["US"]}
               name="phoneNumber"
               placeholder="Phone number"
               value={forgotEmailFormik.values.phoneNumber}
               onChange={handleChangePhoneInput}
               PhoneInputCountrySelectArrow={false}
               international = {false}
                  />
               {Boolean(forgotEmailFormik.touched.phoneNumber && forgotEmailFormik.errors.phoneNumber) && <p className='text-danger mt-2'>{forgotEmailFormik.errors.phoneNumber}</p>}
            </div>
            <div className="text-center ">
               <button type="submit" className="button text-white w-100 mb-3 p-16">
                  Next
               </button>
               <Link to='/login' className='text-decoration-none text-muted'>
                  <i className='bi bi-arrow-left me-2'></i>Back to log in
               </Link>
            </div>
         </form>
      </div>
   )
}

export default ForgotEmail;