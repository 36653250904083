import { ToastContainer } from 'react-toastify';
import MainRouter from './MainRouter/MainRouter';
import AppContext from './app_context/AppContextState';
import AppContextState from './app_context/AppContextState';
import "./App.css"
function App() {
  return (

    <div >
      <div className="App  container gx-0 ">
        <AppContextState>
          <MainRouter />
        </AppContextState>
      </div>
    </div>



  );
}

export default App;

