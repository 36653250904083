import { useFormik } from 'formik';
import React from 'react'
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom'
import { forgotPasswordApi } from '../../../api';
import { forgetEmailValidation } from '../../../Validation/Validation';

const ForgotPassword = () => {

   const forgotPasswordFormik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: forgetEmailValidation,
      onSubmit: async (values) => {
        try {
          const response = await forgotPasswordApi(values);
          if (response.status === 200) {
            //  
            toast.success(response?.data?.message, { id: "001" });
          }
        } catch (err) {
         //   
          toast.error(err.response.data.message, { id: "etrr01" });
        }
      },
    });

   return (
      <div className="container px-3">
         <div style={{ height: "100px", width: "100px" }} className="mt-2 ">
            <img
               src="./component/images/Group 3621.png"
               alt="#"
               style={{ width: "100%" }}
            />
         </div>

         <div className="my-5 text-center">
            <img src="./component/images/Forgot password-rafiki.png" alt="#" />
         </div>
         <div className="text-center">
            <h1 className="fw-bold ">Forgot password?</h1>
            <p className="text-muted mb-4 fs-">
               Enter the email address associated with your account.
            </p>
         </div>

         <form onSubmit={forgotPasswordFormik.handleSubmit}>
            <div className="mb-4">
               {/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Email</label> */}
               <div className="  position-relative">
                  <input
                     className=" form-control  w-100  pe-5 "
                     type="email"
                     placeholder="Email"
                     name='email'
                      value={forgotPasswordFormik.values.email}
                     onChange={forgotPasswordFormik.handleChange}
                  />

                  <i className="bi bi-envelope-fill position-absolute h4 icon-p text-fade"></i>
               </div>
               {Boolean(forgotPasswordFormik.touched.email && forgotPasswordFormik.errors.email) && <p className='text-danger mt-2'>{forgotPasswordFormik.errors.email}</p>}
            </div>
            <div className="text-center ">
               <button type="submit" className="button text-white w-100 mb-3 p-16">
                  Next
               </button>
               <Link to='/login' className='text-decoration-none text-muted'>
                  <i className='bi bi-arrow-left me-2'></i>Back to log in
               </Link>
            </div>
         </form>
      </div>
   )
}

export default ForgotPassword