// import {
//   Box, 
//   Flex, 
//   SkeletonText, 
// } from '@chakra-ui/react'
// import { FaLocationArrow, FaTimes } from 'react-icons/fa'

import {
  useJsApiLoader,
  GoogleMap, 
  DirectionsRenderer,
  MarkerF,
} from '@react-google-maps/api'

import { useEffect, useRef, useState } from 'react'
import { GoogleMapApiKey } from '../../Utils/BaseUrl'

const center = { lat: 36.7783, lng: 119.4179 }

function Map({place, directionsResponse, setDirectionsResponse }) {


  const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    // googleMapsApiKey: "AIzaSyCJ4HYY3az2y8ek1oNy0eG4OTmr3RtPBh0",
    googleMapsApiKey: GoogleMapApiKey,
    libraries: ['places'],
    id: "google-map-script",
  })
  

  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  // const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [result, setResult] = useState('')

  const [duration, setDuration] = useState('')

  const mapRef = useRef()

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef()

 
  const calculateRoute = async () => {
    
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: place?.from,
      destination: place?.to,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    
    setDirectionsResponse(results)
  }

  // useEffect(() =>{
  //   calculateRoute()
  // }, [])

  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [isLoaded,place]);

  if (!isLoaded) {
    return <div>Please wait ...</div>
  }  

  
  function clearRoute() {
    // setDirectionsResponse(null)
    setDistance('')
    setDuration('')
    originRef.current.value = ''
    destiantionRef.current.value = ''
  }


  return (
    
    <div style={{height:"100%", zIndex:-1}}>
      <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            gestureHandling: 'none',
          }}
          onLoad={map => setMap(map)}
        >
          {/* <MarkerF position={center} /> */}
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
    </div>
  )
}

export default Map;
