import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { loginApi } from '../../../api';
import { LoginValidation } from '../../../Validation/Validation';
import { ImgLoader2 } from '../../../Utils/Loader';
import { UserContext } from '../../../app_context/UserContext';
import { getToken } from '../../../Utils/HelperFunction';


function Login() {

   const [passwordType, setPasswordType] = useState("password");
   const [keepLoggIn, setKeepLoggIn] = useState(null);
   const [loader, setLoader] = useState(false)

   const app_context = useContext(UserContext)
   const { setLoggedIn, loggedIn } = app_context

   // const UserApi = useContext(UserContext);
   // const { checkDestination, setCheckDestination } = UserApi;

   const history = useLocation()

   const token = localStorage.getItem("token");

   const checkDestination1 = history?.state?.checkDestination1

   // const goToAllgigs = () => {
   //    if(loggedIn === true || token){
   //          console.log("firstfirstfirstfirstfirst")
   //       navigate('/all-gigs')
   //        }
   // }


   const navigate = useNavigate();

   const loginFormik = useFormik({
      initialValues: {
         email: "",
         password: "",
         keepLoggedIn: false,
      },

      validationSchema: LoginValidation,

      onSubmit: async (values) => {
         //   
         const { email, password, keepLoggedIn } = values;
         setLoader(true)
         try {
            setKeepLoggIn(loginFormik.values.keepLoggedIn);
            const response = await loginApi({ email, password });
            if (response.status === 200) {

               toast.success(response?.data?.message, { id: "001" });
               localStorage.setItem("token", response.data.accessToken);
               setLoggedIn(true)
               // goToAllgigs();
               {/* here we have to pass the flag which is coming from the destination */ }
               // if(checkDestination1 === true){
               //    //  
               //    navigate("/", { state : { 
               //       destination : 2,
               //       transferHistory : history?.state?.transferHistory,
               //       fromCity : history?.state?.fromCity,
               //       toCity : history?.state?.toCity
               //    }})
               // }
               // else{
               //    navigate("/", { state : { 
               //       destination : 1,
               //       transferHistory : history?.state?.transferHistory,
               //       fromCity : history?.state?.fromCity,
               //       toCity : history?.state?.toCity
               //    }})
               // }

               // if(loggedIn === true || token){
               //    console.log("firstfirstfirstfirstfirst")
               navigate('/')
               //  }

               // Boolean(checkDestination1 === true) ?
               // //  
               // navigate("/", { state : { 
               //    destination : 2,
               //    transferHistory : history?.state?.transferHistory,
               //    fromCity : history?.state?.fromCity,
               //    toCity : history?.state?.toCity
               // }})
               // : 
               // //  
               // navigate("/", { state : { 
               //    destination : 1,
               //    transferHistory : history?.state?.transferHistory,
               //    fromCity : history?.state?.fromCity,
               //    toCity : history?.state?.toCity
               // }})
            }
         } catch (error) {
            setLoader(false)
            toast.error(error?.response?.data?.message || error.message, { id: "etrr01" });
         }
      },
   });


   //     useEffect(() => {
   //     if(token){
   //       console.log("firstfirstfirstfirstfirst")
   //       navigate('/all-gigs')
   //     }
   //   }, [token])

   return (

      <div className="bg-white">
         <div className="container px-3 ">
            <div style={{ height: "100px", width: "100px" }} className="pt-3">
               <img
                  src="./component/images/Group 3621.png"
                  alt="#"
                  style={{ width: "100%" }}
               />
            </div>
            <h2 className="fw-bold mt-3 mb-0">Welcome!</h2>
            <p className="text-muted mb-4 fs-6">Log in with your data that you entered during your registration.</p>
         </div>

         <form className="container px-3" onSubmit={loginFormik.handleSubmit}>
            <div className="mb-4">
               {/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Email</label> */}
               <div className="input-group position-relative">
                  <input
                     type="email"
                     className="form-control border-end-0"
                     id="exampleInputEmail1"
                     aria-describedby="emailHelp"
                     placeholder="Email"
                     name='email'
                     value={loginFormik.values.email}
                     onChange={loginFormik.handleChange}
                  />
                   <i className="bi bi-envelope-fill position-absolute icon-p  text-fade " style={{zIndex: 9}}></i>
               </div>
               {Boolean(loginFormik.touched.email && loginFormik.errors.email) && <p className='text-danger mt-2'>{loginFormik.errors.email}</p>}
               <div className='text-end'>
                  <Link to='/forgot-email' className="mb-3 mt-1 form-check text-end fw-semibold text-decoration-none d-inline-block" style={{ color: "#4488bf" }}>
                     Forgot email?
                  </Link>
               </div>
            </div>
            <div className="mb-4">
               {/* <label htmlFor="exampleInputPassword1" className="form-label fw-semibold">Password</label> */}
               <div className="position-relative">
                  <input
                     type={passwordType}
                     className="form-control border-end-0 position-relative  pe-5"
                     id="exampleInputPassword1"
                     placeholder="Password"
                     name='password'
                     value={loginFormik.values.password}
                     onChange={loginFormik.handleChange}
                  />
                  <i className={`bi ${passwordType === "password" ? "bi-eye-slash" : "bi-eye"} position-absolute icon-mail  text-muted border-start-0`} style={{
                     cursor: 'pointer', right: "1rem", top: "1rem",
                     background: "transparent !important", marginLeft: "10px"
                  }}
                     onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></i>
               </div>
               {Boolean(loginFormik.touched.password && loginFormik.errors.password) && <p className='text-danger mt-2'>{loginFormik.errors.password}</p>}
            </div>
            <div className="d-flex justify-content-between align-items-center my-3">
               {/* <div className="d-flex align-items-center mb-3">
                  <input type="checkbox"
                     name='keepLoggedIn'
                     value={loginFormik.values.keepLoggedIn}
                     onChange={(e) => loginFormik.setFieldValue("keepLoggedIn", e.target.checked ? true : false)} />
                  <span
                  ><h6 className="my-auto ps-2 text-black fw-semibold">
                        Remember me
                     </h6>
                  </span>
               </div> */}
               <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="flexCheckChecked"
                     name='keepLoggedIn'
                     value={loginFormik.values.keepLoggedIn}
                     onChange={(e) => loginFormik.setFieldValue("keepLoggedIn", e.target.checked ? true : false)}
                  />
                  <label className="form-check-label p-14" for="flexCheckChecked">
                     <h6 className="my-auto text-black fw-semibold">
                        Remember me
                     </h6>
                  </label>
               </div>
               {Boolean(loginFormik.touched.keepLoggedIn && loginFormik.errors.keepLoggedIn) && <p className='text-danger'>{loginFormik.errors.keepLoggedIn}</p>}
               <div>
                  <Link to='/forgot-password' className=" form-check text-end fw-semibold text-decoration-none" style={{ color: "#4488bf" }}>
                     Forgot password?
                  </Link>
               </div>
            </div>

            <div className="text-center my-3">
               <button type="submit" className="button text-white w-100 py-3">

                  {!loader ? ("Log in") : (<div className="text-xs "><ImgLoader2 /></div>)}
               </button>
            </div>
            <div className="text-center text-muted">
               <p className="fs-6">
                  Don't have an account?
                  <Link to='/register' className="fw-semibold text-decoration-none"> Sign up</Link>
               </p>
            </div>
         </form>
         <div className="head-image container px-0" >
            <img
               src="./component/images/image_5.jpeg"
               alt=""
               style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",

               }}
            />
         </div>


      </div>

   );
}

export default Login;