import * as yup from "yup";

export const LoginValidation = yup.object({
	email: yup.string().email("Invalid email").required("Email can't be empty").trim(),
	password: yup.string().required("Password can't be empty"),
	// .matches(/[0-9]/, "Password requires a number")
	// .matches(/[a-z]/, "Password requires a lowercase letter")
	// .matches(/[A-Z]/, "Password requires an uppercase letter")
	// .matches(/[^\w]/, "Password requires a symbol")
	// .min(8, "Password should be minimum of 8 characters"),
});

export const registerValidation1 = yup.object({
	firstName: yup
		.string()
		.required("First name can't be empty")
		.matches(/^[A-Za-z]+$/, "Please enter a valid first name")
		.trim(),
	lastName: yup
		.string()
		.required("Last name can't be empty")
		.matches(/^[A-Za-z]+$/, "Please enter a valid last name")
		.trim(),
	email: yup.string().email("Invalid email").required("Email can't be empty").trim(),
	password: yup
		.string()
		.required("Password can't be empty")
		.matches(/[0-9]/, "Password requires a number")
		.matches(/[a-z]/, "Password requires a lowercase letter")
		.matches(/[A-Z]/, "Password requires an uppercase letter")
		.matches(/[^\w]/, "Password requires a symbol")
		.min(8, "Password should be of minimum 8 characters"),
	confirmPassword: yup
		.string()
		.required("Confirm password can't be empty")
		.oneOf([yup.ref("password")], "Passwords doesn't match"),
});

export const registerValidation2 = yup.object({
	phone: yup.string().required("Phone number can't be empty").min(12, "Please enter a valid phone number").max(12, "Please enter a valid phone number"),
	company: yup.string(),
	//  .required("Company can't be empty")
	address: yup.string().required("Address can't be empty"),
	city: yup.string().required("City can't be empty"),
	state: yup.string().required("State can't be empty"),
	zipCode: yup
		.string()
		.required("Zip code can't be empty")
		// .matches(/^[A-Za-z0-9 ]*$/, "Please enter a valid Zip Code")
		.min(5, "Please enter a valid zip code")
		.max(6, "Please enter a valid zip code"),
	profilePicture: yup.string(),
	// .required("Please upload a profile picture")
	// signinPolicy: yup.boolean().oneOf([true], "You can't proceed without agreeing the terms"),
});

// export const registerValidation2 = yup.object({
//     phone: yup.string()
//     .min(12,"Please enter a valid phone number")
//     .max(12,"Please enter a valid phone number")
//     ,
//     company: yup.string()
//     //  .required("Company can't be empty")
//     ,
//     address: yup.string(),
//     city: yup.string(),
//     state: yup.string(),
//     zipCode: yup.string()
//     // .matches(/^[A-Za-z0-9 ]*$/, "Please enter a valid Zip Code")
//     .min(5, "Please enter a valid zip code")
//     .max(6, "Please enter a valid zip code"),
//     profilePicture: yup.string()
//     // .required("Please upload a profile picture")
//     ,
//     // signinPolicy: yup.boolean().oneOf([true], "You can't proceed without agreeing the terms"),

// });

export const profileValidation = yup.object({
	firstName: yup
		.string()
		.required("First name can't be empty")
		.matches(/^[A-Za-z]+$/, "Please enter a valid First name")
		.trim(),
	lastName: yup
		.string()
		.required("Last name can't be empty")
		.matches(/^[A-Za-z]+$/, "Please enter a valid Last name")
		.trim(),
	company: yup.string().nullable(),

	email: yup.string().email("Invalid email").required("Email can't be empty").trim(),
	phone: yup.string().required("Phone number can't be empty"),
});

export const forgetEmailValidation = yup.object({
	email: yup.string().email("Invalid email").required("Email can't be empty"),
});

export const forgotPasswordValidation = yup.object({
	password: yup
		.string()
		.min(8, "Password should be minimum of 8 characters")
		// .max(8, "Password should be of maximum 8 characters")
		.required("Password can't be empty")
		// .matches("^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$", " One Uppercase, One Lowercase, One Number and one special case Character"),

		.matches(/[0-9]/, "Password requires a number")
		.matches(/[a-z]/, "Password requires a lowercase letter")
		.matches(/[A-Z]/, "Password requires an uppercase letter")
		.matches(/[^\w]/, "Password requires a symbol"),
	confirmPassword: yup
		.string()
		.required("Confirm password can't be empty")
		.oneOf([yup.ref("password")], "Passwords doesn't match"),
});

export const forgetPhoneValidation = yup.object({
	phoneNumber: yup.string().required("Phone number can't be empty").min(12, "Please enter a valid phone number").max(12, "Please enter a valid phone number"),
});

export const vehicleDetailsValidation = yup.object({
	pickupAddress: yup.string().required("Pickup Address can't be empty"),
	dropOffAddress: yup.string().required("DropOff Address can't be empty"),
});

export const calculateCostValidation = yup.object({
	pickupAddress: yup.string().required("address required"),
	dropOffAddress: yup.string().required("address required"),

	// checkInQuote:yup.boolean().oneOf([true], "You can't proceed without agreeing the terms"),
	fromLongitude: yup.string(),
	fromLatitude: yup.string(),
	toLongitude: yup.string(),
	toLatitude: yup.string(),
	distance: yup.string(),
	priceOfRequest: yup.string(),

	// pickup_place_id: yup.string(),
	// dropOff_place_id: yup.string(),
});

export const datePickerValidation = yup.object({
	pickUpDateAndTime: yup.string(),
	interval: yup.string(),
	// earliestPickUpTime:yup.string(),
});

export const vehicleInfoValidation = yup.object({
	vehicleMake: yup.string().required("Vehicle make can't be empty"),
	vehicleModel: yup.string().required("Vehicle model can't be empty"),
	vinNumber: yup.string().nullable().min(4,"Min 4 digit required").max(4).required("Vin number can't be empty"),
	vehiclePlateNumber: yup.string(), 
	vehicleColor: yup.string().required("Color can't be empty"),
	transmission: yup.string(),
	vehicleOtherInformation: yup.string(),
	estimateEndTime: yup.string(),
	estimatedTimeSeconds: yup.string(),
	pickUpDateAndTime:yup.string().required("Please select pickup time"),
	dropOffTime:yup.string().required("Please select dropoff time")
});

export const customerInfoValidation = yup.object({
	customerFullName: yup
		.string()
		.required("Name can't be empty")
		.matches(/^[A-Za-z\s]*$/, "Please enter a valid name"),
	// .trim(),
	// .matches(/^[A-Za-z]+$/, "Please enter a valid First name"),
	customerEmail: yup.string().email("Invalid email").required("Email can't be empty").trim(),
	customerPhone: yup.string().required("Phone number can't be empty").min(12, "Please enter a valid phone number").max(12, "Please enter a valid phone number"),
	// company: yup.string()
	// .required("Company Name can't be empty"),
	// customerCorporationName: yup.string().required(),
	customerOtherInfos: yup.string(),
});

export const businessValidation1 = yup.object({
	businessName: yup
		.string()
		.required("Business name can't be empty")
		.matches(/^[A-Za-z]+$/, "Please enter a valid business name")
		.trim(),
	email: yup.string().email("Invalid email").required("Email can't be empty").trim(),
	password: yup
		.string()
		.required("Password can't be empty")
		.matches(/[0-9]/, "Password requires a number")
		.matches(/[a-z]/, "Password requires a lowercase letter")
		.matches(/[A-Z]/, "Password requires an uppercase letter")
		.matches(/[^\w]/, "Password requires a symbol"),
	// .min(8, "Password should be of minimum 8 characters"),
	confirmPassword: yup
		.string()
		.required("Confirm password can't be empty")
		.oneOf([yup.ref("password")], "Passwords doesn't match"),
});

export const businessValidation2 = yup.object({
	phone: yup.string().required("Phone number can't be empty").min(12, "Please enter a valid phone number").max(12, "Please enter a valid phone number"),
	address: yup.string().required("Address can't be empty"),
	city: yup.string().required("City can't be empty"),
	state: yup.string().required("State can't be empty"),
	zipCode: yup
		.string()
		.required("Zip code can't be empty")
		// .matches(/^[A-Za-z0-9 ]*$/, "Please enter a valid Zip Code")
		.min(5, "Please enter a valid zip code")
		.max(6, "Please enter a valid zip code"),
	profilePicture: yup.string(),
	// .required("Please upload a profile picture")
	// signinPolicy: yup.boolean().oneOf([true], "You can't proceed without agreeing the terms"),
});

export const pickUpValidation = yup.object({
	images: yup.array().of(yup.string()).min(6, "Photos should be atleast 6").required("Photos can't be empty"),

	odometerOrigin: yup.string().required("Origin odometer can't be empty"),
});

export const dropOffValidation = yup.object({
	images: yup.array().of(yup.string()).min(6, "Photos should be atleast 6").required("Photos can't be empty"),
	// .required("Please upload images first")
	odometerDestination: yup.string().required("Destination odometer can't be empty"),
});
