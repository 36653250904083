import axios from "axios";
import { toast } from "react-hot-toast";
import { API_BASE_URL, CarUrl } from "../Utils/BaseUrl";

axios.interceptors.response.use((response) => response, (error) => {
  // console.log(error, "OOOOOOOOOOOoo")
  if ((error?.response?.data?.status === 401) || error?.status === 401) {
    toast.error(error.response.data.message, { id: "etrr01" });

    let token = localStorage.getItem("token")
    localStorage.clear()
    if(token) {
      window.location.reload()
    } 
    throw error
  } else {
    // toast.error(error.response.data.message, { id: "etrr01" });
    throw error
  }
});

export const postApiWithoutToken = (path, data) => {
  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  });
};

export const putApiWithoutToken = (path, data) => {
  return axios.put(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postApiWithToken = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getApiWithToken = (path) => {
  const token = localStorage.getItem("token");

  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};



export const imgUploadApi = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getApiWithoutToken = (path) => {
  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const putApiWithToken = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.put(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};


// export const postLoginApi = (path, data) => {
//   // const token = localStorage.getItem("token");

//   return axios.post(API_BASE_URL + path, data, {
//     headers: {
//       "Content-Type": "application/json",
//       // Authorization: `Bearer ${token}`,
//     },
//   });
// };

export const getApiMake = (path, data) => {

  const token = localStorage.getItem("token")

  return axios.get(CarUrl + path, {
    headers: {
      "X-RapidAPI-Key": "c8026dd730msh1057eed0d2f02f9p16d276jsn259b041c733a",
      "X-RapidAPI-Host": "car-data.p.rapidapi.com",
      "Content-Type": "application/json",
    },
  })
}

export const getApiModel = (path, data) => {
  const { year, make } = data

  return axios.get(CarUrl + path, {
    params: { year, make },
    headers: {
      "X-RapidAPI-Key": "c8026dd730msh1057eed0d2f02f9p16d276jsn259b041c733a",
      "X-RapidAPI-Host": "car-data.p.rapidapi.com",
      "Content-Type": "application/json",
    },
  })
}