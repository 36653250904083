import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DriverUrl } from '../../../Utils/BaseUrl'
import Navbar from './Navbar'
import { GigContext } from '../../../app_context/UserContext'
import Header from '../../../Components/Header/Header'

const ServiceWork = ({setQuotation, setRequestStep}) => {

  const {destinationShowCity, calculateCostFormik} = useContext(GigContext);

  const navigate = useNavigate();

  const handleNext = () => {
    
    setTimeout(() => {
      setRequestStep(2)
    }, 100);
  }
   
  return (
    <div className="mb-4">
    {/* <Navbar/>         */}
    <Header/>
    <div className='main-content-container'>
    <div className="container mb-3 " style={{marginTop: 80}}>
      <h5 className="fw-bold ">How the service work:</h5>
      
      <div className="d-flex justify-content-between align-items-center border-bottom py-3" >
        <div className="d-flex  align-items-center" style={{cursor:"pointer"}} onClick={()=>setRequestStep(2)}>
          <div className="button p-1 rounded-circle me-3 service-icon d-flex align-items-center justify-content-center">
            {/* <img src="/component/images/Icon awesome-wpforms.png" className="img-fluid p-1" alt=""/> */}
            {/* <div className='img-fluid'> */}
            <h6 className='m-0 p-0 text-white'>1</h6>
            {/* <i className="bi bi-1-circle" style={{ fontSize : "25px" , padding : "4px 4px 4px 4px"}}></i> */}
            {/* </div> */}
          </div>

          <p className="m-0 fw-semibold">Fill in the transport information</p>
        </div>
        {/* <p className="m-0 h3" style={{cursor:"pointer"}} onClick={()=>setRequestStep(2)}>{">"}</p> */}
      </div>
      <div className="d-flex justify-content-between align-items-center border-bottom py-3">
        <div className="d-flex align-items-center">
          <div className="button p-1 rounded-circle me-3 service-icon d-flex align-items-center justify-content-center">
            {/* <img src="/component/images/Icon material-payment.png" className="img-fluid p-1" alt=""/> */}
            <h6 className='m-0 p-0 text-white'>2</h6>
            {/* <i className="bi bi-2-circle" style={{ fontSize : "25px" , padding : "4px 4px 4px 4px"}}></i> */}
          </div>

          <p className="m-0 fw-semibold">Make a payment</p>
        </div>
        {/* <p className="m-0 h3">{">"}</p> */}
      </div>
      <div className="d-flex justify-content-between align-items-center  py-3">
        <div className="d-flex align-items-center">
          <div className="button p-1 rounded-circle me-3 service-icon d-flex align-items-center justify-content-center">
            {/* <img src="/component/images/Icon simple-pivotaltracker.png" className="img-fluid p-1" alt=""/> */}
            <h6 className='m-0 p-0 text-white'>3</h6>
            {/* <i className="bi bi-3-circle" style={{ fontSize : "25px" , padding : "4px 4px 4px 4px"}}></i> */}

          </div>

          <p className="m-0 fw-semibold p-16">Track the transport</p>
        </div>
        {/* <p className="m-0 h3">{">"}</p> */}
      </div>


    </div>
    
    <div className="container " style={{position: 'absolute', bottom: 0}}>
      <button type="button" className="button btn-primary w-100 py-3" onClick={()=> handleNext()} >
        Continue
      </button>
      <a className='dark-clr p-12 fw-semibold text-decoration-none' target="_blank" href={DriverUrl}>Do you want to become a driver?</a>
    </div>
    </div>
    <div className="scrollHeight"></div>
  </div>
  )
}

export default ServiceWork