import React from 'react'
import ResetForm from './ResetForm'

const ResetPassword = () => {
   return (
      <div className="container px-3">
         <div className="mt-3" style={{ height: "100px", width: "100px" }}>
            <img
               src="./component/images/Group 3621.png"
               alt="#"
               style={{ width: "100%" }}
            />
         </div>
         <ResetForm />
      </div>
   )
}

export default ResetPassword