import React, { useEffect, useState } from "react";
import { GigContext } from "./UserContext";
import { useFormik } from "formik";
import { calculateCostValidation, customerInfoValidation, vehicleInfoValidation } from "../Validation/Validation";
import { useLocation, useNavigate } from "react-router-dom";
import { createGigApi, createPayment, createPrice, getGigDetails } from "../api";
import { toast } from "react-hot-toast";
import { WEB_URL } from "../Utils/BaseUrl";

const GigContextState = (props) => {
	const [checkDestination, setCheckDestination] = useState(false);
	const [directionsResponse, setDirectionsResponse] = useState("");
	const [distAndPrice, setDistAndPrice] = useState("");
	const [place, setPlace] = useState({ to: "", from: "" });
	const [checkEditRequest, setCheckEditRequest] = useState(false);
	const [dummy, setDummy] = useState("");
	const [dummyTwo, setDummyTwo] = useState();
	const [createdGigData, setCreatedGigData] = useState("");
	const [destinationShowCity, setDestinationShowCity] = useState({ from: "", to: "" });
	const [placeId, setPlaceId] = useState({ from_place_id: "", to_place_id: "" });
	const [gigDetails, setGigDetails] = useState({ data: "", loading: true });
	const [id, setId] = useState("");
	const [load, setLoad] = useState({ loading: false });
	const [otherModel, setOtherModel] = useState("")
	// const []

	const navigate = useNavigate();
	const location = useLocation();

	const orderId = location?.state?.orderId;

	const getGigsData = async () => {
		setGigDetails({ loading: true });
		try {
			const res = await getGigDetails(orderId);
			if (res.status === 200) {
				setGigDetails({ data: res?.data, loading: false });
			}
		} catch (error) {
			setGigDetails({ loading: false });
		}
	};


	const isOrderId = () => {
		if (gigDetails?.data) {
			calculateCostFormik?.setFieldValue("pickupAddress", gigDetails?.data?.pickupAddress);
			calculateCostFormik?.setFieldValue("dropOffAddress", gigDetails?.data?.dropOffAddress);
			calculateCostFormik?.setFieldValue("dropOffAddress", gigDetails?.data?.dropOffAddress);
			vehicleInfoFormik?.setFieldValue("pickUpDateAndTime", gigDetails?.data?.dropOffAddress);

			// vehicleInfoFormik.values.pickUpDateAndTime
			setPlace({ ...place, to: gigDetails?.data?.dropOffAddress, from: gigDetails?.data?.pickupAddress });
		}
	};

	useEffect(() => {
		setId(location?.state?.orderId);
	}, []);

	useEffect(() => {
		if (location !== "") {
			getGigsData();
		}
	}, [location]);

	useEffect(() => {
		isOrderId();
	}, [gigDetails]);

	const calculateCostFormik = useFormik({
		initialValues: {
			// distance: "",
			// dropOffAddress: "",
			// dropOffCity: "",
			// dropOffTime: "",
			// earliestPickUpTime: "",
			// estimateEndTime: "",
			// estimatedTimeSeconds: "",
			// fromLatitude: "",
			// fromLongitude: "",
			// pickUpDateAndTime: "",
			// pickupAddress: "",
			// pickupCity: "",
			// toLatitude: "",
			// toLongitude: "",
			// transmission: "",
			// vehicleColor: "",
			// vehicleMake: "",
			// vehicleModel: "",
			// vehicleOtherInformation: "",
			// vehiclePlateNumber: "",

			pickupAddress: "",
			dropOffAddress: "",
			// checkInQuote: false,

			fromLongitude: "",
			fromLatitude: "",
			toLongitude: "",
			toLatitude: "",

			distance: "",

			pickup_place_id: "",
			dropOff_place_id: "",

			pickupCity: "",
			dropOffCity: "",
			// priceOfRequest: "",

			estimateEndTime: "",
			estimatedTimeSeconds: "",
		},
		validationSchema: calculateCostValidation,
		onSubmit: () => {
			return;
		},
	});

	const customerInfoFormik = useFormik({
		initialValues: {
			// fullName: "",
			// email: "",
			// phone: "",
			// company: "",
			// corporationName:"",
			// otherInfos:"",
			customerFullName: "",
			customerEmail: "",
			customerPhone: "",
			customerCompany: "",
			customerCorporationName: "",
			customerOtherInfos: "",
		},
		validationSchema: customerInfoValidation,
		onSubmit: async (values) => {
			return;
			//  setRequestStep(6)
		},
	});

	const vehicleInfoFormik = useFormik({
		initialValues: {
			pickUpDateAndTime: "",
			dropOffTime: "",
			vehicleMake: "",
			vinNumber:"",
			vehicleModel: "",
			vehiclePlateNumber: "",
			transmission: "Automatic",
			vehicleColor: "",
			vehicleOtherInformation: "",
			mustArrivalTime: "",
			securityCode: "",
			// orderId:""
		},

		validationSchema: vehicleInfoValidation,

		onSubmit: async (values) => {
			setLoad({ loading: true });
			try {
				const data = {
					...calculateCostFormik?.values,
					...customerInfoFormik?.values,
					...values,
				};
				delete data.pickup_place_id;
				delete data.dropOff_place_id;

				const res = await createGigApi(data);
				if (res?.status === 200) {
					setCreatedGigData(res);
					handleSubmit(res?.data?.createRequest?.orderId);
					vehicleInfoFormik.setFieldValue("orderId", res?.data?.createRequest?.orderId)
				}
			} catch (error) {
				toast.error(error?.response?.data?.message, { id: "etrr01" });
				setLoad({ loading: false });
			}
		},
	});

	const handleSubmit = async (orderId) => {
		try {
			const res = await createPrice({ orderId: orderId });
			if (res.status === 200) {
				try {
					const res1 = await createPayment({
						line_items: [{ price: res?.data?.price?.id, quantity: 1 }],
						success_url: `${WEB_URL}/paymentSuccess/${orderId}`,
						// `${WEB_URL}/confirm-order/${orderId}`,
						cancel_url: `${WEB_URL}/paymentFailure/${orderId}`,
						// `http://localhost:3000/request/create`
					});
					if (res1?.status === 200) {
						window.location.href = res1?.data?.session?.url;
					}
				} catch (error) {
					return error;
				}
			}
		} catch (error) {
			return error;
		}
	};

	return (
		<GigContext.Provider
			value={{
				calculateCostFormik,
				checkDestination,
				setCheckDestination,
				distAndPrice,
				setDistAndPrice,
				place,
				setPlace,
				checkEditRequest,
				setCheckEditRequest,
				vehicleInfoFormik,
				load,
				setLoad,
				customerInfoFormik,
				dummy,
				setDummy,
				dummyTwo,
				setDummyTwo,
				directionsResponse,
				setDirectionsResponse,
				destinationShowCity,
				setDestinationShowCity,
				placeId,
				setPlaceId,
				otherModel,
				setOtherModel
			}}>
			{props.children}
		</GigContext.Provider>
	);
};

export default GigContextState;
