import React, { useContext, useEffect } from 'react'
import { GigContext } from '../../app_context/UserContext'
import { Link, useNavigate } from 'react-router-dom'
import { getAmount } from '../../api'
import { isAuth } from '../../Utils/HelperFunction'
import Footer from '../../Components/Footer/Footer'
import Map from './Map'
import Header from '../../Components/Header/Header'

const NewQuoteDetails = () => {

  const { calculateCostFormik, checkDestination, setCheckDestination, distAndPrice, setDistAndPrice, place, setPlace, checkEditRequest, directionsResponse,
    setDirectionsResponse } = useContext(GigContext)

  const navigate = useNavigate()

  const registerRoute = () => {
    setCheckDestination(true);
    setTimeout(() => {
      if (checkDestination === true) {
        navigate("/register", {
          state: {
            checkDestination: checkDestination,
            data: calculateCostFormik?.values,
            //   fromCity: destinationShowCity?.from,
            //   toCity: destinationShowCity?.to,
            //   gigDetails,
          },
        });
      }
    }, 100);
  };

  const getAmountApi = async (dist) => {
    try {
      const res = await getAmount(dist);
      if (res?.status === 200) {
        setDistAndPrice(res?.data?.amount);
      }
    } catch (error) { }
  };



  const calculateRoute = async (from, to) => {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: from,
      destination: to,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    }); 
    // setDist(results.routes?.[0]?.legs?.[0]?.distance?.text)
    const distance = results.routes?.[0]?.legs?.[0]?.distance?.value;
    const duration = results.routes?.[0]?.legs?.[0]?.duration?.text;
    const durationValue = results.routes?.[0]?.legs?.[0]?.duration?.value;
    // const distInM = (distance/1000).toFixed(2)
    const distInM = (distance / 1609.344).toFixed(2);
    if (distance !== "") {
      calculateCostFormik.setFieldValue("distance", distInM);
      calculateCostFormik.setFieldValue("estimateEndTime", duration);
      calculateCostFormik.setFieldValue("estimatedTimeSeconds", durationValue.toString());
    }
  }

  useEffect(() => {
    if (place?.from && place?.to) {
      calculateRoute(place?.from, place?.to);
      // console.log(place, "responseofplaceinuseffect")
    }
    
  }, [place]);

  useEffect(() => {
    if (calculateCostFormik?.values?.distance !== "") {
      getAmountApi(calculateCostFormik?.values?.distance);
      //  console.log( "responseofplaceafterformik")
    }
  }, [ calculateCostFormik?.values])
  

  useEffect(()=> {
    if (!Boolean(calculateCostFormik?.values?.pickupAddress && calculateCostFormik?.values?.dropOffAddress)) {
      navigate("/new-quote")
    }
  },[])
  // console.log(calculateCostFormik?.values, "I got the formik in details")
  return (
    <div className="footer-app-container">
      <Header/>
      {/* <div className="dark-color header container d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/new-quote");
            }}
          >
            <i className="bi bi-chevron-left h3 text-white ms-2"></i>
          </div>
          <div
            style={{ width: "60px" }}
            className=" text-align-center py-2 ms-3"
          >
            <img
              src="../component/images/Mask Group 2259.png"
              alt="#"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className=" d-flex justify-content-end align-items-center"></div>
      </div> */}
      <main className="mb-4 destination main-content-container">

        <div
          className="destination-image container px-0 "
          style={{ height: "60vh", marginTop: 80 }}
        >
          <Map
            place={place}
            directionsResponse={directionsResponse}
            setDirectionsResponse={setDirectionsResponse}
          ></Map>
        </div>

        <div className="container">
          <div
            className="m-auto border border-2 mt-2 mb-2"
            style={{ width: "50px" }}
          ></div>
          <h5 className="mb-2 fw-bold">Vehicle Delivery Quote</h5>
          <div>
            <div className="d-flex justify-content-between align-items-center">

              <div className="d-md-flex d-sm-block justify-content-start align-items-center">
                <h6 className=" m-0 fw-bold  me-2  "style={{ minWidth: 50 }}>From{'\u00A0'}:</h6>
                <span className="p-0 m-0 fw-semibold opacity-75">
                  {calculateCostFormik?.values?.pickupAddress || "-"}
                </span>
              </div>

              <div className="ps-2">
                <p className="p-0 m-0 fw-semibold">
                  {Boolean(
                    calculateCostFormik?.values?.distance === "0.00"
                  )
                    ? "-"
                    : `${"$" + distAndPrice}  `}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">

              <div className="d-md-flex d-sm-block justify-content-start align-items-center">
                <h6 className=" m-0 fw-bold  me-2 text-sm-end text-start " style={{ minWidth: 50 }}>To{'\u00A0'}:</h6>
                <span className="p-0 m-0 fw-semibold opacity-75">
                  {calculateCostFormik?.values?.dropOffAddress || "-"}
                </span>

              </div>
              <div className="ps-2">
                <p className="p-0 m-0 fw-semibold">
                  {calculateCostFormik?.values?.distance}{'\u00A0'}{"mi"}
                </p>
              </div>
            </div>
          </div>


          <div className="d-flex border-top border-bottom py-2 justify-content-between align-items-center fw-semibold">
            {/* <p className="m-0"><i className="bi bi-person-fill mx-2 h5"></i>$78.00</p> */}

            {/* <a href="#" className=" button text-decoration-none " 
              style={{padding: "10px"}}
              onClick={()=> {handleBack()}}> Back</a> */}

            <a
              className="light-clr button text-decoration-none px-4"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/new-quote")
              }}
            >
              Edit
            </a>

            {/* <a href="#" className="light-clr">
              Change destination
            </a> */}
          </div>
          <div className="mt-3 d-flex text-center text-decoration-none" >
            {localStorage.getItem("token") ? (
              // <button type="button"
              //   className="button btn-primary w-100 py-3"
              //   onClick={() =>
              //     navigate("/request/create",{
              //       state:{
              //         // from:calculateCostFormik.values?.pickupAddress,
              //         // to:calculateCostFormik.values?.dropOffAddress,
              //         calculateCostFormik: calculateCostFormik
              //     }
              //   })
              //   }
              // >
              //   {checkEditRequest ? 'Continue' : 'PROCEED'}
              // </button>
              <Link
                className="button btn-primary w-100 py-3 text-decoration-none mb-3"
                to={"/request/create"}
              // state={{
              //   data: calculateCostFormik?.values,
              //   // fromCity: destinationShowCity?.from,
              //   // toCity: destinationShowCity?.to,
              //   // history: history?.state,
              //   // gigDetails,
              //   // vehicleValue,
              //   // setQuotation: setQuotation,
              // }}
              // onClick={() =>
              //   navigate("/request/create",{
              //     state:{
              //       // from:calculateCostFormik.values?.pickupAddress,
              //       // to:calculateCostFormik.values?.dropOffAddress,
              //       calculateCostFormik: calculateCostFormik
              //   }
              // to={{
              //   pathname : "/request/create",
              //   state:{
              //     // from:calculateCostFormik.values?.pickupAddress,
              //     // to:calculateCostFormik.values?.dropOffAddress,
              //     // calculateCostFormik: "calculateCostFormik?.values"
              //     // z : calvalues

              //    }
              // }}

              // })
              // }
              >
                {checkEditRequest ? "Continue" : "Get started"}
              </Link>
            ) : (
              <>
                <button
                  type="button"
                  id="#createButton"
                  className="button btn-primary w-100 py-3 mb-3"
                  onClick={() => {
                    registerRoute();
                  }}
                >
                  Create an account
                </button>
              </>
            )}
            {/* here we have to send the flag in side the navigation of create account btn */}
          </div>
        </div>
        <div className="scrollHeight"></div>
      </main>
      {
        isAuth() ?
          <Footer />
          :
          ""
      }
    </div>
  )
}

export default NewQuoteDetails