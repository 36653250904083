import { useEffect, useState } from "react"; 
import { getAdminDetails, myProfileApi } from "../api";
import { getToken } from "../Utils/HelperFunction";
import {UserContext} from "./UserContext";

const AppContextState = (props) =>{

  const [customerData, setCustomerData]  = useState({data : null, loading :true})
  const [loggedIn, setLoggedIn] = useState(false)
  const [clearReview , setClearReview] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false)
  const [adminData, setAdminData] = useState({data:null, loading:true})

  const [socket, setSocket] = useState("")


  const token = getToken()

  const getProfile = async () => {
    setCustomerData({ loading: true })
    try {
      const res = await myProfileApi();
      if (res.status === 200) {
        setCustomerData({ data: res?.data?.data});
      }
    } catch (err) {
      setCustomerData({ loading: false })
      // 
    }
  };

  const getDetailsAdmin = async () => {
    try{
        const res = await getAdminDetails()
        setAdminData((v) => ({...v, data : res?.data , loading : false}))
    }
    catch(error){

    setAdminData({loading:false})
        console.log(error)
    }
}

	useEffect(() => {
		// const token = getToken()
		//
		if (loggedIn === true || token) {
			getProfile();
			getDetailsAdmin();
		}
	}, [loggedIn, token]);



    return (
        <UserContext.Provider value={{
          customerData,
          setLoggedIn,
          clearReview,
          setClearReview,
          loggedIn,
          isBusiness,
          setIsBusiness,
          getDetailsAdmin,
          adminData,
          socket, setSocket,
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default AppContextState    