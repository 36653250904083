import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { RequestContext } from "../../app_context/UserContext";

const Header = ({hideBack}) => {
  const request = useContext(RequestContext)
  const location = useLocation();

  return (
    <header className="dark-color header container d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
       {hideBack ? "" : <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            if ((request?.requestStep > 1) && location.pathname === "/request/create" ){
              request?.setRequestStep?.(request?.requestStep - 1);
            } else {
              window.history.back();
            }
          }}
        >
          <i className="bi bi-chevron-left h3 text-white ms-2"></i>
        </div>}
        <div style={{ width: "60px" }} className=" text-align-center py-2 ms-3">
          <img
            src="../component/images/Mask Group 2259.png"
            alt="#"
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className=" d-flex justify-content-end align-items-center"></div>
    </header>
  );
};

export default Header;
