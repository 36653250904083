import React, { useContext, useState } from 'react'
import { useFormik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getCitiesApi, getStatesApi, registerApi } from '../../../api';
import { businessValidation1, businessValidation2 } from '../../../Validation/Validation';
import { ImgLoader2 } from '../../../Utils/Loader';
import BusinessStep1 from './Steps/Step1';
import BusinessStep2 from './Steps/Step2';
import {UserContext} from '../../../app_context/UserContext';


const RegisterBusiness = () => {

   const app_context = useContext(UserContext)
   const { setLoggedIn } = app_context

   const [step, setStep] = useState(0);
   const [checkLength, setCheckLength] = useState(false)
   const [stateCode, setStateCode] = useState("")
   const [cityData, setCityData] = useState("")

   const [loader, setLoader] = useState(false)
   // const data = useLocation()

   // const UserApi = useContext(UserContext);
   // const { checkDestination, setCheckDestination } = UserApi;

   const history = useLocation()

  const checkDestination1 = history?.state?.checkDestination

   const navigate = useNavigate();

   const fetchStates = async (search) => {
      const res = await getStatesApi();
      if (res?.status === 200 && res?.data?.length) {
        const data = res?.data.map(l => ({ label: l.name, value: l.isoCode }))
        return data
      }
      return []
    };

    const fetchCities = async (stCode) => {
      try {
        const res = await getCitiesApi({ stateCode: stCode });
        if (res?.data?.cities?.length > 1) {
          setCityData(res?.data?.cities)
        }
      } catch (err) {
         return err
      }
    };

   const businessFormik1 = useFormik({
      initialValues: {
         businessName: "",
         email: "",
         password: "",
         confirmPassword: "",
      },
      validationSchema: businessValidation1,

      onSubmit: (values) => {
         if (values.password.length < 8) {
            return setCheckLength(true)
         }
         setStep(1);
      },
   });

   const businessFormik2 = useFormik({
      initialValues: {
         phone: "",
         address: "",
         city: "",
         state: "",
         zipCode: "",
         profilePicture: "",
         // signinPolicy: false,
      },
      validationSchema: businessValidation2,

      onSubmit: async (values) => {
         const data = { ...businessFormik1.values, ...values, isBusiness : true };
         delete data.confirmPassword;
         // delete data.signinPolicy;
         // delete data.company;

         // const data = {

         // }

         setLoader(true)
         try {
            const res = await registerApi(data);
            if (res?.status === 200 || res?.status === 201) {
               localStorage.setItem("token", res.data.accessToken);
               toast.success(res?.data?.message, { id: "001" });
               setLoggedIn(true)

               Boolean(checkDestination1 === true) ?
                  navigate("/quote", { state : { 
                     destination : 2,
                     transferHistory : history?.state?.data,
                     fromCity : history?.state?.fromCity,
                     toCity : history?.state?.toCity
                  }})
                  : 
                  navigate("/quote", { state : { 
                     destination : 1,
                     transferHistory : history?.state?.data,
                     fromCity : history?.state?.fromCity,
                     toCity : history?.state?.toCity
                  }})
            } 
         } catch (error) { 
            setLoader(false)
            toast.error(error?.response?.data?.message, { id: "etrr01" });
         }
      },
   });


   // const handleSubmit = () =>{
   //    setStep(1);
   // }
   return (
      <div className="container px-3">
         <div className="mt-3" style={{ height: "100px", width: "100px" }}>
            <img
               src="./component/images/Group 3621.png"
               alt="#"
               style={{ width: "100%" }}
            />

         </div>
         {
            step === 0 ? "" :
            
               <div className='row flex-column'>
                 <label htmlFor='profilePic mb-3'>
                     <span style={{ cursor: "pointer" }}>
                       <i className="bi bi-arrow-left-circle-fill dark-clr h3  " onClick={() => setStep(0)}></i>
                     </span>
                  </label>
                  <h1 className="fw-bold my-2 col-md-11">Create Account!</h1>
               </div>
         }


         {step === 0 ? (
            <BusinessStep1 businessFormik1={businessFormik1} checkLength={checkLength} setCheckLength={setCheckLength} >
               <button type="submit" className="button text-white w-100  py-3"
               // onClick={()=>{handleSubmit(1)}}
               >
                 Next
               </button>
            </BusinessStep1>
         ) : (
            <BusinessStep2 businessFormik1={businessFormik1} businessFormik2={businessFormik2} fetchStates={fetchStates} fetchCities={fetchCities} stateCode={stateCode} setStateCode={setStateCode} cityData={cityData}>
               <button type="submit" className="button text-white w-100 py-3 fs-5">
                  {!loader ? ("Create an account") : (<div className="text-xs "><ImgLoader2 /></div>)}
               </button>
            </BusinessStep2>
         )}

         {Boolean(step === 0) && (
            <p className="text-muted text-center">Already have an account?
               <Link 
               to='/login'
                  state={{
                     transferHistory : history?.state?.data,
                     fromCity : history?.state?.fromCity,
                     toCity : history?.state?.toCity,
                     checkDestination1
                  }}
                className="ms-2 fw-semibold text-decoration-none">Log in</Link>

               {/* here we have to pass the flag which is coming from the destination */}
            </p>
         )}

      </div>
   )
}

export default RegisterBusiness